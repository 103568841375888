import React, { useState } from 'react';
import SideBar1 from '../../components/SideBar1/SideBar1';
import MeetingRoomBookingComponent from './MeetingRoomBookingComponent'; // Ensure this import is correct

const MeetingRoomBooking = () => {
    const [expand, setExpand] = useState(true);

    const handleToggleExpand = () => {
        setExpand(!expand);
    };

    return (
        <div style={{ display: 'flex' }}>
            <SideBar1 expand={expand} onToggleExpand={handleToggleExpand} />
            <MeetingRoomBookingComponent expand={expand} />
        </div>
    );
}

export default MeetingRoomBooking;
