import { Container,  Content, Form, ButtonToolbar, Button, FlexboxGrid, Dropdown, DatePicker, useToaster, Message,} from 'rsuite';
  import { useState, useContext, useEffect } from "react";
  import { doc, serverTimestamp, getDoc, updateDoc, collection, getDocs } from "firebase/firestore"; 
  import { db } from "../../firebase";
  import NavBar from '../../components/NavBar/NavBar';
  import { AuthContext } from "../../context/authContext";
  import Header from '../../components/Header/Header';
  import { USERS, GENDERDATA, POSITIONS } from '../../constants/constants';
  import { useParams, useNavigate } from 'react-router-dom';
  import SideBar1 from '../../components/SideBar1/SideBar1';
  const UserDetailsPage = () => {
    const [expand, setExpand] = useState(true);

    const handleToggleExpand = () => {
        setExpand(!expand);
    };

    return (
        <div style={{ display: 'flex' }}>
            <SideBar1 expand={expand} onToggleExpand={handleToggleExpand} />
            <UserDetailsData expand={expand}/>
        </div>
    );
}

export default UserDetailsPage;


  const UserDetailsData = ({ expand }) => {
    const { currentUser } = useContext(AuthContext);
    const { id } = useParams();
    const [formData, setFormData] = useState({
      name: "",
      email: "",
      gender: GENDERDATA[0],
      position: POSITIONS[0],
      ic: "",
      contact: "",
      joinedDate: null,
      recruiterId: "",
      recruiterName: "",
      salary: 0,
      medLeaves: 0,
      annualLeaves: 0,
      subsalesTier: 70,
      projectTier: 0,
    });
    const [editMode, setEditMode] = useState(false);
    const [recruiters, setRecruiters] = useState([]);
    const toaster = useToaster();
    const [errors, setErrors] = useState({});
  
    useEffect(() => {
      const fetchUserDetails = async () => {
        const docRef = doc(db, "users", id);
        const docSnap = await getDoc(docRef); // Use getDoc for fetching a single document
        if (docSnap.exists()) {
          setFormData(docSnap.data());
        } else {
          console.log("No such document!");
        }
      };
  
      const fetchRecruiters = async () => {
        const querySnapshot = await getDocs(collection(db, "users"));
        const recruiterList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setRecruiters(recruiterList);
      };
  
      fetchUserDetails();
      fetchRecruiters();
    }, [id]);
  
    const handleChange = (value, field) => {
      setFormData({
        ...formData,
        [field]: value
      });
    };
  
    const handleSelectRecruiter = (id, name) => {
      setFormData({
        ...formData,
        recruiterId: id,
        recruiterName: name
      });
    };
  
    const handleUpdateUser = async () => {
      const newErrors = {};
      if (!formData.name) newErrors.name = "Full Name is required";
      if (!formData.email) newErrors.email = "Email is required";
      if (!formData.ic) newErrors.ic = "IC Number is required";
      if (!formData.contact) newErrors.contact = "Contact is required";
      if (!formData.joinedDate) newErrors.joinedDate = "Joined date is required";
  
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return;
      }
  
      try {
        const docRef = doc(db, "users", id);
        await updateDoc(docRef, {
          ...formData,
          updatedAt: serverTimestamp(),
        });
        toaster.push(<Message type="success">User details have been updated successfully!</Message>);
        setEditMode(false);
      } catch (e) {
        console.error('Error updating user: ', e);
      }
    };
  
    const handleEdit = () => {
      setEditMode(true);
    };
  
    return (
      <div className="user-details" style={{flex: 1}}>
        <Container style={{ marginLeft: expand ? '260px' : '56px' }}>
          <Container>
            <NavBar user={currentUser}/>
            <div className="header-container" style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
              <Header title={USERS}/>
              <ButtonToolbar style={{marginTop: "20px", marginRight: "20px"}}>
                <Button appearance="primary" onClick={handleEdit}>Edit User</Button>
              </ButtonToolbar>
            </div>
            <Content>
              <Form style={{ textAlign: 'left', marginLeft: "40px", marginTop: "40px"}} onSubmit={handleUpdateUser}>
                <FlexboxGrid>
                  <FlexboxGrid.Item colspan={12}>
                    <Form.Group controlId="name">
                      <Form.ControlLabel>Full Name</Form.ControlLabel>
                      <Form.Control 
                        name="name" 
                        value={formData.name}
                        onChange={(value) => handleChange(value, 'name')}
                        readOnly={!editMode}
                      />
                      {errors.name && <Form.HelpText style={{ color: 'red' }}>{errors.name}</Form.HelpText>}
                    </Form.Group>
                    <Form.Group controlId="email">
                      <Form.ControlLabel>Email</Form.ControlLabel>
                      <Form.Control 
                        name="email" 
                        type="email" 
                        value={formData.email}
                        onChange={(value) => handleChange(value, 'email')}
                        readOnly={!editMode}
                      />
                      {errors.email && <Form.HelpText style={{ color: 'red' }}>{errors.email}</Form.HelpText>}
                    </Form.Group>
                    <Form.Group controlId="gender">
                      <Form.ControlLabel>Gender</Form.ControlLabel>
                      <Dropdown 
                        title={formData.gender || "Gender"} 
                        onSelect={(value) => handleChange(value, 'gender')}
                        disabled={!editMode}
                      >
                        {GENDERDATA.map((data) => (
                          <Dropdown.Item key={data} eventKey={data}>{data}</Dropdown.Item>
                        ))}
                      </Dropdown>
                      {errors.gender && <Form.HelpText style={{ color: 'red' }}>{errors.gender}</Form.HelpText>}
                    </Form.Group>
                    <Form.Group controlId="position">
                      <Form.ControlLabel>Position</Form.ControlLabel>
                      <Dropdown 
                        title={formData.position || "Position"} 
                        onSelect={(value) => handleChange(value, 'position')}
                        disabled={!editMode}
                      >
                        {POSITIONS.map((data) => (
                          <Dropdown.Item key={data} eventKey={data}>{data}</Dropdown.Item>
                        ))}
                      </Dropdown>
                      {errors.position && <Form.HelpText style={{ color: 'red' }}>{errors.position}</Form.HelpText>}
                    </Form.Group>
                    <Form.Group controlId="recruiter">
                      <Form.ControlLabel>Recruiter</Form.ControlLabel>
                      <Dropdown 
                        title={formData.recruiterName || "Select Recruiter"} 
                        onSelect={(eventKey, event) => handleSelectRecruiter(eventKey, event.target.innerText)}
                        disabled={!editMode}
                      >
                        {recruiters.map((recruiter) => (
                          <Dropdown.Item key={recruiter.id} eventKey={recruiter.id}>{recruiter.name}</Dropdown.Item>
                        ))}
                      </Dropdown>
                      {errors.recruiterId && <Form.HelpText style={{ color: 'red' }}>{errors.recruiterId}</Form.HelpText>}
                    </Form.Group>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={12}>
                    <Form.Group controlId="icNumber">
                      <Form.ControlLabel>IC Number</Form.ControlLabel>
                      <Form.Control 
                        name="icnumber" 
                        type="text" 
                        value={formData.ic}
                        onChange={(value) => handleChange(value, 'ic')}
                        readOnly={!editMode}
                      />
                      {errors.ic && <Form.HelpText style={{ color: 'red' }}>{errors.ic}</Form.HelpText>}
                    </Form.Group>
                    <Form.Group controlId="contactNumber">
                      <Form.ControlLabel>Contact Number</Form.ControlLabel>
                      <Form.Control 
                        name="contactNumber" 
                        type="text" 
                        value={formData.contact}
                        onChange={(value) => handleChange(value, 'contact')}
                        readOnly={!editMode}
                      />
                      {errors.contact && <Form.HelpText style={{ color: 'red' }}>{errors.contact}</Form.HelpText>}
                    </Form.Group>
                    <Form.Group controlId="joinedDate">
                        <Form.ControlLabel>Joined Date</Form.ControlLabel>
                        <DatePicker
                            oneTap
                            value={formData.joinedDate ? new Date(formData.joinedDate.seconds * 1000) : null}
                            onChange={(date) => handleChange(date, 'joinedDate')}
                            disabled={!editMode}
                        />
                        {errors.joinedDate && <Form.HelpText style={{ color: 'red' }}>{errors.joinedDate}</Form.HelpText>}
                    </Form.Group>
                    {editMode && (
                  <ButtonToolbar>
                    <Button appearance="primary" onClick={handleUpdateUser}>Save Changes</Button>
                    <Button appearance="default" onClick={() => setEditMode(false)}>Cancel</Button>
                  </ButtonToolbar>
                )}
                  </FlexboxGrid.Item>
                  
                </FlexboxGrid>
                
              </Form>
            </Content>
          </Container>
        </Container>
      </div>
    );
  }
  