import { Navbar, Nav, Dropdown} from 'rsuite';
import { useContext, forwardRef } from "react";
import { AuthContext } from "../../context/authContext";
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';
import {TITLE} from '../../constants/constants';
import { Link } from 'react-router-dom';

const NavBar = () => {
  const { currentUser } = useContext(AuthContext);

  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  
    const handleSignOut = async () => {
      try {
        await signOut(auth);
        dispatch({ type: "LOGOUT" });
        localStorage.clear();
        navigate("/login");
      } catch (error) {
        console.error("Error signing out: ", error);
      }
    }

    const handleProfile = () => {
      navigate("/profile");
    }

    const handleAccountSetting = () => {
      navigate("/account-setting");
    }

    const NavLink = forwardRef(({ href, children, ...rest }, ref) => (
      <Link ref={ref} to={href} {...rest}>
        {children}
      </Link>
    ));

    return (
      <div className='navbar' style={{position: 'sticky', top: 0, zIndex: 1000}}>
        <Navbar>
          <Navbar.Brand style={{fontWeight: "800"}} as={NavLink} href="/">{TITLE}</Navbar.Brand>
          <Nav pullRight>
            {currentUser ? (
              <Dropdown title={`${currentUser.position}, ${currentUser.name}`} trigger="hover">
                <Dropdown.Item onClick={handleProfile}>Profile</Dropdown.Item>
                <Dropdown.Item onClick={handleAccountSetting}>Account Setting</Dropdown.Item>
                <Dropdown.Item onClick={handleSignOut}>Logout</Dropdown.Item>
              </Dropdown>
            ) : (
              <Nav.Item>Login</Nav.Item>
            )}
          </Nav>
        </Navbar>
      </div>
    )
}

export default NavBar