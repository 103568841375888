import React, { useState, useContext, useEffect } from 'react';
import { Container,  Content, Form, Button, useToaster, Message,Table, Modal,Pagination,Input,Dropdown,DatePicker,} from 'rsuite';
import { AuthContext } from '../../context/authContext';
import NavBar from '../../components/NavBar/NavBar';
import { doc, serverTimestamp, setDoc, collection, getDocs, updateDoc } from "firebase/firestore"; 
import { db } from "../../firebase";
import { addNumbersToData } from '../../Helpers/AddNumber';
import CustomerHeader from '../../components/Header/Header';
import { OFFICESLOTS } from '../../constants/constants';

const { Column, HeaderCell, Cell } = Table;

const venueData = [
    "Conference Hall",
    "Private Meeting Room",
    "Cold Call Room 1",
    "Cold Call Room 2",
    "Cold Call Room 3",
];

const MeetingRoomBookingComponent = ({ expand }) => {
    const { currentUser } = useContext(AuthContext);
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [open, setOpen] = useState(false);
    const [venue, setVenue] = useState(venueData[0]);
    const [bookingDate, setBookingDate] = useState(null);
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const toaster = useToaster();
    const [selectedBookingId, setSelectedBookingId] = useState(null);
    const [cancelOpen, setCancelOpen] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const querySnapshot = await getDocs(collection(db, "bookings"));
            const bookingsData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            bookingsData.sort((a, b) => a.employee.localeCompare(b.employee));
            setData(bookingsData);
        };
        fetchData();
    }, []);

    const handleBooking = async () => {
        if (!bookingDate || !startTime || !endTime) {
            toaster.push(<Message type='warning'>Please select a date, start time, and end time.</Message>);
            return;
        }

        const newBooking = {
            employee: currentUser.name,
            uid: currentUser.uid,
            venue,
            bookingDate: bookingDate ? bookingDate.toISOString().split('T')[0] : "",
            startTime,
            endTime,
            status: "Booked",
            updatedAt: serverTimestamp(),
            updatedBy: currentUser.uid
        };

        await setDoc(doc(collection(db, "bookings")), newBooking);

        setData([...data, newBooking]);
        handleClose();
        toaster.push(<Message type='success'>Office slots booked successfully!</Message>);
    };

    const handleConfirmCancel = async () => {
        const bookingRef = doc(db, "bookings", selectedBookingId);
        await updateDoc(bookingRef, { status: "Cancelled", uid: currentUser.uid, updatedAt: serverTimestamp() });
        setData(data.map(item => item.id === selectedBookingId ? { ...item, status: "Cancelled" } : item));
        handleCancelClose();
        toaster.push(<Message type='success'>Booking cancelled successfully!</Message>);
    };

    const handleOpen = () => setOpen(true);
    
    const handleClose = () => {
        setOpen(false);
    };

    const handleCancelOpen = (id) => {
        setSelectedBookingId(id);
        setCancelOpen(true);
    };

    const handleCancelClose = () => setCancelOpen(false);

    const handleSearch = (value) => {
        setSearchQuery(value);
    };

    const filteredData = data.filter((item) =>
        item.employee.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.venue.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const numberedData = addNumbersToData(filteredData);

    return (
        <>
        <div className="registration" style={{ flex: 1 }}>
            <Container style={{ marginLeft: expand ? '260px' : '56px',}}>
                <NavBar/>
                <CustomerHeader title={OFFICESLOTS}/>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div style={{ textAlign: "left", margin: "20px", paddingLeft: "20px" }}>
                        <Button appearance="primary" onClick={handleOpen}>Booking slot</Button>
                        <Modal open={open} onClose={handleClose}>
                            <Modal.Header>
                                <Modal.Title>Book Office Slots</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form style={{ textAlign: 'left', marginLeft: "40px", marginTop: "40px"}} >
                                    <Form.Group controlId="employee">
                                        <Form.ControlLabel>Employee Name</Form.ControlLabel>
                                        <Form.Control name="employee" value={currentUser.name} />
                                    </Form.Group>
                                    <Form.Group controlId="venue">
                                        <Form.ControlLabel>Venue</Form.ControlLabel>
                                        <Dropdown title={venue || "Venue"} onSelect={(value) => setVenue(value)}>
                                            {venueData.map((data) => (
                                                <Dropdown.Item key={data} eventKey={data}>{data}</Dropdown.Item>
                                            ))}
                                        </Dropdown>
                                    </Form.Group>
                                    <Form.Group controlId="date">
                                        <Form.ControlLabel>Date</Form.ControlLabel>
                                        <DatePicker oneTap onChange={(value) => setBookingDate(value)} />
                                    </Form.Group>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "300px" }}>
                                        <Form.Group controlId="startTime">
                                            <Form.ControlLabel>Start Time</Form.ControlLabel>
                                            <Input type="time" onChange={(value) => setStartTime(value)} />
                                        </Form.Group>
                                        <Form.Group controlId="endTime">
                                            <Form.ControlLabel>End Time</Form.ControlLabel>
                                            <Input type="time" onChange={(value) => setEndTime(value)} />
                                        </Form.Group>
                                    </div>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button appearance="primary" onClick={handleBooking}>
                                    Book
                                </Button>
                                <Button onClick={handleClose} appearance="subtle">
                                    Cancel
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <div style={{ height: "80px", justifyContent: "center", marginTop: "20px", marginRight: "20px", marginLeft: "20px" }}>
                        <Input 
                            placeholder="Search by employee or venue"
                            value={searchQuery}
                            onChange={handleSearch}
                            style={{ width: 300 }}
                        />
                    </div>
                </div>
                <Content style={{marginLeft: "20px",}}>
                    <Table
                        height={400}
                        data={numberedData}
                        onRowClick={rowData => {
                            console.log(rowData);
                        }}
                    >
                        <Column width={60} align="center" fixed>
                            <HeaderCell>No</HeaderCell>
                            <Cell dataKey="number" />
                        </Column>
                        <Column width={200}>
                            <HeaderCell>Employee</HeaderCell>
                            <Cell dataKey="employee" style={{ textAlign: "left" }} />
                        </Column>
                        <Column width={200}>
                            <HeaderCell>Venue</HeaderCell>
                            <Cell dataKey="venue" />
                        </Column>
                        <Column width={100}>
                            <HeaderCell>Booking Date</HeaderCell>
                            <Cell dataKey="bookingDate" />
                        </Column>
                        <Column width={100}>
                            <HeaderCell>Start Time</HeaderCell>
                            <Cell dataKey="startTime" />
                        </Column>
                        <Column width={150}>
                            <HeaderCell>End Time</HeaderCell>
                            <Cell dataKey="endTime" />
                        </Column>
                        <Column width={150}>
                            <HeaderCell>Status</HeaderCell>
                            <Cell dataKey="status" />
                        </Column>
                        <Column width={80} fixed="right">
                            <HeaderCell>Action</HeaderCell>
                            <Cell style={{ padding: '6px' }}>
                                {rowData => rowData.uid === currentUser.uid && rowData.status !== "Cancelled" && (
                                    <Button appearance="link" onClick={() => handleCancelOpen(rowData.id)}>
                                        Cancel
                                    </Button>
                                )}
                            </Cell>
                        </Column>
                    </Table>
                    <Pagination total={numberedData.length} limit={10} activePage={activePage} onChangePage={setActivePage} style={{ marginLeft: "20px", marginTop: "20px", marginBottom: "40px"}}/>
                </Content>
            </Container>
        </div>
        <Modal open={cancelOpen} onClose={handleCancelClose}>
            <Modal.Header>
                <Modal.Title>Cancel Booking</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to cancel this booking?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button appearance="primary" onClick={handleConfirmCancel}>
                    Confirm
                </Button>
                <Button onClick={handleCancelClose} appearance="subtle">
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </>
    );
};

export default MeetingRoomBookingComponent;
