import React, { useState } from 'react';
import SideBar1 from '../../components/SideBar1/SideBar1';
import SubsalesOrderComponents from './SubsalesOrderComponent';

const SalesOrder = () => {
    const [expand, setExpand] = useState(true);

    const handleToggleExpand = () => {
        setExpand(!expand);
    };

    return (
        <div style={{ display: 'flex' }}>
            <SideBar1 expand={expand} onToggleExpand={handleToggleExpand} />
            <SubsalesOrderComponents expand={expand} />
        </div>
    );
}

export default SalesOrder;
