import React, { useState, useEffect, useContext } from 'react';
import ParentComponent from '../../components/ParentComponent/ParentComponent';
import { Container, Content, Table, Loader, Pagination, Modal, Form, Button } from 'rsuite';
import NavBar from '../../components/NavBar/NavBar';
import CustomerHeader from '../../components/Header/Header';
import { CLAIMREQUEST, CLAIMSDB, USERSDB, VIEWPROJECTSDETAILSURL, VIEWRENTALSDETAILSURL, VIEWSUBSALESDETAILSURL } from '../../constants/constants';
import { db } from '../../firebase';
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { AuthContext } from '../../context/authContext';
import { useNavigate } from 'react-router-dom';

const { Column, HeaderCell, Cell } = Table;

const ClaimsRequestComponent = ({ expand }) => {
    const [claims, setClaims] = useState([]);
    const [filteredClaims, setFilteredClaims] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activePage, setActivePage] = useState(1);
    const [openApproveModal, setOpenApproveModal] = useState(false);
    const [openReturnModal, setOpenReturnModal] = useState(false);
    const [selectedClaim, setSelectedClaim] = useState(null);
    const [remark, setRemark] = useState("");
    const itemsPerPage = 10;
    const navigate = useNavigate();

    const { currentUser } = useContext(AuthContext);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const claimsSnapshot = await getDocs(collection(db, CLAIMSDB));
                const claimsData = claimsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

                const usersSnapshot = await getDocs(collection(db, USERSDB));
                const usersData = usersSnapshot.docs.reduce((acc, doc) => {
                    acc[doc.id] = doc.data().name;
                    return acc;
                }, {});

                const claimsWithUserNames = claimsData.map((claim, index) => ({
                    ...claim,
                    agent: usersData[claim.uid] || 'Unknown',
                    number: index + 1 + (activePage - 1) * itemsPerPage
                }));

                setClaims(claimsWithUserNames);
                setFilteredClaims(claimsWithUserNames.slice(0, itemsPerPage));
            } catch (error) {
                console.error("Error fetching claims data: ", error);
            }
            setLoading(false);
        };

        fetchData();
    }, [activePage]);

    useEffect(() => {
        const startIndex = (activePage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setFilteredClaims(claims.slice(startIndex, endIndex));
    }, [activePage, claims]);

    const handleApprove = async () => {
        if (selectedClaim) {
            try {
                const claimDocRef = doc(db, CLAIMSDB, selectedClaim.id);
                await updateDoc(claimDocRef, { status: 2 });
                setClaims(prevClaims => prevClaims.map(claim => claim.id === selectedClaim.id ? { ...claim, status: 2 } : claim));
                setOpenApproveModal(false);
            } catch (error) {
                console.error("Error approving claim: ", error);
            }
        }
    };

    const handleReturn = async () => {
        if (selectedClaim) {
            try {
                const claimDocRef = doc(db, CLAIMSDB, selectedClaim.id);
                await updateDoc(claimDocRef, { status: 3, remark });
                setClaims(prevClaims => prevClaims.map(claim => claim.id === selectedClaim.id ? { ...claim, status: 3, remark } : claim));
                setOpenReturnModal(false);
            } catch (error) {
                console.error("Error returning claim: ", error);
            }
        }
    };

    const openApproveClaimModal = (claim) => {
        setSelectedClaim(claim);
        setOpenApproveModal(true);
    };

    const openReturnClaimModal = (claim) => {
        setSelectedClaim(claim);
        setOpenReturnModal(true);
    };

    const handleRowClick = (rowData) => {
        const { category, docId } = rowData;
        let path = '';
        let state = {};

        switch (category) {
            case 'Rental':
                path = `/${VIEWRENTALSDETAILSURL}/${docId}`;
                state = { from: 'Claims' };
                break;
            case 'Subsale':
                path = `/${VIEWSUBSALESDETAILSURL}/${docId}`;
                state = { from: 'Claims' };
                break;
            case 'Project':
                path = `/${VIEWPROJECTSDETAILSURL}/${docId}`;
                state = { from: 'Claims' };
                break;
            default:
                break;
        }

        if (path) {
            navigate(path, { state });
        }
    };

    return (
        <div className="claim-request" style={{ flex: 1 }}>
            <Container style={{ marginLeft: expand ? '260px' : '56px' }}>
                <NavBar />
                <CustomerHeader title={CLAIMREQUEST} />
                <Content style={{ marginTop: "20px" }}>
                    {loading ? (
                        <Loader content="Loading..." />
                    ) : (
                        <div>
                            <Table data={filteredClaims} autoHeight onRowClick={handleRowClick}>
                                <Column width={50} align="center" fixed>
                                    <HeaderCell>No</HeaderCell>
                                    <Cell dataKey="number" />
                                </Column>
                                <Column width={200} flexGrow={1}>
                                    <HeaderCell>Agent</HeaderCell>
                                    <Cell dataKey="agent" />
                                </Column>
                                <Column width={200} flexGrow={1}>
                                    <HeaderCell>Category</HeaderCell>
                                    <Cell dataKey="category" />
                                </Column>
                                <Column width={200} flexGrow={1}>
                                    <HeaderCell>Commission</HeaderCell>
                                    <Cell dataKey="calculatedCommission" />
                                </Column>
                                <Column width={200} flexGrow={1}>
                                    <HeaderCell>Status</HeaderCell>
                                    <Cell>
                                        {rowData => rowData.status === 1 ? "In Progress" : rowData.status === 2 ? "Completed" : "Returned"}
                                    </Cell>
                                </Column>
                            </Table>
                            <Pagination
                                total={claims.length}
                                limit={itemsPerPage}
                                activePage={activePage}
                                onChangePage={setActivePage}
                                style={{ marginLeft: "20px", marginTop: "20px", marginBottom: "40px" }}
                            />
                        </div>
                    )}
                </Content>
            </Container>

            <Modal open={openApproveModal} onClose={() => setOpenApproveModal(false)}>
                <Modal.Header>
                    <Modal.Title>Approve Claim</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to approve this claim?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleApprove} appearance="primary">Approve</Button>
                    <Button onClick={() => setOpenApproveModal(false)} appearance="subtle">Cancel</Button>
                </Modal.Footer>
            </Modal>

            <Modal open={openReturnModal} onClose={() => setOpenReturnModal(false)}>
                <Modal.Header>
                    <Modal.Title>Return Claim</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form fluid>
                        <Form.Group controlId="remark">
                            <Form.ControlLabel>Remark</Form.ControlLabel>
                            <Form.Control name="remark" value={remark} onChange={value => setRemark(value)} as="textarea" rows={3} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleReturn} appearance="primary">Return</Button>
                    <Button onClick={() => setOpenReturnModal(false)} appearance="subtle">Cancel</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

const ClaimsRequest = () => {
    return (
        <ParentComponent>
            <ClaimsRequestComponent />
        </ParentComponent>
    )
}

export default ClaimsRequest;
