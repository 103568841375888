import React, { useState } from 'react';
import SideBar1 from '../../components/SideBar1/SideBar1';

const ParentComponent = ({ children }) => {
  const [expand, setExpand] = useState(true);

  const handleToggleExpand = () => {
    setExpand(!expand);
  };

  // Clone the children element and pass down expand and handleToggleExpand as props
  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, { expand, handleToggleExpand })
  );

  return (
    <div>
      <SideBar1 expand={expand} onToggleExpand={handleToggleExpand} />
      {childrenWithProps}
    </div>
  );
};

export default ParentComponent;
