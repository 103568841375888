import React from 'react'
import { 
    Form, 
    Button, 
    FlexboxGrid,
    Modal,
    Input,
} from 'rsuite';

const SubsalesViewModal = ({viewModalOpen, handleCloseViewModal, selectedRowData}) => {
    const downloadFile = (url) => {
        window.open(url, '_blank');
    };
    
  return (
    <Modal keyboard={false} open={viewModalOpen} onClose={handleCloseViewModal}>
        <Modal.Header>
            <Modal.Title>View Sales Order</Modal.Title>
        </Modal.Header>
        {
            selectedRowData && <Modal.Body>
            <Form fluid>
                <div style={{marginBottom: "20px"}}>
                    <h5>Property Details</h5>
                </div>
                <FlexboxGrid style={{marginBottom: "20px"}}>
                    <FlexboxGrid.Item colspan={8}>
                        <Form.Group controlId="lotNumber">
                            <Form.ControlLabel>Lot Number</Form.ControlLabel>
                            <Form.Control  name="lotNumber" value={selectedRowData.lotNumber} />
                        </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={8}>
                    <Form.Group controlId="subsale">
                        <Form.ControlLabel>Subsale</Form.ControlLabel>
                        <Form.Control  name="subsale" value={selectedRowData.subsale} />
                    </Form.Group>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                
                <FlexboxGrid style={{marginBottom: "20px"}}>
                    <FlexboxGrid.Item colspan={8}>
                        <Form.Group controlId="nettPrice">
                            <Form.ControlLabel>Nett Price</Form.ControlLabel>
                            <Form.Control name="nettPrice" value={selectedRowData.nettPrice}  />
                           
                        </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={8}>
                    <Form.Group controlId="loanMargin">
                            <Form.ControlLabel>Loan Margin (%)</Form.ControlLabel>
                            <Form.Control name="loanMargin" value={selectedRowData.loanMargin}  />
                           
                        </Form.Group>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                
                <Form.Group controlId="bookingFormUrl" style={{marginBottom: "40px"}}>
                    <Form.ControlLabel>Booking Form</Form.ControlLabel>
                    {
                        selectedRowData.bookingFormUrl !== "" ? 
                        <Button
                            appearance="link"
                            onClick={() => downloadFile(selectedRowData.bookingFormUrl)}
                            style={{ marginLeft: '10px' }}
                        >
                            Download
                        </Button>
                        : <div>N/A</div>
                    }
                </Form.Group>
                <div style={{marginBottom: "20px"}}>
                    <h5>Purchaser Details</h5>
                </div>
                <FlexboxGrid style={{marginBottom: "20px"}}>
                    <FlexboxGrid.Item colspan={8}>
                        <Form.Group controlId="purchaserName">
                            <Form.ControlLabel>Purchaser Name</Form.ControlLabel>
                            <Form.Control name="purchaserName" value={selectedRowData.purchaserName}/>
                        </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={8}>
                        <Form.Group controlId="purchaserContact">
                            <Form.ControlLabel>Purchaser Contact</Form.ControlLabel>
                            <Form.Control name="purchaserContact" value={selectedRowData.purchaserContact} />
                        </Form.Group>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <FlexboxGrid style={{marginBottom: "40px"}}>
                    <FlexboxGrid.Item colspan={8}>
                        <Form.Group controlId="bookDate">
                            <Form.ControlLabel>Booking Date</Form.ControlLabel>
                            <Form.Control  name="bookDate" value={selectedRowData.bookDate && new Date(selectedRowData.bookDate.seconds * 1000).toLocaleDateString()} />
                        </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={8}>
                        <Form.Group controlId="status">
                            <Form.ControlLabel>Status</Form.ControlLabel>
                            <Form.Control  name="status" value={selectedRowData.status} />
                        </Form.Group>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                
                <div style={{marginBottom: "20px"}}>
                    <h5>Document Details</h5>
                </div>
                
                <FlexboxGrid style={{marginBottom: "20px"}}>
                    <FlexboxGrid.Item colspan={8}>
                        <Form.Group controlId="source" >
                            <Form.ControlLabel>Source</Form.ControlLabel>
                            <Form.Control  name="source" value={selectedRowData.source} />
                        </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={8}>
                        <Form.Group controlId="referralName">
                            <Form.ControlLabel>Referral Name</Form.ControlLabel>
                            <Form.Control name="referralName" value={selectedRowData.referralName} />
                        </Form.Group>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                
                <div style={{paddingRight: "40px"}}>
                    <Form.Group controlId="remark" style={{marginBottom: "20px"}}>
                        <Form.ControlLabel>Remark</Form.ControlLabel>
                        <Input as="textarea" rows={3} placeholder="Remarks..." name="remark" value={selectedRowData.remark}/>
                    </Form.Group>
                </div>
                                
                

                <FlexboxGrid style={{marginBottom: "20px"}}>
                    <FlexboxGrid.Item colspan={8}>
                        <Form.Group controlId="spaCopyUrl">
                            <Form.ControlLabel>SPA Copy</Form.ControlLabel>
                            {
                                selectedRowData.spaCopyUrl !== "" 
                                ? <Button
                                    appearance="link"
                                    onClick={() => downloadFile(selectedRowData.spaCopyUrl)}
                                    style={{ marginLeft: '10px' }}
                                >
                                    Download
                                </Button>
                                :<div>N/A</div>
                            }
                        </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={8}>
                        <Form.Group controlId="spaDate">
                            <Form.ControlLabel>SPA Date</Form.ControlLabel>
                            <Form.Control value={selectedRowData.spaDate && new Date(selectedRowData.spaDate.seconds * 1000).toLocaleDateString()} />
                        </Form.Group>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                
                <FlexboxGrid style={{marginBottom: "20px"}}>
                    <FlexboxGrid.Item colspan={8}>
                    <Form.Group controlId="agreementSignedUrl">
                        <Form.ControlLabel>Agreement Signed</Form.ControlLabel>
                        {
                            selectedRowData.agreementSignedUrl !== ""
                            ?
                            <Button
                                appearance="link"
                                onClick={() => downloadFile(selectedRowData.agreementSignedUrl)}
                                style={{ marginLeft: '10px' }}
                            >
                                Download
                            </Button>
                            : <div>N/A</div>
                        }
                    </Form.Group>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={8}>
                        <Form.Group controlId="agreementSignDate">
                            <Form.ControlLabel>Agreement Sign Date</Form.ControlLabel>
                            <Form.Control  name="agreementSignDate" value={selectedRowData.agreementSignDate && new Date(selectedRowData.agreementSignDate.seconds * 1000).toLocaleDateString()} />
                
                        </Form.Group>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                
                
                <Form.Group controlId="cancellationFormUrl" style={{marginBottom: "40px"}}>
                    <Form.ControlLabel>Cancellation Form</Form.ControlLabel>
                    {
                        selectedRowData.cancellationFormUrl !== "" 
                        ? <Button
                            appearance="link"
                            onClick={() => downloadFile(selectedRowData.cancellationFormUrl)}
                            style={{ marginLeft: '10px' }}
                        >
                            Download
                        </Button>
                        :<div>N/A</div>
                    }
                </Form.Group>
                <h5 style={{marginBottom: "20px"}}>Agent Details</h5>
                {selectedRowData.salesAgent.map((agent, index) => (
                    <FlexboxGrid key={index} style={{marginBottom: "20px"}}>
                        <FlexboxGrid.Item colspan={8}>
                            <Form.Group controlId={`salesAgent-${index}`}>
                                <Form.ControlLabel>Sales Agent</Form.ControlLabel>
                                <Form.Control name={`salesAgent-${index}`} value={agent} />
                            </Form.Group>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={6}>
                            <Form.Group controlId={`commissionShare-${index}`}>
                                <Form.ControlLabel>Commission Share</Form.ControlLabel>
                                <Form.Control name={`commissionShare-${index}`} value={selectedRowData.commissionShare[index]} />
                            </Form.Group>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                ))}
                
                
            </Form>
            </Modal.Body>
        }
        <Modal.Footer>
            <Button onClick={handleCloseViewModal} appearance="subtle">
                Close
            </Button>
        </Modal.Footer>
    </Modal>
  )
}

export default SubsalesViewModal