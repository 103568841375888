import React, { useState, useEffect, useContext } from 'react';
import { Container, Content, Form, Button, FlexboxGrid, Input, Message, useToaster, Modal } from 'rsuite';
import { useParams } from 'react-router-dom';
import SideBar1 from '../../components/SideBar1/SideBar1';
import NavBar from '../../components/NavBar/NavBar';
import { db } from '../../firebase';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import ViewDataComponent from '../../components/ViewDataComponent/ViewDataComponent';
import { AuthContext } from '../../context/authContext';

const ViewContactComponent = () => {
    const [expand, setExpand] = useState(true);

    const handleToggleExpand = () => {
        setExpand(!expand);
    };

    return (
        <div style={{ display: 'flex' }}>
            <SideBar1 expand={expand} onToggleExpand={handleToggleExpand} />
            <ViewContactData expand={expand} />
        </div>
    );
}

export default ViewContactComponent;

const ViewContactData = ({ expand }) => {
    const { id } = useParams(); // Extract the claim ID from the URL
    const [contactData, setContactData] = useState(null);
    const toaster = useToaster();
    const colSpanTitle = 3;

    useEffect(() => {
        const fetchSalesOrderData = async () => {
            try {
                const saleDoc = await getDoc(doc(db, "contacts", id));
                if (saleDoc.exists()) {
                    const data = saleDoc.data();
                    setContactData(data);
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error("Error fetching claim data: ", error);
            }
        };

        fetchSalesOrderData();
    }, [id, toaster]);

    if (!contactData) {
        return (
            <Container style={{ marginLeft: expand ? '260px' : '56px' }}>
                <NavBar title="View Leave Application" />
                <Content style={{ padding: "20px", textAlign: "left" }}>
                    <Message type="info">Loading leave details...</Message>
                </Content>
            </Container>
        );
    }

    return (
        <Container style={{ marginLeft: expand ? '260px' : '56px' }}>
            <NavBar title="View Contact Details" />
            <Content style={{ padding: "20px", textAlign: "left" }}>
                <Form fluid style={{ marginLeft: "20px" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <h5 style={{ marginBottom: "30px" }}>Leave Information</h5>  
                    </div>
                    <ViewDataComponent title="Name" data={contactData.name} />
                    <ViewDataComponent title="Contact No." data={contactData.contactNumber} />
                    <ViewDataComponent title="Date Added" data={new Date(contactData.timeStamp.seconds * 1000).toLocaleDateString()} />
                    <FlexboxGrid style={{ marginBottom: "30px" }} align="middle">
                        <FlexboxGrid.Item colspan={colSpanTitle}>
                            <Form.ControlLabel>Remark</Form.ControlLabel>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={8}>
                            <Input as="textarea" readOnly rows={3} placeholder="Remarks..." name="remark" value={contactData.remark}/>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Form>
            </Content>   
        </Container>
    );
};
