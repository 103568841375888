import {  
    Button, 
    Modal,
} from 'rsuite';

const DeleteModal = ({deleteOpen, handleDeleteClose, message, handleDelete}) => {
  return (
    <div>
        <Modal open={deleteOpen} onClose={handleDeleteClose}>
            <Modal.Header>
                <Modal.Title>Delete Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleDelete} appearance="primary">
                    Delete
                </Button>
                <Button onClick={handleDeleteClose} appearance="subtle">
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    </div>
  )
}

export default DeleteModal