import React, { useEffect, useState, useContext } from 'react';
import { Container, Content, FlexboxGrid, Grid, Row, Col, Message, useToaster } from 'rsuite';
import NavBar from '../../components/NavBar/NavBar';
import { AuthContext } from '../../context/authContext';
import { db } from '../../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { addNumbersToData } from '../../Helpers/AddNumber';

const HomeComponent = ({ expand }) => {
  const { currentUser } = useContext(AuthContext);

  return (
    <div className="show-fake-browser sidebar-page" style={{ flex: 1 }}>
      <Container style={{ marginLeft: expand ? '260px' : '56px' }}>
        <Container>
          <NavBar/>
          <Content>
          
          </Content>
        </Container>
      </Container>
    </div>
  );
};

export default HomeComponent;

