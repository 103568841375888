import { 
  Container,  
  Content, 
  Form, 
  Button, 
  FlexboxGrid, 
  Dropdown, 
  DatePicker, 
  useToaster, 
  Message,
  Input,
  Table,
  Modal,
  Pagination
} from 'rsuite';
import { useState, useContext, useEffect } from "react";
import { doc, serverTimestamp, setDoc, getDocs, collection, updateDoc } from "firebase/firestore"; 
import { auth, db } from "../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { generateRandomPassword } from '../../Helpers/GenerateRandomPassword';
import NavBar from '../../components/NavBar/NavBar';
import { AuthContext } from "../../context/authContext";
import emailjs from 'emailjs-com';
import Header from '../../components/Header/Header';
import { USERS, GENDERDATA, POSITIONS } from '../../constants/constants';
import { useNavigate } from 'react-router-dom';
import './RegisterNewUserComponent.css'; // Import CSS for styling
import { reloadPage } from '../../Helpers/ReloadPage';

const { Column, HeaderCell, Cell } = Table;

const RegisterNewUserComponent = ({ expand }) => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: generateRandomPassword(),
    gender: GENDERDATA[0],
    position: POSITIONS[0],
    ic: "",
    contact: "",
    joinedDate: null,
    recruiterId: "",
    recruiterName: "",
    downline: [],
    insertedAt: new Date(),
    updatedAt: new Date(),
    updatedBy: currentUser.name,
    salary: 0,
    medLeaves: 0,
    annualLeaves: 0,
    subsalesTier: 70,
    projectTier: 0,
  });

  const [recruiters, setRecruiters] = useState([]);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const toaster = useToaster();
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      const querySnapshot = await getDocs(collection(db, "users"));
      const userList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUsers(userList);
      setFilteredUsers(userList);
      setRecruiters(userList);
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    if (searchKeyword === '') {
      setFilteredUsers(users);
    } else {
      const filtered = users.filter(user =>
        user.name.toLowerCase().includes(searchKeyword.toLowerCase())
      );
      setFilteredUsers(filtered);
    }
  }, [searchKeyword, users]);

  const sendEmail = async (userDetails) => {
    const templateParams = {
      from_name: "Admin, Ivan",
      from_email: "ivanlim608@gmail.com", // Replace with your admin email
      subject: "Welcome to Our Platform",
      message: `Hi ${userDetails.name}, your account has been successfully created. Your initial password is ${userDetails.password}. Please login to https://application.ivanlimchoonkiat.com/ using your email and initial password`,
      to_name: userDetails.name,
      to_email: userDetails.email,
    };

    try {
      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID, 
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID, 
        templateParams,
        process.env.REACT_APP_EMAILJS_USER_ID
      );
      console.log('Email sent successfully!');
    } catch (error) {
      console.error('Email sending error:', error);
    }
  };

  const updateRecruiterDownline = async (recruiterId, newUserEmail) => {
    try {
      const recruiterRef = doc(db, "users", recruiterId);
      await updateDoc(recruiterRef, {
        downline: [...formData.downline, newUserEmail],
        updatedAt: serverTimestamp(),
      });
    } catch (e) {
      console.error('Error updating recruiter downline: ', e);
    }
  };

  const handleAddUser = async () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Full Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.ic) newErrors.ic = "IC Number is required";
    if (!formData.contact) newErrors.contact = "Contact is required";
    if (!formData.joinedDate) newErrors.joinedDate = "Joined date is required";
    if (!formData.recruiterId) newErrors.recruiterId = "Recruiter is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const res = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
      await setDoc(doc(db, "users", res.user.uid), {
        ...formData,
        insertedAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
      toaster.push(<Message type="success">User has been registered successfully!</Message>);
      await sendEmail(formData); 
      await updateRecruiterDownline(formData.recruiterId, formData.email);
      reloadPage();
      setErrors({});
      setShowModal(false);
    } catch (e) {
      console.error('Error adding user: ', e);
    }
  };

  const handleChange = (value, field) => {
    setFormData({
      ...formData,
      [field]: value
    });
  };

  const handleSelectRecruiter = (id, name) => {
    setFormData({
      ...formData,
      recruiterId: id,
      recruiterName: name
    });
  };

  const handleRowClick = (rowData) => {
    navigate(`/view-user/${rowData.id}`);
  };

  const paginatedUsers = filteredUsers.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div className="registration" style={{flex: 1}}>
      <Container style={{ marginLeft: expand ? '260px' : '56px' }}>
        <Container>
          <NavBar user={currentUser}/>
          <div className="header-container">
            <Header title={USERS}/>
            <div className="header-actions" 
              style={{
                display: "flex", 
                flexDirection: "row", 
                justifyContent: "space-between",
                margin: "20px"
                
              }}>
              <Button appearance="primary" onClick={handleOpen}>Add User</Button>
              <Input 
                placeholder="Search by name"
                value={searchKeyword}
                onChange={setSearchKeyword}
                style={{ width: 300 }}
              />
            </div>
          </div>
          <Content>
            <Table
              data={paginatedUsers}
              autoHeight
              onRowClick={handleRowClick}
            >
              <Column width={50} align="center" fixed>
                <HeaderCell>No</HeaderCell>
                <Cell>{(rowData, rowIndex) => rowIndex + 1}</Cell>
              </Column>
              <Column width={200} flexGrow={1}>
                <HeaderCell>Name</HeaderCell>
                <Cell dataKey="name" />
              </Column>
              <Column width={200} flexGrow={1}>
                <HeaderCell>Email</HeaderCell>
                <Cell dataKey="email" />
              </Column>
              <Column width={200} flexGrow={1}>
                <HeaderCell>Recruited By</HeaderCell>
                <Cell dataKey="recruiterName" />
              </Column>
            </Table>
            <Pagination
              activePage={currentPage}
              total={filteredUsers.length}
              limit={itemsPerPage}
              onChangePage={setCurrentPage}
              style={{ marginTop: 20, textAlign: 'center', marginLeft: 20 }}
            />
          </Content>
        </Container>
      </Container>
      <Modal open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Add New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddUser} >

            <Form.Group controlId="name">
                    <Form.ControlLabel>Full Name</Form.ControlLabel>
                    <Form.Control name="name" onChange={(value) => handleChange(value, 'name')}/>
                    {errors.name && <Form.HelpText style={{ color: 'red' }}>{errors.name}</Form.HelpText>}
                  </Form.Group>
                  <Form.Group controlId="email">
                    <Form.ControlLabel>Email</Form.ControlLabel>
                    <Form.Control name="email" type="email" onChange={(value) => handleChange(value, 'email')}/>
                    {errors.email && <Form.HelpText style={{ color: 'red' }}>{errors.email}</Form.HelpText>}
                  </Form.Group>
                  <Form.Group controlId="gender">
                    <Form.ControlLabel>Gender</Form.ControlLabel>
                    <Dropdown title={formData.gender || "Gender"} onSelect={(value) => handleChange(value, 'gender')}>
                      {GENDERDATA.map((data) => (
                        <Dropdown.Item key={data} eventKey={data}>{data}</Dropdown.Item>
                      ))}
                    </Dropdown>
                    {errors.gender && <Form.HelpText style={{ color: 'red' }}>{errors.gender}</Form.HelpText>}
                  </Form.Group>
                  <Form.Group controlId="position">
                    <Form.ControlLabel>Position</Form.ControlLabel>
                    <Dropdown title={formData.position || "Position"} onSelect={(value) => handleChange(value, 'position')}>
                      {POSITIONS.map((data) => (
                        <Dropdown.Item key={data} eventKey={data}>{data}</Dropdown.Item>
                      ))}
                    </Dropdown>
                    {errors.position && <Form.HelpText style={{ color: 'red' }}>{errors.position}</Form.HelpText>}
                  </Form.Group>
                <Form.Group controlId="recruiter">
                  <Form.ControlLabel>Recruiter</Form.ControlLabel>
                  <Dropdown title={formData.recruiterName || "Select Recruiter"} onSelect={(eventKey, event) => handleSelectRecruiter(eventKey, event.target.innerText)}>
                    {recruiters.map((recruiter) => (
                      <Dropdown.Item key={recruiter.id} eventKey={recruiter.id}>{recruiter.name}</Dropdown.Item>
                    ))}
                  </Dropdown>
                  {errors.recruiterId && <Form.HelpText style={{ color: 'red' }}>{errors.recruiterId}</Form.HelpText>}
                </Form.Group>

                <Form.Group controlId="icNumber">
                  <Form.ControlLabel>IC Number</Form.ControlLabel>
                  <Form.Control name="icnumber" type="text" onChange={(value) => handleChange(value, 'ic')}/>
                  {errors.ic && <Form.HelpText style={{ color: 'red' }}>{errors.ic}</Form.HelpText>}
                </Form.Group>
                <Form.Group controlId="contactNumber">
                  <Form.ControlLabel>Contact Number</Form.ControlLabel>
                  <Form.Control name="contactNumber" type="text" onChange={(value) => handleChange(value, 'contact')}/>
                  {errors.contact && <Form.HelpText style={{ color: 'red' }}>{errors.contact}</Form.HelpText>}
                </Form.Group>
                <Form.Group controlId="joinedDate">
                  <Form.ControlLabel>Joined Date</Form.ControlLabel>
                  <DatePicker oneTap onChange={(date) => handleChange(date, 'joinedDate')}/>
                  {errors.joinedDate && <Form.HelpText style={{ color: 'red' }}>{errors.joinedDate}</Form.HelpText>}
                </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button appearance="primary" onClick={handleAddUser}>Submit</Button>
          <Button onClick={handleClose} appearance="subtle">Cancel</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default RegisterNewUserComponent;
