import React from 'react'
import { Container, Content, Form, Button, FlexboxGrid, Input, Message } from 'rsuite';

const ViewDataComponent = ({title, data}) => {
    const colSpanTitle = 3;

    return (
        <div>
            <FlexboxGrid style={{ marginBottom: "10px" }} align="middle">
                <FlexboxGrid.Item colspan={colSpanTitle}>
                    <Form.ControlLabel>{title}</Form.ControlLabel>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={8}>
                    <Form.Control readOnly name="landlordName" value={data} />
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </div>
    )
}

export default ViewDataComponent