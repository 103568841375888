const TITLE = "Property Management System";
const DASHBOARD = "Dashboard";
const PROJECTLIST = "Project Lists";
const SUBSALELIST = "Subsale Lists";
const LEAVES = "Leaves";
const CLAIMS = "Claims";
const DOCUMENTS = "Documents";
const REGISTRATION = "Registration";
const USERSPROFILE = "User's Profile";
const MYPROFILE = "My Profile";
const CHANGEPASSWORD = "Change Password";
const ANNOUNCEMENT = "Announcements";
const OFFICESLOTS = "Office Slots";
const CONTACTS = "Contacts";
const PROJECTORDERS = "Project Orders";
const RENTALORDERS = "Rental Orders";
const SUBSALEORDERS = "Subsale Orders";
const ORDERREQUESTS = "Order Requests";
const RENTALCLAIMDETAILS = "Rental Claim Details";
const PROJECTCLAIMDETAILS = "Project Claim Details";
const SUBSALECLAIMDETAILS = "Subsale Claim Details";
const LEAVEINFORMATION = "Leave Information"
const VIEWSUBSALESORDER = "View Subsale Order";
const VIEWPROJECTSORDER = "View Project Order";
const VIEWRENTALSORDER = "View Rental Order";
const CLAIMREQUEST = "Claim Request";

//url
const VIEWSUBSALESDETAILSURL = "view-subsales-details";
const VIEWPROJECTSDETAILSURL = "view-sales-details";
const VIEWRENTALSDETAILSURL = "view-rental-details";
const CLAIMSREQUESTURL = "claim-request";

const POSITIONS = [
    "Team Executive",
    "Pre Leader",
    "Team Leader",
    "Prime Leader",
    "Team Director",
    "Admin"
]

const GENDERDATA = [
   "Male",
   "Female",
   "Others"
]

const BOOKINGSDB = "bookings";
const CONTACTSDB = "contacts";
const DOCUMENTSDB = "documents";
const LEAVESDB = "leaves";
const PROJECTSDB = "projects";
const RENTALORDERSDB = "rentalOrders";
const SALESORDESDB = "salesOrders";
const SUBSALESDB = "subsales";
const SUBSALESORDERSDB = "subsalesOrders";
const USERSDB = "users";
const CLAIMSDB = "claims";

export {
    TITLE,
    DASHBOARD,
    PROJECTLIST,
    SUBSALELIST,
    LEAVES,
    CLAIMS,
    DOCUMENTS,
    REGISTRATION,
    POSITIONS,
    GENDERDATA,
    USERSPROFILE as USERS,
    MYPROFILE,
    CHANGEPASSWORD,
    ANNOUNCEMENT,
    OFFICESLOTS,
    CONTACTS,
    PROJECTORDERS,
    RENTALORDERS,
    SUBSALEORDERS,
    ORDERREQUESTS,
    RENTALCLAIMDETAILS,
    PROJECTCLAIMDETAILS,
    SUBSALECLAIMDETAILS,
    LEAVEINFORMATION,
    BOOKINGSDB,
    CONTACTSDB,
    DOCUMENTSDB,
    LEAVESDB,
    PROJECTSDB,
    RENTALORDERSDB,
    SALESORDESDB,
    SUBSALESDB,
    SUBSALESORDERSDB,
    CLAIMSDB,
    USERSDB,
    VIEWSUBSALESORDER,
    VIEWPROJECTSORDER,
    VIEWRENTALSORDER,
    VIEWSUBSALESDETAILSURL,
    VIEWPROJECTSDETAILSURL,
    VIEWRENTALSDETAILSURL,
    CLAIMSREQUESTURL,
    CLAIMREQUEST,
}