import { Container, FlexboxGrid, Content } from 'rsuite';
import NavBar from '../../components/NavBar/NavBar';
import { AuthContext } from "../../context/authContext";
import { useContext } from "react";
import { MYPROFILE } from '../../constants/constants';

const ProfileComponent = ({expand}) => {
  const { currentUser } = useContext(AuthContext);

  const formatJoinedDate = (joinedDate) => {
    if (joinedDate && joinedDate.seconds) {
      const date = new Date(joinedDate.seconds * 1000);
      return date.toISOString().substring(0, date.toISOString().indexOf('T'));
    }
    return "N/A";
  }

  const data =[
    {
      attribute: "Name",
      value: currentUser.name || "N/A",
    },
    {
      attribute: "Email",
      value: currentUser.email || "N/A",
    },
    {
      attribute: "Gender",
      value: currentUser.gender || "N/A",
    },
    {
      attribute: "Position",
      value: currentUser.position || "N/A",
    },
    {
        attribute: "Up Line",
        value: currentUser.recruiterName || "N/A",
      },
    {
      attribute: "IC Number",
      value: currentUser.ic || "N/A",
    },
    {
      attribute: "Salary",
      value: `RM ${currentUser.salary}` || "N/A",
    },
    {
      attribute: "Joined Date",
      value: formatJoinedDate(currentUser.joinedDate),
    },
    {
      attribute: "Contact Number",
      value: currentUser.contact || "N/A",
    },
    {
      attribute: "Annual Leaves Available",
      value: currentUser.annualLeaves || "N/A",
    },
    {
      attribute: "Subsales Tier",
      value: currentUser.subsalesTier || "N/A",
    },
    {
      attribute: "Project Tier",
      value: currentUser.projectTier || "N/A",
    },
  ];

  return (
    <div className='profile-component' style={{ flex: 1 }}>
        <Container style={{ marginLeft: expand ? '260px' : '56px' }}>
            <NavBar/>
            <Content>
            <h3 style={{textAlign: "left", marginTop: "10px", marginLeft: "20px"}}>{MYPROFILE}</h3>
            <div style={{marginLeft: "20px", marginTop: "20px"}}>
                {
                data.map((item) => (
                    <FlexboxGrid key={item.attribute}>
                    <FlexboxGrid.Item colspan={4} style={{textAlign: "left", height: "50px", fontWeight: "800"}}>{item.attribute}</FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={12} style={{textAlign: "left", paddingLeft: "10px"}}>{item.value}</FlexboxGrid.Item>
                    </FlexboxGrid>
                ))
                }
            </div>
            </Content>
        </Container>
    </div>
  )
}

export default ProfileComponent;
