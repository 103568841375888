import React, { useState, useEffect, useContext } from 'react';
import { Container, Content, Form, Button, FlexboxGrid, Input, Message, useToaster, Modal } from 'rsuite';
import { useParams } from 'react-router-dom';
import SideBar1 from '../../components/SideBar1/SideBar1';
import NavBar from '../../components/NavBar/NavBar';
import { db } from '../../firebase';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import ViewDataComponent from '../../components/ViewDataComponent/ViewDataComponent';
import { AuthContext } from '../../context/authContext';
import { LEAVEINFORMATION, LEAVESDB } from '../../constants/constants';
import CustomerHeader from '../../components/Header/Header';

const ViewLeavesComponent = () => {
    const [expand, setExpand] = useState(true);

    const handleToggleExpand = () => {
        setExpand(!expand);
    };

    return (
        <div style={{ display: 'flex' }}>
            <SideBar1 expand={expand} onToggleExpand={handleToggleExpand} />
            <ViewLeaveData expand={expand} />
        </div>
    );
}

export default ViewLeavesComponent;

const ViewLeaveData = ({ expand }) => {
    const { id } = useParams(); // Extract the claim ID from the URL
    const [leaveData, setLeaveData] = useState(null);
    const [formData, setFormData] = useState({});
    const [showApprovalModal, setShowApprovalModal] = useState(false);
    const [showRejectionModal, setShowRejectionModal] = useState(false);
    const [rejectionComment, setRejectionComment] = useState("");
    const toaster = useToaster();
    const colSpanTitle = 3;
    const { currentUser } = useContext(AuthContext);

    const downloadFile = (url) => {
        window.open(url, '_blank');
    };

    useEffect(() => {
        const fetchSalesOrderData = async () => {
            try {
                const saleDoc = await getDoc(doc(db, LEAVESDB, id));
                if (saleDoc.exists()) {
                    const data = saleDoc.data();
                    setLeaveData(data);
                    setFormData(data); // Initialize form data with fetched data
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error("Error fetching claim data: ", error);
            }
        };

        fetchSalesOrderData();
    }, [id, toaster]);

    const handleChange = (key, value) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [key]: value
        }));
    };

    const handleApprove = async () => {
        try {
            await updateDoc(doc(db, "leaves", id), { status: "Approved" });
            setShowApprovalModal(false);
            setFormData({ ...formData, status: "Approved" }); // Update local state to reflect the change
            toaster.push(<Message type="success" description="Leave application approved successfully." />);
        } catch (error) {
            toaster.push(<Message type="error" description="Failed to update status." />);
        }
    };

    const handleReject = async () => {
        if (!rejectionComment.trim()) {
            toaster.push(<Message type="error">Please provide a reason for rejection.</Message>);
            return;
        }
        try {
            await updateDoc(doc(db, "leaves", id), { status: "Rejected", comments: rejectionComment });
            setShowRejectionModal(false);
            setFormData({ ...formData, status: "Rejected", comments: rejectionComment }); // Update local state
            toaster.push(<Message type="success">Leave application rejected successfully.</Message>);
        } catch (error) {
            toaster.push(<Message type="error">Failed to update status.</Message>);
        }
    };

    const ApprovalModal = () => (
        <Modal open={showApprovalModal} onClose={() => setShowApprovalModal(false)}>
            <Modal.Header>
                <Modal.Title>Confirm Approval</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to approve this leave application?</Modal.Body>
            <Modal.Footer>
                <Button appearance='primary' onClick={handleApprove}>Approve</Button>
                <Button appearance='ghost' onClick={() => setShowApprovalModal(false)}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );

    if (!leaveData) {
        return (
            <Container style={{ marginLeft: expand ? '260px' : '56px' }}>
                <NavBar title="View Leave Application" />
                <Content style={{ padding: "20px", textAlign: "left" }}>
                    <Message type="info">Loading leave details...</Message>
                </Content>
            </Container>
        );
    }

    return (
        <Container style={{ marginLeft: expand ? '260px' : '56px' }}>
            <NavBar/>
            <CustomerHeader title={LEAVEINFORMATION}/>
            <Content style={{textAlign: "left", marginLeft: "20px", marginTop: "20px" }}>
                <Form fluid >
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        
                        {formData.status === "Pending" && currentUser.position === "Admin" && (
                            <div className='action'>
                                <Button appearance='primary' onClick={() => setShowApprovalModal(true)} style={{ marginRight: "20px" }}>Approve</Button>
                                <Button appearance='ghost' onClick={() => setShowRejectionModal(true)}>Reject</Button>
                            </div>
                        )}
                        <ApprovalModal />
                        <Modal open={showRejectionModal} onClose={() => setShowRejectionModal(false)}>
                            <Modal.Header>
                                <Modal.Title>Confirm Rejection</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p style={{marginBottom: "20px"}}>Please provide a reason:</p>
                                <Input as="textarea" rows={3} value={rejectionComment} onChange={value => setRejectionComment(value)} />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={handleReject} appearance='primary'>Reject</Button>
                                <Button onClick={() => setShowRejectionModal(false)} appearance="ghost">Cancel</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <ViewDataComponent title="Status" data={formData.status} />
                    {
                        formData.status === "Rejected" && 
                    <ViewDataComponent title="Comment" data={formData.comments} />

                    }
                    <ViewDataComponent title="Applicant's Name" data={formData.applicant} />

                    <FlexboxGrid style={{ marginBottom: "20px" }} align="middle">
                        <FlexboxGrid.Item colspan={colSpanTitle}>
                            <Form.ControlLabel>Document</Form.ControlLabel>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={8}>
                            {formData.documentUrl ? (
                                <Button
                                    appearance="link"
                                    onClick={() => downloadFile(formData.documentUrl)}
                                    style={{ marginLeft: '10px' }}
                                >
                                    Download
                                </Button>
                            ) : (
                                <div>N/A</div>
                            )}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <ViewDataComponent title="Start Date" data={formData.startDate} />
                    <ViewDataComponent title="End Date" data={formData.endDate} />
                    <FlexboxGrid style={{ marginBottom: "30px" }} align="middle">
                        <FlexboxGrid.Item colspan={colSpanTitle}>
                            <Form.ControlLabel>Remark</Form.ControlLabel>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={8}>
                            <Input as="textarea" readOnly rows={3} placeholder="Remarks..." name="remark" value={formData.remark} onChange={value => handleChange('remark', value)} />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Form>
            </Content>
            
        </Container>
    );
};
