import React, { useState } from 'react';
import SideBar1 from '../../components/SideBar1/SideBar1';
import RentalsOrderComponents from './RentalsOrderComponents';

const RentalsOrder = () => {
    const [expand, setExpand] = useState(true);

    const handleToggleExpand = () => {
        setExpand(!expand);
    };

    return (
        <div style={{ display: 'flex' }}>
            <SideBar1 expand={expand} onToggleExpand={handleToggleExpand} />
            <RentalsOrderComponents expand={expand} />
        </div>
    );
}

export default RentalsOrder;
