import React, { useState, useContext, useEffect } from 'react';
import NavBar from '../../components/NavBar/NavBar';
import TrashIcon from '@rsuite/icons/Trash';
import { Container, Content, Form, Button, Dropdown, useToaster, DatePicker, FlexboxGrid, Message, Table, Modal, Pagination, Input, InputGroup, IconButton, Tooltip, Whisper } from 'rsuite';
import { addNumbersToData } from '../../Helpers/AddNumber';
import { AuthContext } from '../../context/authContext';
import { db } from '../../firebase';
import { doc, serverTimestamp, addDoc, collection, getDocs, updateDoc, deleteDoc } from "firebase/firestore"
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import { reloadPage } from '../../Helpers/ReloadPage';
import DeleteModal from '../salesOrder/DeleteModal';
import SubsalesViewModal from './ViewModal';
import InfoRoundIcon from '@rsuite/icons/InfoRound';
import { useNavigate } from 'react-router-dom';
import { SUBSALEORDERS, SUBSALESDB, VIEWSUBSALESDETAILSURL } from '../../constants/constants';
import CustomerHeader from '../../components/Header/Header';

const { Column, HeaderCell, Cell } = Table;

const statusData = [
    "Pending",
    "Cancel",
    "Ready To Convert",
]

const SubsalesOrderComponents = ({ expand }) => {
    const { currentUser } = useContext(AuthContext);
    const [activePage, setActivePage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [open, setOpen] = useState(false);
    const [errors, setErrors] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [editId, setEditId] = useState('');
    const [backdrop, setBackdrop] = useState('static');
    const toaster = useToaster();
    const [data, setData] = useState([]);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();

    const handleCloseViewModal = () => {
        setSelectedRowData(null);
        setViewModalOpen(false);
    };

    const subsaleFormData = {
        landlordName: "",
        purchaserName: "",
        project: "",
        lotNumber: "",
        bookingAmount: "",
        purchasePrice: "",
        commissionPercentge: "",
        spaPrice: "",
        spaDate: "",
        status: statusData[0],
        bookingFormUrl: "",
        spaCopyUrl: "",
        cancellationFormUrl: "",
        remark: "",
        salesAgent: [currentUser.name],
        commissionShare: ["100"]
    }

    const [formData, setFormData] = useState(subsaleFormData);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "users"));
                const usersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setUsers(usersData);
            } catch (error) {
                console.error("Error fetching users: ", error);
                toaster.push(<Message type='error'>{`Error fetching users: ${error.message}`}</Message>);
            }
        };

        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "subsalesOrders"));
                const fetchedData = [];
                querySnapshot.forEach(doc => {
                    fetchedData.push({ id: doc.id, ...doc.data() });
                });
                const filteredData = fetchedData.filter(data => data.uid === currentUser.uid);
                const numberedData = addNumbersToData(filteredData);

                setData(numberedData);
                setFilteredData(numberedData); // Initialize filteredData with the fetched data

            } catch (error) {
                console.error("Error fetching data: ", error);
                toaster.push(<Message type='error'>{`Error fetching data: ${error.message}`}</Message>);
            }
        };
        fetchData();
        fetchUsers();
    }, [currentUser.uid, toaster]);

    const validateForm = () => {
        const newErrors = {};
        if (!formData.landlordName) newErrors.landlordName = "Landlord name is required";
        if (!formData.purchaserName) newErrors.purchaserName = "Purchaser name is required";
        if (!formData.project) newErrors.project = "Project name is required";
        if (!formData.lotNumber) newErrors.lotNumber = "Lot number is required";
        if (!formData.bookingAmount) newErrors.bookingAmount = "Booking amount is required";
        if (!formData.purchasePrice) newErrors.purchasePrice = "Purchase price is required";
        if (!formData.commissionPercentge) newErrors.commissionPercentge = "Commission is required";
        if (!formData.spaPrice) newErrors.spaPrice = "SPA price is required";

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async () => {
        if (!validateForm()) {
            return;
        }

        console.log("Form data to be submitted:", formData);

        try {
            if (editMode) {
                // Update existing subsales order
                const salesOrderRef = doc(db, SUBSALESDB, editId);
                const { id, ...formDataWithoutId } = formData; // Exclude the id field
                await updateDoc(salesOrderRef, {
                    ...formDataWithoutId,
                    updatedAt: serverTimestamp(),
                    uid: currentUser.uid
                });
                toaster.push(<Message type='success'>Subsales order updated successfully!</Message>);
            } else {
                // Add new sales order
                await addDoc(collection(db, "subsalesOrders"), {
                    ...formData,
                    createdAt: serverTimestamp(),
                    uid: currentUser.uid
                });
                toaster.push(<Message type='success'>New subsales order added successfully!</Message>);
            }
            handleClose();
            reloadPage();
            setFormData(subsaleFormData);
        } catch (error) {
            console.error("Error adding or updating document: ", error);
            toaster.push(<Message type='error'>{`Error adding or updating document: ${error.message}`}</Message>);
        }
    };

    const handleChange = (key, value, index) => {
        if (key === 'salesAgent' || key === 'commissionShare') {
            const updatedArray = [...formData[key]];
            updatedArray[index] = value;
            setFormData(prevFormData => ({
                ...prevFormData,
                [key]: updatedArray
            }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [key]: value
            }));
        }
    };

    const handleNumberChange = (key, value, index) => {
        if (/^\d*\.?\d*$/.test(value)) {
            if ((key === 'loanMargin' || key === 'commissionShare') && Number(value) > 100) {
                toaster.push(<Message type='error'>Value cannot exceed 100</Message>);
            } else {
                handleChange(key, value, index);
            }
        }
    };

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        setOpen(false);
        setEditMode(false);
        setEditId('');
        setFormData(subsaleFormData);
        setErrors({});
    };

    const handleSearchChange = (value) => {
        setSearchQuery(value);
        if (value === "") {
            setFilteredData(data);
        } else {
            const lowercasedFilter = value.toLowerCase();
            const filtered = data.filter(item => {
                return (
                    item.lotNumber.toLowerCase().includes(lowercasedFilter) ||
                    item.purchaserName.toLowerCase().includes(lowercasedFilter) ||
                    item.status.toLowerCase().includes(lowercasedFilter)
                );
            });
            setFilteredData(filtered);
        }
    };

    const addAgent = () => {
        setFormData(prevFormData => ({
            ...prevFormData,
            salesAgent: [...prevFormData.salesAgent, ""],
            commissionShare: [...prevFormData.commissionShare, ""]
        }));
    };

    const removeAgent = (index) => {
        const updatedAgents = formData.salesAgent.filter((_, i) => i !== index);
        const updatedShares = formData.commissionShare.filter((_, i) => i !== index);
        setFormData(prevFormData => ({
            ...prevFormData,
            salesAgent: updatedAgents,
            commissionShare: updatedShares
        }));
    };

    const handleFileChange = (key, file) => {
        const storage = getStorage();
        const storageRef = ref(storage, `documents/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on('state_changed',
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
            },
            (error) => {
                // Handle unsuccessful uploads
                console.error("Error uploading file: ", error);
                toaster.push(<Message type='error'>{`Error uploading file: ${error.message}`}</Message>);
            },
            () => {
                // Handle successful uploads on complete
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        [key]: downloadURL
                    }));

                });
            }
        );
    };

    const removeFile = (key) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [key]: ""
        }));

    };

    const handleEdit = (rowData) => {
        setEditId(rowData.id);
        setEditMode(true);
        const formattedRowData = { ...rowData, spaDate: rowData.spaDate ? new Date(rowData.spaDate.seconds * 1000) : '' };
        setFormData(formattedRowData);
        handleOpen();
    };

    const handleDelete = async () => {
        try {
            await deleteDoc(doc(db, "subsalesOrders", deleteId));
            handleDeleteClose();
            toaster.push(<Message type='success'>Subsales order deleted successfully!</Message>);
            reloadPage();
        } catch (e) {
            console.log(e);
            toaster.push(<Message type='error'>Error deleting document: {e.message}</Message>);
        }
    };

    const handleDeleteOpen = (id) => {
        setDeleteId(id);
        setDeleteOpen(true);
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
        setDeleteId('');
    };

    const handleRowClick = (rowData) => {
        navigate(`/${VIEWSUBSALESDETAILSURL}/${rowData.id}`);
    };

    const handleEditButtonClick = (event, rowData) => {
        event.stopPropagation();
        handleEdit(rowData);
    };

    const handleDeleteButtonClick = (event, id) => {
        event.stopPropagation();
        handleDeleteOpen(id);
    };

    return (
        <div style={{ flex: 1 }}>
            <Container style={{ marginLeft: expand ? '260px' : '56px' }}>
                <Container >
                    <NavBar/>
                    <CustomerHeader title={SUBSALEORDERS}/>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <div style={{ textAlign: "left", margin: "20px" }}>
                            <Button onClick={handleOpen} appearance="primary" >
                                New subsales
                            </Button>
                            <Modal backdrop={backdrop} keyboard={false} open={open} onClose={handleClose}>
                                <Modal.Header>
                                    <Modal.Title>{editMode ? 'Edit Subsales' : 'Add New Subsales'}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form fluid>
                                        <div style={{ marginBottom: "20px" }}>
                                            <h5>Property Details</h5>
                                        </div>
                                        <Form.Group controlId="landlordName">
                                            <Form.ControlLabel>Landlord Name *</Form.ControlLabel>
                                            <Form.Control name="landlordName" value={formData.landlordName} onChange={value => handleChange('landlordName', value)} />
                                            {errors.landlordName && <Form.HelpText style={{ color: 'red' }}>{errors.landlordName}</Form.HelpText>}
                                        </Form.Group>
                                        <Form.Group controlId="purchaserName">
                                            <Form.ControlLabel>Purchaser Name *</Form.ControlLabel>
                                            <Form.Control name="purchaserName" value={formData.purchaserName} onChange={value => handleChange('purchaserName', value)} />
                                            {errors.purchaserName && <Form.HelpText style={{ color: 'red' }}>{errors.purchaserName}</Form.HelpText>}
                                        </Form.Group>
                                        <Form.Group controlId="project">
                                            <Form.ControlLabel>Project Name *</Form.ControlLabel>
                                            <Form.Control name="project" value={formData.project} onChange={value => handleChange('project', value)} />
                                            {errors.project && <Form.HelpText style={{ color: 'red' }}>{errors.project}</Form.HelpText>}
                                        </Form.Group>
                                        <FlexboxGrid style={{ marginBottom: "20px" }}>
                                            <FlexboxGrid.Item colspan={8}>
                                                <Form.Group controlId="lotNumber">
                                                    <Form.ControlLabel>Lot Number *</Form.ControlLabel>
                                                    <Form.Control name="lotNumber" value={formData.lotNumber} onChange={value => handleChange('lotNumber', value)} />
                                                    {errors.lotNumber && <Form.HelpText style={{ color: 'red' }}>{errors.lotNumber}</Form.HelpText>}
                                                </Form.Group>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={6}>
                                                <Form.Group controlId="bookingAmount">
                                                    <Form.ControlLabel>Booking Amount (RM) *</Form.ControlLabel>
                                                    <Form.Control name="bookingAmount" value={formData.bookingAmount} onChange={value => handleNumberChange('bookingAmount', value)} />
                                                    {errors.bookingAmount && <Form.HelpText style={{ color: 'red' }}>{errors.bookingAmount}</Form.HelpText>}
                                                </Form.Group>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>

                                        <FlexboxGrid style={{ marginBottom: "20px" }}>
                                            <FlexboxGrid.Item colspan={8}>
                                                <Form.Group controlId="purchasePrice">
                                                    <Form.ControlLabel>Purchase Price (RM) *</Form.ControlLabel>
                                                    <Form.Control name="purchasePrice" value={formData.purchasePrice} onChange={value => handleNumberChange('purchasePrice', value)} />
                                                    {errors.purchasePrice && <Form.HelpText style={{ color: 'red' }}>{errors.purchasePrice}</Form.HelpText>}
                                                </Form.Group>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={8}>
                                                <Form.Group controlId="commissionPercentge">
                                                    <Form.ControlLabel>Commission (%) *</Form.ControlLabel>
                                                    <Form.Control name="commissionPercentge" value={formData.commissionPercentge} onChange={value => handleNumberChange('commissionPercentge', value)} />
                                                    {errors.commissionPercentge && <Form.HelpText style={{ color: 'red' }}>{errors.commissionPercentge}</Form.HelpText>}
                                                </Form.Group>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>

                                        <FlexboxGrid style={{ marginBottom: "20px" }}>
                                            <FlexboxGrid.Item colspan={8}>
                                                <Form.Group controlId="spaPrice">
                                                    <Form.ControlLabel>SPA Price (RM) *</Form.ControlLabel>
                                                    <Form.Control name="spaPrice" value={formData.spaPrice} onChange={value => handleNumberChange('spaPrice', value)} />
                                                    {errors.spaPrice && <Form.HelpText style={{ color: 'red' }}>{errors.spaPrice}</Form.HelpText>}
                                                </Form.Group>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={8}>
                                                <Form.Group controlId="spaDate">
                                                    <Form.ControlLabel>SPA Date</Form.ControlLabel>
                                                    <DatePicker oneTap value={formData.spaDate} onChange={value => handleChange('spaDate', value)} style={{ width: 224 }} />
                                                    {errors.spaDate && <Form.HelpText style={{ color: 'red' }}>{errors.spaDate}</Form.HelpText>}
                                                </Form.Group>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                        <FlexboxGrid style={{ marginBottom: "40px" }}>
                                            <FlexboxGrid.Item colspan={8}>
                                                <Form.Group controlId="status">
                                                    <Form.ControlLabel>Status</Form.ControlLabel>
                                                    <Dropdown title={formData.status} activeKey={formData.status} onSelect={value => handleChange('status', value)}>
                                                        {statusData.map(status => <Dropdown.Item key={status} eventKey={status}>{status}</Dropdown.Item>)}
                                                    </Dropdown>
                                                    {errors.status && <Form.HelpText style={{ color: 'red' }}>{errors.status}</Form.HelpText>}
                                                </Form.Group>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={8}>

                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                        <div style={{ paddingRight: "40px" }}>
                                            <Form.Group controlId="remark" style={{ marginBottom: "20px" }}>
                                                <Form.ControlLabel>Remark</Form.ControlLabel>
                                                <Input as="textarea" rows={3} placeholder="Remarks..." name="remark" value={formData.remark} onChange={value => handleChange('remark', value)} />
                                            </Form.Group>
                                        </div>
                                        <div style={{ marginBottom: "20px" }}>
                                            <h5>Document Details</h5>
                                        </div>

                                        <Form.Group controlId="bookingFormUrl" style={{ marginBottom: "40px" }}>
                                            <Form.ControlLabel>
                                                Complete Booking Form * <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Booking Form, IC & Transaction Slip </Tooltip>}><InfoRoundIcon /></Whisper>
                                            </Form.ControlLabel>
                                            <input type="file" onChange={(event) => handleFileChange('bookingFormUrl', event.target.files[0])} />
                                            {formData.bookingFormUrl && (
                                                <div>
                                                    <a href={formData.bookingFormUrl} target="_blank" rel="noopener noreferrer">View File</a>
                                                    <Button onClick={() => removeFile('bookingFormUrl')} color="red" appearance="link">Remove</Button>
                                                </div>
                                            )}
                                            {errors.bookingFormUrl && <Form.HelpText style={{ color: 'red' }}>{errors.bookingFormUrl}</Form.HelpText>}
                                        </Form.Group>

                                        <Form.Group controlId="spaCopyUrl" style={{ marginBottom: "40px" }}>
                                            <Form.ControlLabel>
                                                SPA Copy *
                                            </Form.ControlLabel>
                                            <input type="file" onChange={(event) => handleFileChange('spaCopyUrl', event.target.files[0])} />
                                            {formData.spaCopyUrl && (
                                                <div>
                                                    <a href={formData.spaCopyUrl} target="_blank" rel="noopener noreferrer">View File</a>
                                                    <Button onClick={() => removeFile('spaCopyUrl')} color="red" appearance="link">Remove</Button>
                                                </div>
                                            )}
                                            {errors.spaCopyUrl && <Form.HelpText style={{ color: 'red' }}>{errors.spaCopyUrl}</Form.HelpText>}
                                        </Form.Group>

                                        <Form.Group controlId="cancellationFormUrl" style={{ marginBottom: "40px" }}>
                                            <Form.ControlLabel>
                                                Cancellation Form (if any)
                                            </Form.ControlLabel>
                                            <input type="file" onChange={(event) => handleFileChange('cancellationFormUrl', event.target.files[0])} />
                                            {formData.cancellationFormUrl && (
                                                <div>
                                                    <a href={formData.cancellationFormUrl} target="_blank" rel="noopener noreferrer">View File</a>
                                                    <Button onClick={() => removeFile('cancellationFormUrl')} color="red" appearance="link">Remove</Button>
                                                </div>
                                            )}
                                        </Form.Group>

                                        <FlexboxGrid style={{ marginBottom: "20px" }}>
                                            <FlexboxGrid.Item colspan={8}>
                                                <h5>Agent Details</h5>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={8}>
                                                <Button appearance="ghost" onClick={addAgent}>Add Agent</Button>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                        {formData.salesAgent.map((agent, index) => (
                                            <FlexboxGrid key={index} style={{ marginBottom: "20px" }}>
                                                <FlexboxGrid.Item colspan={8}>
                                                    <Form.Group controlId={`salesAgent-${index}`}>
                                                        <Form.ControlLabel>Sales Agent</Form.ControlLabel>
                                                        <Dropdown title={agent || "Select Agent"} activeKey={agent} onSelect={value => handleChange('salesAgent', value, index)}>
                                                            {users.map(user => <Dropdown.Item key={user.id} eventKey={user.name}>{user.name}</Dropdown.Item>)}
                                                        </Dropdown>
                                                    </Form.Group>
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                                                <FlexboxGrid.Item colspan={6}>
                                                    <Form.Group controlId={`commissionShare-${index}`}>
                                                        <Form.ControlLabel>Commission Share</Form.ControlLabel>
                                                        <Form.Control name={`commissionShare-${index}`} value={formData.commissionShare[index]} onChange={value => handleNumberChange('commissionShare', value, index)} />
                                                        {errors.commissionShare && <Form.HelpText style={{ color: 'red' }}>{errors.commissionShare}</Form.HelpText>}
                                                    </Form.Group>
                                                </FlexboxGrid.Item>
                                                <FlexboxGrid.Item colspan={2}>
                                                    <IconButton icon={<TrashIcon />} size="xs" appearance="ghost" color="red" onClick={() => removeAgent(index)} />
                                                </FlexboxGrid.Item>
                                            </FlexboxGrid>
                                        ))}


                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button appearance="primary" onClick={handleSubmit}>
                                        {editMode ? 'Save' : 'Add'}
                                    </Button>
                                    <Button onClick={handleClose} appearance="subtle">
                                        Cancel
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                        <div style={{ height: "80px", justifyContent: "center", marginTop: "20px", marginRight: "20px", marginLeft: "20px" }}>
                            <Input 
                                placeholder="Search..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                                style={{ width: 300 }}
                            />
                        </div>
                    </div>
                    <Content style={{ marginLeft: "20px" }}>
                        <Table
                            height={500}
                            data={filteredData.slice((activePage - 1) * 10, activePage * 10)}
                            onRowClick={handleRowClick}
                            style={{ marginRight: "40px", textAlign: "left" }}
                        >
                            <Column width={60} align="center">
                                <HeaderCell>No</HeaderCell>
                                <Cell dataKey="number" />
                            </Column>
                            <Column width={200}>
                                <HeaderCell>Purchaser Name</HeaderCell>
                                <Cell dataKey="purchaserName" />
                            </Column>
                            <Column width={200}>
                                <HeaderCell>Project Name</HeaderCell>
                                <Cell dataKey="project" />
                            </Column>
                            <Column width={150}>
                                <HeaderCell>Lot No.</HeaderCell>
                                <Cell dataKey="lotNumber" />
                            </Column>
                            <Column width={200}>
                                <HeaderCell>Purchase Price (RM)</HeaderCell>
                                <Cell>
                                    {rowData => {
                                        const purchasePrice = parseFloat(rowData.purchasePrice) || 0;
                                        return purchasePrice.toFixed(2);
                                    }}
                                </Cell>
                            </Column>
                            <Column width={200}>
                                <HeaderCell>Status</HeaderCell>
                                <Cell dataKey="status" />
                            </Column>
                            <Column width={150} fixed="right">
                                <HeaderCell align='center'>Action</HeaderCell>
                                <Cell style={{ padding: '6px' }}>
                                    {rowData => (
                                        <div>
                                            {(rowData.status !== 'Ready To Convert' && rowData.status !== 'Converted') && (
                                                <>
                                                    <Button appearance="link" style={{ marginLeft: '10px' }} onClick={(event) => handleEditButtonClick(event, rowData)}>
                                                        Edit
                                                    </Button>
                                                    <Button appearance="link" style={{ marginLeft: '10px' }} onClick={(event) => handleDeleteButtonClick(event, rowData.id)}>
                                                        Delete
                                                    </Button>
                                                </>
                                            )}
                                        </div>
                                    )}
                                </Cell>
                            </Column>
                        </Table>
                        <SubsalesViewModal viewModalOpen={viewModalOpen} handleCloseViewModal={handleCloseViewModal} selectedRowData={selectedRowData} />
                        <DeleteModal message="Are you sure you want to delete this subsales order?" handleDeleteClose={handleDeleteClose} deleteOpen={deleteOpen} handleDelete={handleDelete} />
                        <Pagination total={filteredData.length} limit={10} activePage={activePage} onChangePage={setActivePage} style={{ marginLeft: "20px", marginTop: "20px", marginBottom: "40px"}}/>
                    </Content>
                </Container>
            </Container>
        </div>
    )
}

export default SubsalesOrderComponents;

