import React, { useState, useContext, useEffect } from 'react';
import { FlexboxGrid, Container, Content, Form, Button, useToaster, Message, Table, Modal, Pagination, Input, Dropdown } from 'rsuite';
import { AuthContext } from '../../context/authContext';
import NavBar from '../../components/NavBar/NavBar';
import { doc, serverTimestamp, setDoc, collection, getDocs, updateDoc} from "firebase/firestore"; 
import { db } from "../../firebase";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { reloadPage } from '../../Helpers/ReloadPage';
import { addNumbersToData } from '../../Helpers/AddNumber';
import { useNavigate } from 'react-router-dom';
import CustomerHeader from '../../components/Header/Header';
import { LEAVES } from '../../constants/constants';

const { Column, HeaderCell, Cell } = Table;

const typeOfLeavesData = [
    "Annual Leave",
    "Sick Leave",
    "Maternity Leave",
    "Paternity Leave",
    "Public Holidays",
    "Marriage Leave",
    "Bereavement Leave",
    "Emergency Leave", // Compassionate Leave
    "Study Leave",
    "Unpaid Leave",
];

const LeavesComponent = ({ expand }) => {
  const [name, setName] = useState("");
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState('');
  const [searchQuery, setSearchQuery] = useState("");
  const { currentUser } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const toaster = useToaster();
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditMode(false);
    setEditId('');
    setName('');
    setFile(null);
    setErrors({});
    setFormData(initialFormData); // Reset form data
  };

  const initialFormData = {
    leaveType: typeOfLeavesData[0],
    documentUrl: "",
    remark: "",
    startDate: "",
    endDate: "",
    status: "Pending",
    applicant: currentUser.name,
    comments: ""
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (name, value) => {
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    const fetchDocuments = async () => {
      const querySnapshot = await getDocs(collection(db, "leaves"));
      const documentList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      documentList.sort((a, b) => a.applicant.localeCompare(b.applicant));
      setDocuments(documentList);
    };

    fetchDocuments();
  }, []);

  const handleAddOrEditLeave = async () => {
    const newErrors = {};
    if (!formData.leaveType) newErrors.leaveType = "Leave type is required";
    if (!formData.startDate) newErrors.startDate = "Start date is required";
    if (!formData.endDate) newErrors.endDate = "End date is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      let downloadURL = "";
      if (file) {
        const storage = getStorage();
        const storageRef = ref(storage, `leaves/${name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        await new Promise((resolve, reject) => {
          uploadTask.on('state_changed', 
            null, 
            (error) => reject(error), 
            async () => {
              downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              resolve();
            }
          );
        });
      }

      if (editMode) {
        await updateDoc(doc(db, "leaves", editId), {
          ...formData,
          documentUrl: downloadURL || formData.documentUrl,
          updatedBy: currentUser.name,
        });
        toaster.push(<Message type='success'>Leave application updated successfully!</Message>);
      } else {
        await setDoc(doc(collection(db, "leaves")), {
          ...formData,
          documentUrl: downloadURL,
          timeStamp: serverTimestamp(),
          updatedBy: currentUser.name,
        });
        toaster.push(<Message type='success'>New leave application has been added successfully!</Message>);
      }

      handleClose();
      reloadPage();
      setErrors({});
    } catch (e) {
      console.log(e);
    }
  };

  const handleSearchChange = (value) => {
    setSearchQuery(value);
  };

  const filteredLeaves = documents.filter(leave => 
    leave.applicant.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const numberedData = addNumbersToData(filteredLeaves);

  const handleRowClick = (rowData) => {
    navigate(`/view-leaves/${rowData.id}`);
};

  return (
    <div className="registration" style={{ flex: 1 }}>
      <Container style={{ marginLeft: expand ? '260px' : '56px' }}>
        <NavBar/>
        <CustomerHeader title={LEAVES}/>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "20px" }}>
        <div style={{ textAlign: "left", margin: "20px" }}>
              <Button appearance="primary" onClick={handleOpen}>
                Apply Leave
              </Button>
              <Modal open={open} onClose={handleClose}>
                <Modal.Header>
                  <Modal.Title>{editMode ? 'Edit Leave Application' : 'Apply Leave'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form style={{ textAlign: 'left', marginLeft: "40px", marginTop: "40px"}} >
                    <Form.Group controlId="leaveType">
                      <Form.ControlLabel>Leave Type</Form.ControlLabel>
                      <Dropdown title={formData.leaveType} activeKey={formData.leaveType} onSelect={value => handleChange('leaveType', value)}>
                        {typeOfLeavesData.map(type => <Dropdown.Item key={type} eventKey={type}>{type}</Dropdown.Item>)}
                      </Dropdown>
                      {errors.leaveType && <Form.HelpText style={{ color: 'red' }}>{errors.leaveType}</Form.HelpText>}
                    </Form.Group>
                    <Form.Group controlId="remark" style={{marginRight: "40px"}}>
                      <Form.ControlLabel>Remark</Form.ControlLabel>
                      <Input as="textarea" rows={3} value={formData.remark} onChange={value => handleChange('remark', value)} />
                    </Form.Group>
                    <FlexboxGrid style={{ marginBottom: "20px" }}>
                        <FlexboxGrid.Item colspan={8}>
                            <Form.Group controlId="startDate">
                                <Form.ControlLabel>Start Date</Form.ControlLabel>
                                <Input type="date" value={formData.startDate} onChange={value => handleChange('startDate', value)} />
                                {errors.startDate && <Form.HelpText style={{ color: 'red' }}>{errors.startDate}</Form.HelpText>}
                            </Form.Group>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={8}>
                            <Form.Group controlId="endDate">
                                <Form.ControlLabel>End Date</Form.ControlLabel>
                                <Input type="date" value={formData.endDate} onChange={value => handleChange('endDate', value)} />
                                {errors.endDate && <Form.HelpText style={{ color: 'red' }}>{errors.endDate}</Form.HelpText>}
                            </Form.Group>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>  
                    <Form.Group controlId="file">
                      <Form.ControlLabel>Upload Document</Form.ControlLabel>
                      <input type="file" onChange={(e) => setFile(e.target.files[0])} />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={handleAddOrEditLeave} appearance="primary">
                    {editMode ? 'Edit' : 'Apply'}
                  </Button>
                  <Button onClick={handleClose} appearance="subtle">
                    Cancel
                  </Button>
                </Modal.Footer>
              </Modal>
            </div> 
          <div style={{ height: "80px", justifyContent: "center", marginTop: "20px", marginRight: "20px", marginLeft: "20px" }}>
              <Input 
                placeholder="Search by leave type"
                value={searchQuery}
                onChange={handleSearchChange}
                style={{ width: 300 }}
              />
          </div>
        </div>
        <Content style={{marginLeft: "20px"}}>
          <Table
            height={500}
            data={numberedData.slice((activePage - 1) * 10, activePage * 10)}
            onRowClick={handleRowClick}
            style={{ marginRight: "40px"}}
          >
            <Column width={60} align="center">
              <HeaderCell>No</HeaderCell>
              <Cell dataKey="number" />
            </Column>
            <Column width={400}>
              <HeaderCell align="left">Name</HeaderCell>
              <Cell align="left" dataKey="applicant" />
            </Column>
            <Column width={600}>
              <HeaderCell align="left">Remark</HeaderCell>
              <Cell align="left" dataKey="remark" />
            </Column>
            <Column width={100}>
              <HeaderCell align="left">Status</HeaderCell>
              <Cell align="left" dataKey="status" />
            </Column>
          </Table>
          <Pagination total={documents.length} limit={10} activePage={activePage} onChangePage={setActivePage} style={{ marginLeft: "20px", marginTop: "20px", marginBottom: "40px"}}/>
        </Content>
      </Container>
    </div>
  )
}

export default LeavesComponent;
