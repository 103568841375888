import './App.css';
import 'rsuite/dist/rsuite.min.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "./context/authContext";
import routes from './routes';

function App() {
  const { currentUser } = useContext(AuthContext);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {routes(currentUser).map((route, index) => (
            <Route key={index} path={route.path} element={route.element} index={route.index || false} />
          ))}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
