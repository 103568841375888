import React, { useState } from 'react';
import SideBar1 from '../../components/SideBar1/SideBar1';
import LeavesComponent from './LeavesComponent';

const Leaves = () => {
    const [expand, setExpand] = useState(true);

    const handleToggleExpand = () => {
        setExpand(!expand);
    };

    return (
        <div style={{ display: 'flex' }}>
            <SideBar1 expand={expand} onToggleExpand={handleToggleExpand} />
            <LeavesComponent expand={expand}/>
        </div>
    );
}

export default Leaves;
