import { Sidenav, Nav, Sidebar, Navbar } from 'rsuite';
import DashboardIcon from '@rsuite/icons/legacy/Dashboard';
import EventDetailIcon from '@rsuite/icons/EventDetail';
import OffRoundIcon from '@rsuite/icons/OffRound';
import NoticeIcon from '@rsuite/icons/Notice';
import DetailIcon from '@rsuite/icons/Detail';
import TrendIcon from '@rsuite/icons/Trend';
import TagIcon from '@rsuite/icons/Tag';
import AdvancedAnalyticsIcon from '@rsuite/icons/AdvancedAnalytics';
import PhoneIcon from '@rsuite/icons/Phone';
import CalendarIcon from '@rsuite/icons/Calendar';
import AngleLeftIcon from '@rsuite/icons/legacy/AngleLeft';
import AngleRightIcon from '@rsuite/icons/legacy/AngleRight';
import PageIcon from '@rsuite/icons/Page';
import { useContext, forwardRef, useState } from 'react';
import { auth } from '../../firebase';
import { signOut } from 'firebase/auth';
import { AuthContext } from '../../context/authContext';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const headerStyles = {
  padding: 18,
  fontSize: 16,
  height: 56,
  background: '#34c3ff',
  color: ' #fff',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
};

const NavToggle = ({ expand, onChange }) => {
  return (
    <Navbar appearance="subtle" className="nav-toggle">
      <Nav pullRight>
        <Nav.Item onClick={onChange} style={{ width: 56, textAlign: 'center' }}>
          {expand ? <AngleLeftIcon /> : <AngleRightIcon />}
        </Nav.Item>
      </Nav>
    </Navbar>
  );
};

const NavLink = forwardRef(({ href, children, ...rest }, ref) => (
    <Link ref={ref} to={href} {...rest}>
      {children}
    </Link>
));

const SideBar1 = ({ expand, onToggleExpand }) => {
    const { currentUser } = useContext(AuthContext);
    const { dispatch } = useContext(AuthContext);
    const navigate = useNavigate();
    const [activeKey, setActiveKey] = useState('1');

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            dispatch({ type: "LOGOUT" });
            localStorage.clear();
            navigate("/login");
        } catch (error) {
            console.error("Error signing out: ", error);
        }
    };

    return (
        <div className='sidebar' style={{textAlign: 'left', position: 'fixed', top: 0, left: 0, bottom: 0, height: '100vh', zIndex: 1000, overflowY: 'auto', backgroundColor: '#fff' }}>
            <Sidebar
                style={{ display: 'flex', flexDirection: 'column' }}
                width={expand ? 260 : 56}
                collapsible
            >
                <Sidenav expanded={expand}>
                    <Sidenav.Body>
                        <Nav activeKey={activeKey} onSelect={setActiveKey}>
                            {currentUser.position === "Admin" && (
                                <Nav.Menu eventKey="1" title="Dashboard" icon={<DashboardIcon />}>
                                    <Nav.Item eventKey="1-1">Overview</Nav.Item>
                                    <Nav.Item eventKey="1-2" as={NavLink} href="/order-request">Order Requests</Nav.Item>
                                    <Nav.Item eventKey="1-3" as={NavLink} href="/claim-request">Claim Requests</Nav.Item>
                                    <Nav.Item eventKey="1-4" as={NavLink} href="/users-registration">User List</Nav.Item>
                                </Nav.Menu>
                            )}
                            <Nav.Menu eventKey="2" title="Property Lists" icon={<PageIcon />}>
                                <Nav.Item eventKey="2-1" as={NavLink} href="/project-list">Project List</Nav.Item>
                                <Nav.Item eventKey="2-2" as={NavLink} href="/subsales-list">Subsales List</Nav.Item>
                            </Nav.Menu>
                            <Nav.Menu eventKey="4" title="Orders" icon={<TagIcon />}>
                                <Nav.Item eventKey="4-1" as={NavLink} href="/sales-order">Sales Order</Nav.Item>
                                <Nav.Item eventKey="4-2" as={NavLink} href="/subsales-order">Subsales Order</Nav.Item>
                                <Nav.Item eventKey="4-3" as={NavLink} href="/rentals-order">Rentals Order</Nav.Item>
                            </Nav.Menu>
                            <Nav.Menu eventKey="5" title="Commissions" icon={<TrendIcon />}>
                                <Nav.Item eventKey="5-1">My Commissions</Nav.Item>
                                <Nav.Item eventKey="5-2">DL's Subsales Commissions</Nav.Item>
                                <Nav.Item eventKey="5-3">DL's Rentals Commissions</Nav.Item>
                            </Nav.Menu>
                            <Nav.Item eventKey="9" icon={<AdvancedAnalyticsIcon />} as={NavLink} href="/claims">Claims</Nav.Item>
                            <Nav.Item eventKey="7" icon={<PhoneIcon />} as={NavLink} href="/contact">
                                Contacts
                            </Nav.Item>
                            <Nav.Menu eventKey="8" title="Office Management" icon={<CalendarIcon />}>
                                <Nav.Item eventKey="8-1" as={NavLink} href="/booking-office-room">Office Slots</Nav.Item>
                            </Nav.Menu>
                            <Nav.Item eventKey="9" icon={<DetailIcon />} as={NavLink} href="/documents">
                                Documents
                            </Nav.Item>
                            <Nav.Item eventKey="10" icon={<NoticeIcon />}>
                                Announcement
                            </Nav.Item>
                            <Nav.Item eventKey="3" icon={<EventDetailIcon />} as={NavLink} href="/leaves">
                                Leaves
                            </Nav.Item>
                            <Nav.Item eventKey="5" icon={<OffRoundIcon />} onClick={handleSignOut}>
                                Logout
                            </Nav.Item>
                        </Nav>
                    </Sidenav.Body>
                </Sidenav>
                <NavToggle expand={expand} onChange={onToggleExpand} />
            </Sidebar>
        </div>
    );
};

export default SideBar1;
