import React, { useState } from 'react';
import SideBar1 from '../../components/SideBar1/SideBar1';
import DocumentComponent from './DocumentComponent'; // Ensure this import is correct

const Projects = () => {
  const [expand, setExpand] = useState(true);

  const handleToggleExpand = () => {
    setExpand(!expand);
  };

  return (
    <div style={{ display: 'flex' }}>
      <SideBar1 expand={expand} onToggleExpand={handleToggleExpand} />
      <DocumentComponent expand={expand} />
    </div>
  );
}

export default Projects;
