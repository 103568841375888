import React, { useState, useEffect } from 'react';
import { Container,  Content, Table, Button, useToaster, Message, Pagination} from 'rsuite';
import { db } from '../../firebase';
import { getDocs, updateDoc, doc, collection, query, where } from "firebase/firestore";
import SideBar1 from '../../components/SideBar1/SideBar1';
import NavBar from '../../components/NavBar/NavBar';
import ViewModalSales from './ViewModalSales';
import ViewModalRental from './ViewModalRental';
import { addNumbersToData } from '../../Helpers/AddNumber';
import ViewModalSubsales from './ViewModalSubsales';

const { Column, HeaderCell, Cell } = Table;

const statusData = ["Pending", "Cancel", "Ready To Convert", "Not Ready To Convert"];

const OrderRequestComponent = ({expand}) => {
    const [data, setData] = useState([]);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [selectedOrderType, setSelectedOrderType] = useState(null);
    const toaster = useToaster();
    const [activePage, setActivePage] = useState(1);

    useEffect(() => {
        const fetchData = async () => {
            const rentalOrdersQuery = query(collection(db, "rentalOrders"), where("status", "==", "Ready To Convert"));
            const salesOrdersQuery = query(collection(db, "salesOrders"), where("status", "==", "Ready To Convert"));
            const subsalesOrdersQuery = query(collection(db, "subsalesOrders"), where("status", "==", "Ready To Convert"));

            const rentalOrdersSnapshot = await getDocs(rentalOrdersQuery);
            const salesOrdersSnapshot = await getDocs(salesOrdersQuery);
            const subsalesOrdersSnapshot = await getDocs(subsalesOrdersQuery);

            const rentalOrders = rentalOrdersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), type: "Rental" }));
            const salesOrders = salesOrdersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), type: "Sales" }));
            const subsalesOrders = subsalesOrdersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), type: "Subsales" }));

            const allOrders = [...rentalOrders, ...salesOrders, ...subsalesOrders].map(order => ({
                ...order,
                agents: order.salesAgent.join(' & ')
            }));

            const numberedData = addNumbersToData(allOrders);
            setData(numberedData);
        };
        
        fetchData();
    }, []);

    const handleApprove = async (id, type) => {
        try {
            const collectionName = type === "Rental" ? "rentalOrders" : type === "Sales" ? "salesOrders" : "subsalesOrders";
            const orderRef = doc(db, collectionName, id);
            await updateDoc(orderRef, { status: "Converted" });
            toaster.push(<Message type='success'>Order approved successfully!</Message>);
            setData(data.filter(order => order.id !== id));
        } catch (error) {
            console.error("Error approving order: ", error);
            toaster.push(<Message type='error'>Failed to approve order!</Message>);
        }
    };

    const handleReturn = async (id, type) => {
        try {
            const collectionName = type === "Rental" ? "rentalOrders" : type === "Sales" ? "salesOrders" : "subsalesOrders";
            const orderRef = doc(db, collectionName, id);
            await updateDoc(orderRef, { status: "Returned" });
            toaster.push(<Message type='success'>Order returned successfully!</Message>);
            setData(data.filter(order => order.id !== id));
        } catch (error) {
            console.error("Error returning order: ", error);
            toaster.push(<Message type='error'>Failed to return order!</Message>);
        }
    };

    const handleOpenViewModal = (rowData) => {
        setSelectedRowData(rowData);
        setSelectedOrderType(rowData.type);
        setViewModalOpen(true);
    };

    const handleCloseViewModal = () => {
        setSelectedRowData(null);
        setSelectedOrderType(null);
        setViewModalOpen(false);
    };

    return (
        <div className='order-request' style={{flex: 1}}>
            <Container  style={{ marginLeft: expand ? '260px' : '56px' }}>
                <Container>
                    <NavBar title={"Order Requests"}/>
                    <Content>
                        <Table height={500} data={data} style={{ margin: "20px" }}>
                            <Column width={50} align="center">
                                <HeaderCell>No</HeaderCell>
                                <Cell dataKey="number" />
                            </Column>
                            <Column width={200} align="center">
                                <HeaderCell>Order Type</HeaderCell>
                                <Cell dataKey="type" />
                            </Column>
                            <Column width={200} align="center">
                                <HeaderCell>Project</HeaderCell>
                                <Cell dataKey="project" />
                            </Column>
                            <Column width={200} align="center">
                                <HeaderCell>Status</HeaderCell>
                                <Cell dataKey="status" />
                            </Column>
                            <Column width={200} align="center">
                                <HeaderCell>Agents</HeaderCell>
                                <Cell dataKey="agents" />
                            </Column>
                            <Column width={200} align="center">
                                <HeaderCell align='center'>Action</HeaderCell>
                                <Cell style={{ padding: '6px' }}>
                                    {rowData => (
                                        <span>
                                            <Button appearance="link" onClick={() => handleOpenViewModal(rowData)} style={{ marginLeft: '10px' }}>View</Button>
                                            <Button appearance="link" onClick={() => handleApprove(rowData.id, rowData.type)}>Approve</Button>
                                            <Button appearance="link" onClick={() => handleReturn(rowData.id, rowData.type)} style={{ marginLeft: '10px' }}>Return</Button>
                                            
                                        </span>
                                    )}
                                </Cell>
                            </Column>
                        </Table>
                        <Pagination total={data.length} limit={10} activePage={activePage} onChangePage={setActivePage} style={{ marginLeft: "20px", marginTop: "20px", marginBottom: "40px"}}/>
                    </Content>
                </Container>
            </Container>
            {selectedOrderType === "Sales" && 
                <ViewModalSales viewModalOpen={viewModalOpen} handleCloseViewModal={handleCloseViewModal} selectedRowData={selectedRowData} />
            }
            {selectedOrderType === "Rental" && 
                <ViewModalRental viewModalOpen={viewModalOpen} handleCloseViewModal={handleCloseViewModal} selectedRowData={selectedRowData} />
            }
            {selectedOrderType === "Subsales" && 
                <ViewModalSubsales viewModalOpen={viewModalOpen} handleCloseViewModal={handleCloseViewModal} selectedRowData={selectedRowData} />
            }
        </div>
    );
};

export default OrderRequestComponent;
