import React, { useState, useContext, useEffect } from 'react';
import { 
  Container,  
  Content, 
  Form, 
  Button, 
  useToaster, 
  Message,
  Table, 
  Modal,
  Pagination,
  Input,
} from 'rsuite';
import { AuthContext } from '../../context/authContext';
import NavBar from '../../components/NavBar/NavBar';
import { doc, serverTimestamp, setDoc, collection, getDocs, updateDoc, deleteDoc } from "firebase/firestore"; 
import { db } from "../../firebase";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { reloadPage } from '../../Helpers/ReloadPage';
import { addNumbersToData } from '../../Helpers/AddNumber';
import DeleteModal from '../salesOrder/DeleteModal';
import CustomerHeader from '../../components/Header/Header';
import { DOCUMENTS } from '../../constants/constants';

const { Column, HeaderCell, Cell } = Table;

const DocumentComponent = ({ expand }) => {
  const [name, setName] = useState("");
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState('');
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [searchQuery, setSearchQuery] = useState("");
  const { currentUser } = useContext(AuthContext);
  const [open, setOpen] = useState(false);

  const toaster = useToaster();
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditMode(false);
    setEditId('');
    setName('');
    setFile(null);
    setErrors({});
  };

  const handleNameChange = (value) => {
    setName(value);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  useEffect(() => {
    const fetchDocuments = async () => {
      const querySnapshot = await getDocs(collection(db, "documents"));
      const documentList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      documentList.sort((a, b) => a.name.localeCompare(b.name));
      setDocuments(documentList);
    };

    fetchDocuments();
  }, []);

  const handleAddOrEditDocument = async () => {
    const newErrors = {};
    if (!name) newErrors.name = "Document Name is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      // Edit document
      if (editMode) {
        // Update existing document
        let downloadURL;
        if (file) {
          const storage = getStorage();
          const storageRef = ref(storage, `documents/${name}`);
          const uploadTask = uploadBytesResumable(storageRef, file);

          await new Promise((resolve, reject) => {
            uploadTask.on('state_changed', 
              null, 
              (error) => reject(error), 
              async () => {
                downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                resolve();
              }
            );
          });
        }
        await updateDoc(doc(db, "documents", editId), {
          name: name,
          updatedBy: currentUser.name,
          ...(file && { fileURL: downloadURL }),
        });
        handleClose();
        reloadPage();
        toaster.push(<Message type='success'>Document updated successfully!</Message>);
      } else {
        // Adding a new document
        const storage = getStorage();
        const storageRef = ref(storage, `documents/${name}}`);
        
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on('state_changed', 
          (snapshot) => {
            // Handle progress, pause, and resume events
          }, 
          (error) => {
            toaster.push(<Message type="error">Upload failed: {error.message}</Message>);
          }, 
          async () => {
            // Upload completed successfully, get the download URL
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            await setDoc(doc(db, "documents", `${name}`), {
              name: name,
              timeStamp: serverTimestamp(),
              updatedBy: currentUser.name,
              fileURL: downloadURL // Save the download URL
            });
            toaster.push(<Message type='success'>New document has been added successfully!</Message>);
          }
        );
        
        handleClose();
        reloadPage();
        setErrors({});
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleEdit = (rowData) => {
    setEditMode(true);
    setEditId(rowData.id);
    setName(rowData.name);
    setOpen(true);
  };

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(db, "documents", deleteId));
      handleClose();
      toaster.push(<Message type='success'>Document deleted successfully!</Message>);
      reloadPage();
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteOpen = (id) => {
    setDeleteId(id);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setDeleteId('');
  };

  const handleSearchChange = (value) => {
    setSearchQuery(value);
  };

  const filteredProjects = documents.filter(project => 
    project.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const numberedData = addNumbersToData(filteredProjects);

  return (
    <div className="registration" style={{ flex: 1 }}>
      <Container style={{ marginLeft: expand ? '260px' : '56px' }}>
        <NavBar/>
        <CustomerHeader title={DOCUMENTS} />
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "20px" }}>
          {currentUser.position === "Admin" && 
            <div style={{ textAlign: "left", margin: "20px" }}>
              <Button appearance="primary" onClick={handleOpen}>
                Add New Document
              </Button>
              <Modal open={open} onClose={handleClose}>
                <Modal.Header>
                  <Modal.Title>{editMode ? 'Edit Project' : 'Add New Project'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form style={{ textAlign: 'left', marginLeft: "40px", marginTop: "40px"}} >
                    <Form.Group controlId="name">
                      <Form.ControlLabel>Document Name</Form.ControlLabel>
                      <Form.Control name="name" value={name} onChange={handleNameChange} />
                      {errors.name && <Form.HelpText style={{ color: 'red' }}>{errors.name}</Form.HelpText>}
                    </Form.Group>
                    <Form.Group controlId="file">
                      <Form.ControlLabel>Upload Document</Form.ControlLabel>
                      <input type="file" onChange={handleFileChange} />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={handleAddOrEditDocument} appearance="primary">
                    {editMode ? 'Edit' : 'Add'}
                  </Button>
                  <Button onClick={handleClose} appearance="subtle">
                    Cancel
                  </Button>
                </Modal.Footer>
              </Modal>
              <DeleteModal message="Are you sure you want to delete this sales order?" handleDeleteClose={handleDeleteClose} deleteOpen={deleteOpen} handleDelete={handleDelete} />
            </div> 
          }
          <div style={{ height: "80px", justifyContent: "center", marginTop: "20px", marginRight: "20px", marginLeft: "20px" }}> 
            <Input 
              placeholder="Search by document name"
              value={searchQuery}
              onChange={handleSearchChange}
              style={{ width: 300 }}
            />
          </div>
        </div>
        <Content style={{marginLeft: "20px"}}>
          <Table
            height={500}
            data={numberedData.slice((activePage - 1) * 10, activePage * 10)}
            onRowClick={rowData => {
              console.log(rowData);
            }}
            style={{ marginRight: "40px"}}
          >
            <Column width={60} align="center">
              <HeaderCell>No</HeaderCell>
              <Cell dataKey="number" />
            </Column>
            <Column width={700}>
              <HeaderCell align="left">Name</HeaderCell>
              <Cell align="left" dataKey="name" />
            </Column>
            <Column width={250} fixed="right">
              <HeaderCell>Action</HeaderCell>
              <Cell style={{ padding: '6px' }}>
                {rowData => (
                  <div>
                    {currentUser.position === "Admin" ? (
                      <div>
                        <Button appearance="link" onClick={() => handleEdit(rowData)}>
                          Edit
                        </Button>
                        <Button appearance="link" onClick={() => handleDeleteOpen(rowData.id)} style={{ marginLeft: '10px' }}>
                          Delete
                        </Button>
                        <Button appearance="link" onClick={() => window.open(rowData.fileURL, '_blank')} style={{ marginLeft: '10px' }}>
                          Download
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <Button appearance="link" onClick={() => window.open(rowData.fileURL, '_blank')} style={{ marginLeft: '10px' }}>
                          Download
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </Cell>
            </Column>
          </Table>
          <Pagination total={documents.length} limit={10} activePage={activePage} onChangePage={setActivePage} style={{ marginLeft: "20px", marginTop: "20px", marginBottom: "40px"}} />
        </Content>
      </Container>
    </div>
  )
}

export default DocumentComponent;
