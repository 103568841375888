import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ParentComponent from '../../components/ParentComponent/ParentComponent';
import NavBar from '../../components/NavBar/NavBar';
import CustomerHeader from '../../components/Header/Header';
import { CLAIMS, PROJECTSDB, RENTALORDERSDB, SALESORDESDB, SUBSALESORDERSDB, USERSDB, VIEWPROJECTSDETAILSURL, VIEWRENTALSDETAILSURL, VIEWSUBSALESDETAILSURL } from '../../constants/constants';
import { Container, Content, FlexboxGrid, Table, Loader, Pagination } from 'rsuite';
import CustomCard from '../../components/Card/CustomCard';
import { AuthContext } from '../../context/authContext';
import { db } from '../../firebase';
import { collection, getDocs, query, where } from "firebase/firestore";
import { addNumbersToData } from "../../Helpers/AddNumber";

const { Column, HeaderCell, Cell } = Table;

const ClaimsComponent = ({ expand }) => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [claims, setClaims] = useState([]);
  const [filteredClaims, setFilteredClaims] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [pendingCommission, setPendingCommission] = useState(0);
  const [claimedCommission, setClaimedCommission] = useState(0);
  const [unclaimedCommission, setUnclaimedCommission] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const usersSnapshot = await getDocs(collection(db, USERSDB));
        const usersData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        const projectsSnapshot = await getDocs(collection(db, PROJECTSDB));
        const projectsData = projectsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        const rentalSnapshot = await getDocs(collection(db, RENTALORDERSDB));
        const subsalesSnapshot = await getDocs(collection(db, SUBSALESORDERSDB));
        const salesSnapshot = await getDocs(collection(db, SALESORDESDB));

        const rentalData = rentalSnapshot.docs
          .filter(doc => doc.data().status === 'Converted' && doc.data().salesAgent.includes(currentUser.name))
          .map(doc => ({
            docId: doc.id,
            salesAgent: doc.data().salesAgent,
            commissionShare: doc.data().commissionShare,
            category: "Rental",
            status: doc.data().status,
            calculatedCommission: calculateRentalCommission(doc.data(), usersData, currentUser.name)
          }));

        const subsalesData = subsalesSnapshot.docs
          .filter(doc => doc.data().status === 'Converted' && doc.data().salesAgent.includes(currentUser.name))
          .map(doc => ({
            docId: doc.id,
            salesAgent: doc.data().salesAgent,
            commissionShare: doc.data().commissionShare,
            category: "Subsale",
            status: doc.data().status,
            calculatedCommission: calculateSubsaleCommission(doc.data(), usersData, currentUser.name)
          }));

        const salesData = salesSnapshot.docs
          .filter(doc => doc.data().status === 'Converted' && doc.data().salesAgent.includes(currentUser.name))
          .map(doc => ({
            docId: doc.id,
            salesAgent: doc.data().salesAgent,
            commissionShare: doc.data().commissionShare,
            category: "Project",
            status: doc.data().status,
            calculatedCommission: calculateProjectCommission(doc.data(), projectsData, usersData, currentUser.name)
          }));

        const allClaims = addNumbersToData([...rentalData, ...subsalesData, ...salesData]);
        setClaims(allClaims);
        setFilteredClaims(allClaims.slice(0, itemsPerPage));
        calculateUnclaimedCommission([...rentalData, ...subsalesData, ...salesData]);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
      setLoading(false);
    };

    fetchData();
  }, [currentUser.name]);

  useEffect(() => {
    const startIndex = (activePage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setFilteredClaims(claims.slice(startIndex, endIndex));
  }, [activePage, claims]);

  useEffect(() => {
    const fetchPendingCommissions = async () => {
      try {
        const q = query(collection(db, "claims"), where("status", "==", 1), where("uid", "==", currentUser.uid));
        const pendingSnapshot = await getDocs(q);
        let totalPending = 0;
        pendingSnapshot.forEach(doc => {
          totalPending += parseFloat(doc.data().calculatedCommission) || 0;
        });
        setPendingCommission(totalPending.toFixed(2));
      } catch (error) {
        console.error("Error fetching pending commissions: ", error);
      }
    };

    const fetchClaimedCommissions = async () => {
      try {
        const q = query(collection(db, "claims"), where("status", "==", 2), where("uid", "==", currentUser.uid));
        const claimedSnapshot = await getDocs(q);
        let totalClaimed = 0;
        claimedSnapshot.forEach(doc => {
          totalClaimed += parseFloat(doc.data().calculatedCommission) || 0;
        });
        setClaimedCommission(totalClaimed.toFixed(2));
      } catch (error) {
        console.error("Error fetching claimed commissions: ", error);
      }
    };

    fetchPendingCommissions();
    fetchClaimedCommissions();
  }, [currentUser.uid]);

  const calculateUnclaimedCommission = async (ordersData) => {
    try {
      const q = query(collection(db, "claims"), where("uid", "==", currentUser.uid));
      const claimsSnapshot = await getDocs(q);
      const claimedDocIds = claimsSnapshot.docs.map(doc => doc.data().docId);

      let totalUnclaimed = 0;
      ordersData.forEach(order => {
        if (!claimedDocIds.includes(order.docId)) {
          totalUnclaimed += parseFloat(order.calculatedCommission) || 0;
        }
      });
      setUnclaimedCommission(totalUnclaimed.toFixed(2));
    } catch (error) {
      console.error("Error calculating unclaimed commissions: ", error);
    }
  };

  const calculateRentalCommission = (rentalData, usersData, currentUserName) => {
    const monthlyRent = rentalData.rentalMonthly;
    const ownerCommissionMonths = rentalData.serviceFeeMonth;
    const totalCommission = monthlyRent * ownerCommissionMonths;

    const commissions = rentalData.salesAgent.map((agent, index) => {
      const user = usersData.find(u => u.name === agent);
      const agentTier = user ? user.subsalesTier : 0;
      const agentShare = rentalData.commissionShare[index] / 100;
      return {
        agent: agent,
        commission: totalCommission * (agentTier / 100) * agentShare
      };
    });

    const currentUserCommission = commissions.find(commission => commission.agent === currentUserName);
    return currentUserCommission ? currentUserCommission.commission.toFixed(2) : '0.00';
  };

  const calculateSubsaleCommission = (subsaleData, usersData, currentUserName) => {
    const { purchasePrice, commissionPercentge, salesAgent, commissionShare } = subsaleData;
    const totalCommission = purchasePrice * (commissionPercentge / 100);

    const commissions = salesAgent.map((agent, index) => {
      const user = usersData.find(u => u.name === agent);
      const agentTier = user ? user.subsalesTier : 0;
      const agentShare = commissionShare[index] / 100;
      return {
        agent: agent,
        commission: totalCommission * (agentTier / 100) * agentShare
      };
    });

    const currentUserCommission = commissions.find(commission => commission.agent === currentUserName);
    return currentUserCommission ? currentUserCommission.commission.toFixed(2) : '0.00';
  };

  const calculateProjectCommission = (projectsOrder, projectsData, usersData, currentUserName) => {
    const { nettPrice, salesAgent, commissionShare, project } = projectsOrder;
  
    const projectData = projectsData.find(proj => proj.name === project);
    if (!projectData) return '0.00';
  
    const totalCommission = nettPrice * (projectData.commission / 100);
  
    const commissions = salesAgent.map((agent, index) => {
      const user = usersData.find(u => u.name === agent);
      const agentTier = user ? user.subsalesTier : 0;
      const agentShare = commissionShare[index] / 100;
      return {
        agent: agent,
        commission: totalCommission * (agentTier / 100) * agentShare
      };
    });
  
    const currentUserCommission = commissions.find(commission => commission.agent === currentUserName);
    return currentUserCommission ? currentUserCommission.commission.toFixed(2) : '0.00';
  };

  const handleRowClick = (rowData) => {
    const { category, docId } = rowData;
    let path = '';
    let state = {};

    switch (category) {
      case 'Rental':
        path = `/${VIEWRENTALSDETAILSURL}/${docId}`;
        state = { from: 'Claims' };
        break;
      case 'Subsale':
        path = `/${VIEWSUBSALESDETAILSURL}/${docId}`;
        state = { from: 'Claims' };
        break;
      case 'Project':
        path = `/${VIEWPROJECTSDETAILSURL}/${docId}`;
        state = { from: 'Claims' };
        break;
      default:
        break;
    }

    if (path) {
      navigate(path, { state });
    }
  };

  return (
    <div className="claim-component" style={{ flex: 1 }}>
      <Container style={{ marginLeft: expand ? '260px' : '56px' }}>
        <NavBar />
        <CustomerHeader title={CLAIMS} />
        <Content style={{ marginTop: "20px" }}>
          <FlexboxGrid style={{ justifyContent: "space-around" }}>
            <CustomCard title={"Claimed"} value={claimedCommission} color={"#E9CE39"} />
            <CustomCard title={"Unclaimed"} value={unclaimedCommission} color={"#E0B030"} />
            <CustomCard title={"Pending"} value={pendingCommission} color={"#D9A02C"} />
          </FlexboxGrid>
          {loading ? (
            <Loader content="Loading..." style={{marginTop: "150px"}}/>
          ) : (
            <div style={{marginTop:"20px"}}>
              <Table data={filteredClaims} autoHeight onRowClick={handleRowClick}>
                <Column width={50} align="center" fixed>
                  <HeaderCell>No</HeaderCell>
                  <Cell dataKey="number" />
                </Column>
                <Column width={200} flexGrow={1}>
                  <HeaderCell>Category</HeaderCell>
                  <Cell dataKey="category" />
                </Column>
                <Column width={200} flexGrow={1}>
                  <HeaderCell>Status</HeaderCell>
                  <Cell dataKey="status" />
                </Column>
                <Column width={200} flexGrow={1}>
                  <HeaderCell>Calculated Commission</HeaderCell>
                  <Cell dataKey="calculatedCommission" />
                </Column>
                <Column width={200} flexGrow={1}>
                  <HeaderCell>Sales Agent</HeaderCell>
                  <Cell>
                    {rowData => rowData.salesAgent.join(', ')}
                  </Cell>
                </Column>
              </Table>
              <Pagination
                total={claims.length}
                limit={itemsPerPage}
                activePage={activePage}
                onChangePage={setActivePage}
                style={{ marginLeft: "20px", marginTop: "20px", marginBottom: "40px" }}
              />
            </div>
          )}
        </Content>
      </Container>
    </div>
  );
};

const Claims = () => {
  return (
    <ParentComponent>
      <ClaimsComponent />
    </ParentComponent>
  );
}

export default Claims;
