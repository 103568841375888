import React from 'react'
import { Form, Button, FlexboxGrid,Modal,Input,Whisper,Tooltip} from 'rsuite';
import InfoRoundIcon from '@rsuite/icons/InfoRound';

const ViewModalSubsales = ({ viewModalOpen, handleCloseViewModal, selectedRowData }) => {
    const downloadFile = (url) => {
        window.open(url, '_blank');
    };
    
    return (
        <Modal open={viewModalOpen} onClose={handleCloseViewModal}>
            <Modal.Header>
                <Modal.Title>View Sales Order</Modal.Title>
            </Modal.Header>
            {selectedRowData && 
                <Modal.Body>
                <Form fluid>
                    <div style={{marginBottom: "20px"}}>
                        <h5>Property Details</h5>
                    </div>
                    <Form.Group controlId="landlordName">
                        <Form.ControlLabel>Landlord Name *</Form.ControlLabel>
                        <Form.Control name="landlordName" value={selectedRowData.landlordName} />
                    </Form.Group>
                    <Form.Group controlId="purchaserName">
                        <Form.ControlLabel>Purchaser Name *</Form.ControlLabel>
                        <Form.Control name="purchaserName" value={selectedRowData.purchaserName}/>
                       
                    </Form.Group>
                    <Form.Group controlId="project">
                        <Form.ControlLabel>Project Name *</Form.ControlLabel>
                        <Form.Control name="project" value={selectedRowData.project} />
                        
                    </Form.Group>
                    <FlexboxGrid style={{marginBottom: "20px"}}>
                        <FlexboxGrid.Item colspan={8}>
                            <Form.Group controlId="lotNumber">
                                <Form.ControlLabel>Lot Number *</Form.ControlLabel>
                                <Form.Control name="lotNumber" value={selectedRowData.lotNumber} />
                                
                            </Form.Group>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={6}>
                        <Form.Group controlId="bookingAmount">
                            <Form.ControlLabel>Booking Amount (RM) *</Form.ControlLabel>
                            <Form.Control name="bookingAmount" value={selectedRowData.bookingAmount} />
                            
                        </Form.Group>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    
                    <FlexboxGrid style={{marginBottom: "20px"}}>
                        <FlexboxGrid.Item colspan={8}>
                            <Form.Group controlId="purchasePrice">
                                <Form.ControlLabel>Purchase Price (RM) *</Form.ControlLabel>
                                <Form.Control name="purchasePrice" value={selectedRowData.purchasePrice} />
                                
                            </Form.Group>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={8}>
                        <Form.Group controlId="commissionPercentge">
                                <Form.ControlLabel>Commission (%) *</Form.ControlLabel>
                                <Form.Control name="commissionPercentge" value={selectedRowData.commissionPercentge}/>
                                
                            </Form.Group>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    
                    <FlexboxGrid style={{marginBottom: "20px"}}>
                        <FlexboxGrid.Item colspan={8}>
                            <Form.Group controlId="spaPrice">
                                <Form.ControlLabel>SPA Price (RM) *</Form.ControlLabel>
                                <Form.Control name="spaPrice" value={selectedRowData.spaPrice}/>
                                
                            </Form.Group>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={8}>
                            <Form.Group controlId="spaDate">
                                <Form.ControlLabel>SPA Date</Form.ControlLabel>
                                <Form.Control readOnly name="spaDate" value={selectedRowData.spaDate && new Date(selectedRowData.spaDate.seconds * 1000).toLocaleDateString()} />
                            </Form.Group>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <FlexboxGrid style={{marginBottom: "40px"}}>
                        <FlexboxGrid.Item colspan={8}>
                            <Form.Group controlId="status">
                                <Form.ControlLabel>Status</Form.ControlLabel>
                                <Form.Control name="status" value={selectedRowData.status}/>    
                            </Form.Group>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={8}>
                            
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <div style={{paddingRight: "40px"}}>
                        <Form.Group controlId="remark" style={{marginBottom: "20px"}}>
                            <Form.ControlLabel>Remark</Form.ControlLabel>
                            <Input as="textarea" rows={3} placeholder="Remarks..." name="remark" value={selectedRowData.remark}/>
                        </Form.Group>
                    </div>
                    <div style={{marginBottom: "20px"}}>
                        <h5>Document Details</h5>
                    </div>

                    <Form.Group controlId="bookingFormUrl" style={{ marginBottom: "20px" }}>
                        <Form.ControlLabel>Booking Form</Form.ControlLabel>
                        {selectedRowData.bookingFormUrl ? 
                            <Button appearance="link" onClick={() => downloadFile(selectedRowData.bookingFormUrl)} style={{ marginLeft: '10px' }}>Download</Button> 
                            : <div>N/A</div>}
                    </Form.Group>

                    <Form.Group controlId="spaCopyUrl" style={{ marginBottom: "20px" }}>
                        <Form.ControlLabel>SPA Copy</Form.ControlLabel>
                        {selectedRowData.spaCopyUrl ? 
                            <Button appearance="link" onClick={() => downloadFile(selectedRowData.spaCopyUrl)} style={{ marginLeft: '10px' }}>Download</Button> 
                            : <div>N/A</div>}
                    </Form.Group>

                    <Form.Group controlId="cancellationFormUrl" style={{ marginBottom: "40px" }}>
                        <Form.ControlLabel>Cancellation Form</Form.ControlLabel>
                        {selectedRowData.cancellationFormUrl ? 
                            <Button appearance="link" onClick={() => downloadFile(selectedRowData.cancellationFormUrl)} style={{ marginLeft: '10px' }}>Download</Button> 
                            : <div>N/A</div>}
                    </Form.Group>

                    <h5 style={{ marginBottom: "20px" }}>Agent Details</h5>
                        {selectedRowData.salesAgent.map((agent, index) => (
                            <FlexboxGrid key={index} style={{ marginBottom: "20px" }}>
                                <FlexboxGrid.Item colspan={8}>
                                    <Form.Group controlId={`salesAgent-${index}`}>
                                        <Form.ControlLabel>Sales Agent</Form.ControlLabel>
                                        <Form.Control readOnly name={`salesAgent-${index}`} value={agent} />
                                    </Form.Group>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={6}>
                                    <Form.Group controlId={`commissionShare-${index}`}>
                                        <Form.ControlLabel>Commission Share</Form.ControlLabel>
                                        <Form.Control readOnly name={`commissionShare-${index}`} value={selectedRowData.commissionShare[index]} />
                                    </Form.Group>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        ))} 
                </Form>
                </Modal.Body>
            }
            <Modal.Footer>
                <Button onClick={handleCloseViewModal} appearance="subtle">Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ViewModalSubsales;
