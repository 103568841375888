import React, { useState, useEffect, useContext } from 'react';
import { Container, Content, Form, Button, FlexboxGrid, Message, useToaster, Modal, Steps } from 'rsuite';
import { useParams, useLocation } from 'react-router-dom';
import SideBar1 from '../../components/SideBar1/SideBar1';
import NavBar from '../../components/NavBar/NavBar';
import { db } from '../../firebase';
import { doc, getDoc, getDocs, collection, setDoc, query, where, updateDoc } from "firebase/firestore";
import ViewDataComponent from '../../components/ViewDataComponent/ViewDataComponent';
import { RENTALCLAIMDETAILS, VIEWRENTALSORDER } from '../../constants/constants';
import CustomerHeader from '../../components/Header/Header';
import { AuthContext } from '../../context/authContext';
import { reloadPage } from '../../Helpers/ReloadPage';

const ViewRentalOrder = () => {
  const [expand, setExpand] = useState(true);

  const handleToggleExpand = () => {
    setExpand(!expand);
  };

  return (
    <div style={{ display: 'flex' }}>
      <SideBar1 expand={expand} onToggleExpand={handleToggleExpand} />
      <ViewRentalOrderData expand={expand} />
    </div>
  );
}

export default ViewRentalOrder;

const ViewRentalOrderData = ({ expand }) => {
  const { id } = useParams();
  const { currentUser } = useContext(AuthContext);
  const [saleData, setSaleData] = useState(null);
  const [usersData, setUsersData] = useState(null);
  const [submitBy, setSubmitBy] = useState("");
  const [formData, setFormData] = useState({});
  const [claimStatus, setClaimStatus] = useState(null);
  const [isClaimSubmitted, setIsClaimSubmitted] = useState(false);
  const [remark, setRemark] = useState("");
  const toaster = useToaster();
  const colSpanTitle = 3;
  const location = useLocation();
  const title = location.state?.from === 'Claims' ? RENTALCLAIMDETAILS : VIEWRENTALSORDER;
  const [open, setOpen] = useState(false);
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [openReturnModal, setOpenReturnModal] = useState(false);
  const [showAdminActions, setShowAdminActions] = useState(true);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenApproveModal = () => setOpenApproveModal(true);
  const handleCloseApproveModal = () => setOpenApproveModal(false);
  const handleOpenReturnModal = () => setOpenReturnModal(true);
  const handleCloseReturnModal = () => setOpenReturnModal(false);

  const downloadFile = (url) => {
    window.open(url, '_blank');
  };

  useEffect(() => {
    const fetchSalesOrderData = async () => {
      try {
        const usersSnapshot = await getDocs(collection(db, "users"));
        const usersData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setUsersData(usersData);

        const saleDoc = await getDoc(doc(db, "rentalOrders", id));
        if (saleDoc.exists()) {
          const data = saleDoc.data();
          setSaleData(data);
          setFormData(data); // Initialize form data with fetched data
          if (data.uid) {
            const userDoc = await getDoc(doc(db, "users", data.uid));
            if (userDoc.exists()) {
              setSubmitBy(userDoc.data().name);
            }
          }
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching claim data: ", error);
      }
    };

    fetchSalesOrderData();
  }, [id, toaster]);

  useEffect(() => {
    const fetchClaimData = async () => {
      try {
        let q;
        if (currentUser.position === 'Admin') {
          q = query(collection(db, "claims"), where("docId", "==", id));
        } else {
          q = query(collection(db, "claims"), where("docId", "==", id), where("uid", "==", currentUser.uid));
        }
        const claimSnapshot = await getDocs(q);
        if (!claimSnapshot.empty) {
          const claimData = claimSnapshot.docs[0].data();
          setClaimStatus(claimData.status);
          setIsClaimSubmitted(true); // Set claim submitted to true if there's already a claim
          if (claimData.status === 3) {
            setRemark(claimData.remark); // Set remark if status is 3
          }
          console.log("Claim data:", claimData); // Debugging log
        }
      } catch (error) {
        console.error("Error fetching claim data: ", error);
      }
    };

    if (location.state?.from === 'Claims') {
      fetchClaimData();
    }
  }, [id, currentUser.uid, currentUser.position, location.state?.from]);

  console.log(claimStatus);

  const handleChange = (key, value, index) => {
    if (key === 'salesAgent' || key === 'commissionShare') {
      const updatedArray = [...formData[key]];
      updatedArray[index] = value;
      setFormData(prevFormData => ({
        ...prevFormData,
        [key]: updatedArray
      }));
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        [key]: value
      }));
    }
  };

  const calculateRentalCommission = (rentalData, usersData, currentUserName) => {
    const monthlyRent = formData.rentalMonthly;
    const ownerCommissionMonths = formData.serviceFeeMonth;
    const totalCommission = monthlyRent * ownerCommissionMonths;

    const commissions = formData.salesAgent.map((agent, index) => {
      const user = usersData.find(u => u.name === agent);
      const agentTier = user ? user.subsalesTier : 0;
      const agentShare = rentalData.commissionShare[index] / 100;
      return {
        agent: agent,
        commission: totalCommission * (agentTier / 100) * agentShare
      };
    });

    const currentUserCommission = commissions.find(commission => commission.agent === currentUserName);
    return currentUserCommission ? currentUserCommission.commission.toFixed(2) : '0.00';
  };

  const handleClaimSubmit = async () => {
    try {
      const calculatedCommission = calculateRentalCommission(formData, usersData, currentUser.name);
      const q = query(collection(db, "claims"), where("docId", "==", id), where("uid", "==", currentUser.uid));
      const claimSnapshot = await getDocs(q);
      if (!claimSnapshot.empty) {
        const claimId = claimSnapshot.docs[0].id;
        const claimDocRef = doc(db, "claims", claimId);
        await updateDoc(claimDocRef, {
          status: 1,
          calculatedCommission: calculatedCommission,
          remark: ""
        });
      } else {
        const claimData = {
          docId: id,
          uid: currentUser.uid,
          salesAgent: formData.salesAgent,
          commissionShare: formData.commissionShare,
          category: "Rental",
          status: 1,
          calculatedCommission: calculatedCommission
        };
        await setDoc(doc(collection(db, "claims")), claimData);
      }
      toaster.push(<Message type="success" closable>Claim submitted successfully!</Message>);
      setOpen(false);
      reloadPage();
      setIsClaimSubmitted(true); // Set claim submitted to true after submission
    } catch (error) {
      console.error("Error submitting claim: ", error);
      toaster.push(<Message type="error" closable>Error submitting claim!</Message>);
    }
  };

  const handleApprove = async () => {
    try {
      const q = query(collection(db, "claims"), where("docId", "==", id));
      const claimSnapshot = await getDocs(q);
      if (!claimSnapshot.empty) {
        const claimId = claimSnapshot.docs[0].id;
        const claimDocRef = doc(db, "claims", claimId);
        await updateDoc(claimDocRef, { status: 2 });
        toaster.push(<Message type="success" closable>Claim approved successfully!</Message>);
        setOpenApproveModal(false);
        setShowAdminActions(false); // Hide approve and return buttons after approval
        reloadPage();
      }
    } catch (error) {
      console.error("Error approving claim: ", error);
      toaster.push(<Message type="error" closable>Error approving claim!</Message>);
    }
  };

  const handleReturn = async () => {
    try {
      const q = query(collection(db, "claims"), where("docId", "==", id));
      const claimSnapshot = await getDocs(q);
      if (!claimSnapshot.empty) {
        const claimId = claimSnapshot.docs[0].id;
        const claimDocRef = doc(db, "claims", claimId);
        await updateDoc(claimDocRef, { status: 3, remark });
        toaster.push(<Message type="success" closable>Claim returned successfully!</Message>);
        setOpenReturnModal(false);
        reloadPage();
      }
    } catch (error) {
      console.error("Error returning claim: ", error);
      toaster.push(<Message type="error" closable>Error returning claim!</Message>);
    }
  };

  const renderSteps = () => {
    if (!claimStatus || claimStatus === 3 || currentUser.position === "Admin") return null;

    let currentStep = 0;
    switch (claimStatus) {
      case 1:
        currentStep = 1;
        break;
      case 2:
        currentStep = 2;
        break;
      default:
        break;
    }

    return (
      <Steps current={currentStep} style={{ marginBottom: "40px", marginTop: "20px" }}>
        <Steps.Item title="Submitted" />
        <Steps.Item title="In Progress" />
        <Steps.Item title="Completed" />
      </Steps>
    );
  };

  if (!saleData) {
    return (
      <Container style={{ marginLeft: expand ? '260px' : '56px' }}>
        <NavBar />
        <Content style={{ padding: "20px", textAlign: "left" }}>
          <Message type="info">Loading claim details...</Message>
        </Content>
      </Container>
    );
  }

  return (
    <Container style={{ marginLeft: expand ? '260px' : '56px' }}>
      <NavBar />
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <CustomerHeader title={title} />
        {((location.state?.from === 'Claims' && !isClaimSubmitted && currentUser.position !== 'Admin') || (claimStatus === 3 && currentUser.position !== 'Admin')) && (
          <Button appearance="primary" style={{ marginTop: "10px", marginRight: "20px" }} onClick={handleOpen}>
            Claim
          </Button>
        )}
        {currentUser.position === 'Admin' && claimStatus === 1 && (
          <div style={{ display: 'flex', gap: '10px', marginTop: '10px', marginRight: '20px' }}>
            <Button appearance="primary" onClick={handleOpenApproveModal}>Approve</Button>
            <Button appearance="default" onClick={handleOpenReturnModal}>Return</Button>
          </div>
        )}
      </div>
      <Content style={{ padding: "20px", textAlign: "left" }}>
        {claimStatus === 3 && remark && currentUser.position !== 'Admin' && (
          <Message type="warning" style={{ marginBottom: "20px" }}>
              Remark: {remark}
          </Message>
        )}
        {renderSteps()}
        <Form fluid>
          <FlexboxGrid style={{ marginBottom: "10px" }} align="middle">
            <FlexboxGrid.Item colspan={colSpanTitle}>
              <Form.ControlLabel>Submitted By</Form.ControlLabel>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={8}>
              <Form.Control readOnly name="submitBy" value={submitBy} />
            </FlexboxGrid.Item>
          </FlexboxGrid>
          <ViewDataComponent title="Landlord Name" data={formData.landlordName} />
          <ViewDataComponent title="Tenant Name" data={formData.tenantName} />
          <ViewDataComponent title="Project Name" data={formData.project} />
          <ViewDataComponent title="Lot No." data={formData.lotNumber} />
          <ViewDataComponent title="Status" data={formData.status} />
          <ViewDataComponent title="Rental Per Month (RM)" data={formData.rentalMonthly} />
          <ViewDataComponent title="Total Rental Deposit (RM)" data={formData.totalRentalDeposit} />
          <ViewDataComponent title="Security Deposit (RM)" data={formData.securityDeposit} />
          <ViewDataComponent title="Access Card Deposit (RM)" data={formData.accessCardDeposit} />
          <ViewDataComponent title="Stamping Fee (RM)" data={formData.stampingFee} />
          <ViewDataComponent title="Referral Name" data={formData.referralName === "" ? "N/A" : formData.referralName} />
          <FlexboxGrid style={{ marginBottom: "20px" }} align="middle">
            <FlexboxGrid.Item colspan={colSpanTitle}>
              <Form.ControlLabel>Rental Form</Form.ControlLabel>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={8}>
              {formData.rentalFormUrl ? (
                <Button
                  appearance="link"
                  onClick={() => downloadFile(formData.rentalFormUrl)}
                  style={{ marginLeft: '10px' }}
                >
                  Download
                </Button>
              ) : (
                <div style={{ paddingLeft: "20px" }}>N/A</div>
              )}
            </FlexboxGrid.Item>
          </FlexboxGrid>

          <FlexboxGrid style={{ marginBottom: "20px" }} align="middle">
            <FlexboxGrid.Item colspan={colSpanTitle}>
              <Form.ControlLabel>Transaction Slip 1</Form.ControlLabel>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={8}>
              {formData.transactionSlip1Url ? (
                <Button
                  appearance="link"
                  onClick={() => downloadFile(formData.transactionSlip1Url)}
                  style={{ marginLeft: '10px' }}
                >
                  Download
                </Button>
              ) : (
                <div style={{ paddingLeft: "20px" }}>N/A</div>
              )}
            </FlexboxGrid.Item>
          </FlexboxGrid>

          <FlexboxGrid style={{ marginBottom: "20px" }} align="middle">
            <FlexboxGrid.Item colspan={colSpanTitle}>
              <Form.ControlLabel>Transaction Slip 2</Form.ControlLabel>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={8}>
              {formData.transactionSlip2Url ? (
                <Button
                  appearance="link"
                  onClick={() => downloadFile(formData.transactionSlip2Url)}
                  style={{ marginLeft: '10px' }}
                >
                  Download
                </Button>
              ) : (
                <div style={{ paddingLeft: "20px" }}>N/A</div>
              )}
            </FlexboxGrid.Item>
          </FlexboxGrid>

          <FlexboxGrid style={{ marginBottom: "20px" }} align="middle">
            <FlexboxGrid.Item colspan={colSpanTitle}>
              <Form.ControlLabel>Tenancy Agreement</Form.ControlLabel>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={8}>
              {formData.tenancyAgreementUrl ? (
                <Button
                  appearance="link"
                  onClick={() => downloadFile(formData.tenancyAgreementUrl)}
                  style={{ marginLeft: '10px' }}
                >
                  Download
                </Button>
              ) : (
                <div style={{ paddingLeft: "20px" }}>N/A</div>
              )}
            </FlexboxGrid.Item>
          </FlexboxGrid>

          <FlexboxGrid style={{ marginBottom: "30px" }} align="middle">
            <FlexboxGrid.Item colspan={colSpanTitle}>
              <Form.ControlLabel>Remark</Form.ControlLabel>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={8}>
              <Form.Control readOnly name="remark" value={formData.remark} onChange={value => handleChange('remark', value)} />
            </FlexboxGrid.Item>
          </FlexboxGrid>

          <h5 style={{ marginBottom: "30px" }}>Agent Details</h5>

          {formData.salesAgent.map((agent, index) => (
            <FlexboxGrid key={index} style={{ marginBottom: "20px" }} align="middle">
              <FlexboxGrid.Item colspan={3}>
                <Form.Group controlId={`salesAgent-${index}`}>
                  <Form.ControlLabel>Sales Agent</Form.ControlLabel>
                  <Form.ControlLabel>{formData.salesAgent[index]}</Form.ControlLabel>
                </Form.Group>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={8}>
                <Form.ControlLabel>Shared Commission</Form.ControlLabel>
                <Form.Group controlId={`commissionShare-${index}`}>
                  <Form.Control readOnly name={`commissionShare-${index}`} value={formData.commissionShare[index]} />
                </Form.Group>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          ))}
        </Form>
      </Content>

      <Modal open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Confirm Claim</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to submit this claim?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClaimSubmit} appearance="primary">Confirm</Button>
          <Button onClick={handleClose} appearance="ghost">Cancel</Button>
        </Modal.Footer>
      </Modal>

      <Modal open={openApproveModal} onClose={handleCloseApproveModal}>
        <Modal.Header>
          <Modal.Title>Approve Claim</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to approve this claim?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleApprove} appearance="primary">Approve</Button>
          <Button onClick={handleCloseApproveModal} appearance="ghost">Cancel</Button>
        </Modal.Footer>
      </Modal>

      <Modal open={openReturnModal} onClose={handleCloseReturnModal}>
        <Modal.Header>
          <Modal.Title>Return Claim</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form fluid>
            <Form.Group controlId="remark">
              <Form.ControlLabel>Remark</Form.ControlLabel>
              <Form.Control name="remark" value={remark} onChange={value => setRemark(value)} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleReturn} appearance="primary">Return</Button>
          <Button onClick={handleClose} appearance="ghost">Cancel</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
