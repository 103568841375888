import React from 'react'
import { FlexboxGrid, Panel } from 'rsuite'

const CustomCard = ({title, value, color}) => {
    const COLSPAN = 7;

    return (
        <FlexboxGrid.Item colspan={COLSPAN}>
            <Panel header={title} shaded style={{textAlign: "left", backgroundColor: color}}>
                RM 
                <h2 style={{color: "white"}}>{value}</h2>
            </Panel>
        </FlexboxGrid.Item>
    )
}

export default CustomCard