import { useContext, useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import {
  Container,
  Content,
  Form,
  ButtonToolbar,
  Button,
  FlexboxGrid,
  useToaster,
  Message,
  Panel,
} from 'rsuite';
import { doc, getDoc } from "firebase/firestore";
import './login.css'; // Assuming you will use a CSS file for custom styles
import logo from '../../assets/logo.jpg';

const Login = () => {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const toaster = useToaster();
  const navigate = useNavigate()

  const {dispatch} = useContext(AuthContext)

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Signed in
        const user = userCredential.user;
        // Fetch user data from Firestore
        const userDoc = await getDoc(doc(db, "users", user.uid));
        const userData = userDoc.data();
        dispatch({type:"LOGIN", payload: {uid: user.uid, email: user.email, ...userData}})
        navigate("/")
      })
      .catch((error) => {
        toaster.push(<Message type="error" duration={3000}>Invalid email or password.</Message>);
      });
    }catch(error){
      
    }
  };

  const handleEmailChange = (value) => {
    setEmail(value);
  };
  
  const handlePasswordChange = (value) => {
    setPassword(value);
  };

  return (
    <div className="login-page">
      <Container>
        <FlexboxGrid justify="center">
          <FlexboxGrid.Item colspan={12}>
            <div className="login-panel">
              <img src={logo} alt="LSV Logo" className="login-logo"/> {/* Add your logo here */}
              <Panel header={<h3>Sign-In</h3>} bordered>
                <Form fluid onSubmit={handleLogin}>
                  <Form.Group>
                    <Form.ControlLabel style={{textAlign: "left", marginBottom: "10px"}}>Email</Form.ControlLabel>
                    <Form.Control name="email" type="email" onChange={handleEmailChange} placeholder="Enter your email address" />
                  </Form.Group>
                  <Form.Group>
                    <Form.ControlLabel style={{textAlign: "left", marginBottom: "10px"}}>Password</Form.ControlLabel>
                    <Form.Control name="password" type="password" autoComplete="off" onChange={handlePasswordChange} placeholder="Enter your password" />
                  </Form.Group>
                  
                  <Form.Group>
                    <ButtonToolbar>
                      <Button appearance="primary" onClick={handleLogin} block>Sign in</Button>
                    </ButtonToolbar>
                  </Form.Group>
                </Form>
              </Panel>
            </div>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Container>
    </div>
  );
};

export default Login;
