import React from 'react';
import { 
    Form, 
    Button, 
    FlexboxGrid,
    Modal,
    Input,
} from 'rsuite';

const ViewModalRental = ({ viewModalOpen, handleCloseViewModal, selectedRowData }) => {
    const downloadFile = (url) => {
        window.open(url, '_blank');
    };

    return (
        <Modal keyboard={false} open={viewModalOpen} onClose={handleCloseViewModal}>
            <Modal.Header>
                <Modal.Title>View Rental Order</Modal.Title>
            </Modal.Header>
            {selectedRowData && 
                <Modal.Body>
                    <Form fluid>
                        <div style={{ marginBottom: "20px" }}>
                            <h5>Property Details</h5>
                        </div>
                        <FlexboxGrid style={{ marginBottom: "20px" }}>
                            <FlexboxGrid.Item colspan={8}>
                                <Form.Group controlId="landlordName">
                                    <Form.ControlLabel>Landlord Name</Form.ControlLabel>
                                    <Form.Control readOnly name="landlordName" value={selectedRowData.landlordName || ''} />
                                </Form.Group>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={8}>
                                <Form.Group controlId="tenantName">
                                    <Form.ControlLabel>Tenant Name</Form.ControlLabel>
                                    <Form.Control readOnly name="tenantName" value={selectedRowData.tenantName || ''} />
                                </Form.Group>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>

                        <FlexboxGrid style={{ marginBottom: "20px" }}>
                            <FlexboxGrid.Item colspan={8}>
                                <Form.Group controlId="lotNumber">
                                    <Form.ControlLabel>Lot Number</Form.ControlLabel>
                                    <Form.Control readOnly name="lotNumber" value={selectedRowData.lotNumber || ''} />
                                </Form.Group>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={8}>
                                <Form.Group controlId="project">
                                    <Form.ControlLabel>Project Name</Form.ControlLabel>
                                    <Form.Control readOnly name="project" value={selectedRowData.project || ''} />
                                </Form.Group>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>

                        <div style={{ marginBottom: "20px" }}>
                            <h5>Fee Details</h5>
                        </div>
                        <FlexboxGrid style={{ marginBottom: "20px" }}>
                            <FlexboxGrid.Item colspan={8}>
                                <Form.Group controlId="totalRentalDeposit">
                                    <Form.ControlLabel>Total Rental Deposit (RM)</Form.ControlLabel>
                                    <Form.Control readOnly name="totalRentalDeposit" value={selectedRowData.totalRentalDeposit || ''} />
                                </Form.Group>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={8}>
                                <Form.Group controlId="securityDeposit">
                                    <Form.ControlLabel>Security Deposit (RM)</Form.ControlLabel>
                                    <Form.Control readOnly name="securityDeposit" value={selectedRowData.securityDeposit || ''} />
                                </Form.Group>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>

                        <FlexboxGrid style={{ marginBottom: "20px" }}>
                            <FlexboxGrid.Item colspan={8}>
                                <Form.Group controlId="accessCardDeposit">
                                    <Form.ControlLabel>Access Card Deposit (RM)</Form.ControlLabel>
                                    <Form.Control readOnly name="accessCardDeposit" value={selectedRowData.accessCardDeposit || ''} />
                                </Form.Group>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={8}>
                                <Form.Group controlId="rentalMonthly">
                                    <Form.ControlLabel>Rental Per Month (RM)</Form.ControlLabel>
                                    <Form.Control readOnly name="rentalMonthly" value={selectedRowData.rentalMonthly || ''} />
                                </Form.Group>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>

                        <FlexboxGrid style={{ marginBottom: "40px" }}>
                            <FlexboxGrid.Item colspan={8}>
                                <Form.Group controlId="serviceFeeMonth">
                                    <Form.ControlLabel>Service Fee (Month)</Form.ControlLabel>
                                    <Form.Control readOnly name="serviceFeeMonth" value={selectedRowData.serviceFeeMonth || ''} />
                                </Form.Group>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={8}>
                                <Form.Group controlId="stampingFee">
                                    <Form.ControlLabel>Stamping Fees (RM)</Form.ControlLabel>
                                    <Form.Control readOnly name="stampingFee" value={selectedRowData.stampingFee || ''} />
                                </Form.Group>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>

                        <div style={{ marginBottom: "20px" }}>
                            <h5>Document Details</h5>
                        </div>
                        <Form.Group controlId="rentalFormUrl">
                            <Form.ControlLabel>Complete Rental Form</Form.ControlLabel>
                            {selectedRowData.rentalFormUrl ? 
                                <Button appearance="link" onClick={() => downloadFile(selectedRowData.rentalFormUrl)} style={{ marginLeft: '10px' }}>Download</Button> 
                                : <div>N/A</div>}
                        </Form.Group>
                        <Form.Group controlId="transactionSlip1Url">
                            <Form.ControlLabel>Transaction Slip 1</Form.ControlLabel>
                            {selectedRowData.transactionSlip1Url ? 
                                <Button appearance="link" onClick={() => downloadFile(selectedRowData.transactionSlip1Url)} style={{ marginLeft: '10px' }}>Download</Button> 
                                : <div>N/A</div>}
                        </Form.Group>
                        <Form.Group controlId="transactionSlip2Url">
                            <Form.ControlLabel>Transaction Slip 2</Form.ControlLabel>
                            {selectedRowData.transactionSlip2Url ? 
                                <Button appearance="link" onClick={() => downloadFile(selectedRowData.transactionSlip2Url)} style={{ marginLeft: '10px' }}>Download</Button> 
                                : <div>N/A</div>}
                        </Form.Group>
                        <Form.Group controlId="tenancyAgreementUrl">
                            <Form.ControlLabel>Tenancy Agreement</Form.ControlLabel>
                            {selectedRowData.tenancyAgreementUrl ? 
                                <Button appearance="link" onClick={() => downloadFile(selectedRowData.tenancyAgreementUrl)} style={{ marginLeft: '10px' }}>Download</Button> 
                                : <div>N/A</div>}
                        </Form.Group>
                        <Form.Group controlId="stampingCertUrl">
                            <Form.ControlLabel>Stamping Certificate</Form.ControlLabel>
                            {selectedRowData.stampingCertUrl ? 
                                <Button appearance="link" onClick={() => downloadFile(selectedRowData.stampingCertUrl)} style={{ marginLeft: '10px' }}>Download</Button> 
                                : <div>N/A</div>}
                        </Form.Group>

                        <div style={{ paddingRight: "40px", marginBottom: "20px" }}>
                            <Form.Group controlId="remark">
                                <Form.ControlLabel>Remark</Form.ControlLabel>
                                <Input as="textarea" readOnly rows={3} placeholder="Remarks..." name="remark" value={selectedRowData.remark || ''} />
                            </Form.Group>
                        </div>

                        <FlexboxGrid style={{ marginBottom: "40px" }}>
                            <FlexboxGrid.Item colspan={8}>
                                <Form.Group controlId="status">
                                    <Form.ControlLabel>Status</Form.ControlLabel>
                                    <Form.Control readOnly name="status" value={selectedRowData.status || ''} />
                                </Form.Group>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={8}>
                                <Form.Group controlId="rentalDepositMonths">
                                    <Form.ControlLabel>Rental Deposit Months</Form.ControlLabel>
                                    <Form.Control readOnly name="rentalDepositMonths" value={selectedRowData.rentalDepositMonths || ''} />
                                </Form.Group>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>

                        <h5 style={{ marginBottom: "20px" }}>Agent Details</h5>
                        {selectedRowData.salesAgent && selectedRowData.salesAgent.map((agent, index) => (
                            <FlexboxGrid key={index} style={{ marginBottom: "20px" }}>
                                <FlexboxGrid.Item colspan={8}>
                                    <Form.Group controlId={`salesAgent-${index}`}>
                                        <Form.ControlLabel>Sales Agent</Form.ControlLabel>
                                        <Form.Control readOnly name={`salesAgent-${index}`} value={agent} />
                                    </Form.Group>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={6}>
                                    <Form.Group controlId={`commissionShare-${index}`}>
                                        <Form.ControlLabel>Commission Share</Form.ControlLabel>
                                        <Form.Control readOnly name={`commissionShare-${index}`} value={selectedRowData.commissionShare[index] || ''} />
                                    </Form.Group>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        ))}
                    </Form>
                </Modal.Body>
            }
            <Modal.Footer>
                <Button onClick={handleCloseViewModal} appearance="subtle">Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ViewModalRental;
