import React, { useState, useContext, useEffect } from 'react';
import {Container, Content, Form, Button, useToaster, Message, Table, Modal, Pagination, Input} from 'rsuite';
import { AuthContext } from '../../context/authContext';
import NavBar from '../../components/NavBar/NavBar';
import { doc, serverTimestamp, setDoc, collection, getDocs, updateDoc} from "firebase/firestore"; 
import { db } from "../../firebase";
import { reloadPage } from '../../Helpers/ReloadPage';
import { addNumbersToData } from '../../Helpers/AddNumber';
import { useNavigate } from 'react-router-dom';
import CustomerHeader from '../../components/Header/Header';
import { CONTACTS } from '../../constants/constants';

const { Column, HeaderCell, Cell } = Table;

const ContactComponent = ({ expand }) => {
  const [errors, setErrors] = useState({});
  const [documents, setDocuments] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState('');
  const [searchQuery, setSearchQuery] = useState("");
  const { currentUser } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const toaster = useToaster();
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditMode(false);
    setEditId('');
    setErrors({});
    setFormData(initialFormData); // Reset form data
  };

  const initialFormData = {
    name: "",
    contactNumber: "",
    remark: ""
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (name, value) => {
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    const fetchDocuments = async () => {
      const querySnapshot = await getDocs(collection(db, "contacts"));
      const documentList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      documentList.sort((a, b) => a.name.localeCompare(b.name));
      setDocuments(documentList);
    };

    fetchDocuments();
  }, []);

  const handleAddOrEditContact = async () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.contactNumber) newErrors.contactNumber = "Contact number is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      if (editMode) {
        await updateDoc(doc(db, "contacts", editId), {
          ...formData,
          updatedBy: currentUser.name,
        });
        toaster.push(<Message type='success'>Contact details updated successfully!</Message>);
      } else {
        await setDoc(doc(collection(db, "contacts")), {
          ...formData,
          timeStamp: serverTimestamp(),
          updatedBy: currentUser.name,
        });
        toaster.push(<Message type='success'>New contact details has been added successfully!</Message>);
      }

      handleClose();
      reloadPage();
      setErrors({});
    } catch (e) {
      console.log(e);
    }
  };

  const handleSearchChange = (value) => {
    setSearchQuery(value);
  };

  const filteredNames = documents.filter(contact => 
    contact.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const numberedData = addNumbersToData(filteredNames);

  const handleRowClick = (rowData) => {
    navigate(`/view-contact/${rowData.id}`);
};

  return (
    <div className="registration" style={{ flex: 1 }}>
      <Container style={{ marginLeft: expand ? '260px' : '56px' }}>
        <NavBar/>
        <CustomerHeader title={CONTACTS}/>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: "20px" }}>
        <div style={{ textAlign: "left", margin: "20px" }}>
              <Button appearance="primary" onClick={handleOpen}>
                Add Contact
              </Button>
              <Modal open={open} onClose={handleClose}>
                <Modal.Header>
                  <Modal.Title>{editMode ? 'Edit Contact Details' : 'Add Contact'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form style={{ textAlign: 'left', marginLeft: "40px", marginTop: "40px"}} >
                    <Form.Group controlId="name" style={{marginRight: "40px"}}>
                      <Form.ControlLabel>Name</Form.ControlLabel>
                      <Input rows={3} value={formData.name} onChange={value => handleChange('name', value)} />
                      {errors.name && <Form.HelpText style={{ color: 'red' }}>{errors.name}</Form.HelpText>}
                    </Form.Group>
                    <Form.Group controlId="contactNumber" style={{marginRight: "40px"}}>
                      <Form.ControlLabel>Contact Number</Form.ControlLabel>
                      <Input rows={3} value={formData.contactNumber} onChange={value => handleChange('contactNumber', value)} />
                      {errors.contactNumber && <Form.HelpText style={{ color: 'red' }}>{errors.contactNumber}</Form.HelpText>}  
                    </Form.Group>
                    <Form.Group controlId="remark" style={{marginRight: "40px"}}>
                      <Form.ControlLabel>Remark</Form.ControlLabel>
                      <Input as="textarea" rows={3} value={formData.remark} onChange={value => handleChange('remark', value)} />
                    </Form.Group>
                    
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={handleAddOrEditContact} appearance="primary">
                    {editMode ? 'Edit' : 'Add'}
                  </Button>
                  <Button onClick={handleClose} appearance="subtle">
                    Cancel
                  </Button>
                </Modal.Footer>
              </Modal>
            </div> 
          <div style={{ height: "80px", justifyContent: "center", marginTop: "20px", marginRight: "20px", marginLeft: "20px" }}>
              <Input 
                placeholder="Search by name"
                value={searchQuery}
                onChange={handleSearchChange}
                style={{ width: 300 }}
              />
          </div>
        </div>
        <Content style={{marginLeft: "20px"}}>
          <Table
            height={500}
            data={numberedData.slice((activePage - 1) * 10, activePage * 10)}
            onRowClick={handleRowClick}
            style={{ marginRight: "40px"}}
          >
            <Column width={60} align="center">
              <HeaderCell>No</HeaderCell>
              <Cell dataKey="number" />
            </Column>
            <Column width={400}>
              <HeaderCell align="left">Name</HeaderCell>
              <Cell align="left" dataKey="name" />
            </Column>
            <Column width={600}>
              <HeaderCell align="left">Contact Number</HeaderCell>
              <Cell align="left" dataKey="contactNumber" />
            </Column>
          </Table>
          <Pagination total={documents.length} limit={10} activePage={activePage} onChangePage={setActivePage} style={{ marginLeft: "20px", marginTop: "20px", marginBottom: "40px"}} />
        </Content>
      </Container>
    </div>
  )
}

export default ContactComponent;
