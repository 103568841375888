import React, { useState, useContext, useEffect } from 'react';
import NavBar from '../../components/NavBar/NavBar';
import { Container,  Content, Form, Button, Dropdown, useToaster, FlexboxGrid,Message,Table, Modal,Pagination,Input,IconButton} from 'rsuite';
import { addNumbersToData } from '../../Helpers/AddNumber';
import { AuthContext } from '../../context/authContext';
import { db } from '../../firebase';
import { doc, addDoc, collection, getDocs, updateDoc, deleteDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { reloadPage } from '../../Helpers/ReloadPage';
import ViewModal from './ViewModal';
import DeleteModal from '../salesOrder/DeleteModal';
import TrashIcon from '@rsuite/icons/Trash';
import { useNavigate } from 'react-router-dom';
import CustomerHeader from '../../components/Header/Header';
import { RENTALORDERS, VIEWRENTALSDETAILSURL } from '../../constants/constants';

const { Column, HeaderCell, Cell } = Table;

const statusData = [
  "Pending",
  "Cancel",
  "Ready To Convert",
  "Not Ready To Convert"
];

const rentalDepositMonthData = [
  "2 months",
  "3 months"
];

const tenureYearsData = [
    "1 year",
    "2 years",
    "3 years",
    "4 years"
];

const RentalsOrderComponents = ({expand}) => {
    
    const { currentUser } = useContext(AuthContext);
    const [activePage, setActivePage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [open, setOpen] = useState(false);
    const [errors, setErrors] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [editId, setEditId] = useState('');
    const [backdrop, setBackdrop] = useState('static');
    const toaster = useToaster();
    const [data, setData] = useState([]);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [users, setUsers] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState({});
    const navigate = useNavigate();

    const handleOpenViewModal = (rowData) => {
        setSelectedRowData(rowData);
        setViewModalOpen(true);
    };

    const handleCloseViewModal = () => {
        setSelectedRowData(null);
        setViewModalOpen(false);
    };

    const rentalFormData = {
      landlordName: "",
      tenantName: "",
      project: "",
      lotNumber: "",
      totalRentalDeposit: "",
      securityDeposit: "",
      accessCardDeposit: "",
      rentalMonthly: "",
      serviceFeeMonth: "",
      stampingFee: "",
      rentalFormUrl: "",
      transactionSlip1Url: "",
      transactionSlip2Url: "",
      tenancyAgreementUrl: "",
      stampingCertUrl: "",
      remark: "",
      status: statusData[0],
      tenureYears: tenureYearsData[0],
      rentalDepositMonths: rentalDepositMonthData[0],
      salesAgent: [currentUser.name],
      commissionShare: ["100"]
    }

    const [formData, setFormData] = useState(rentalFormData);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "rentalOrders"));
                const fetchedData = [];
                querySnapshot.forEach(doc => {
                    fetchedData.push({ id: doc.id, ...doc.data() });
                });
                const filteredData = fetchedData.filter(data => data.uid === currentUser.uid);
                const numberedData = addNumbersToData(filteredData);
                
                setData(numberedData);
                setFilteredData(numberedData);
                
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        const fetchUsers = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "users"));
                const usersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setUsers(usersData);
            } catch (error) {
                console.error("Error fetching users: ", error);
            }
        };

        fetchData();
        fetchUsers();
    }, [currentUser.uid]);

    const validateForm = () => {
        const newErrors = {};
  
        if (!formData.landlordName) newErrors.landlordName = "Landlord name is required";
        if (!formData.tenantName) newErrors.tenantName = "Tenant name is required";
        if (!formData.project) newErrors.project = "Project name is required";
        if (!formData.lotNumber) newErrors.lotNumber = "Lot number is required";
        if (!formData.totalRentalDeposit) newErrors.totalRentalDeposit = "Total rental deposit is required";
        if (!formData.securityDeposit) newErrors.securityDeposit = "Security deposit is required";
        if (!formData.accessCardDeposit) newErrors.accessCardDeposit = "Access card deposit is required";
        if (!formData.rentalMonthly) newErrors.rentalMonthly = "Rental per month is required";
        if (!formData.serviceFeeMonth) newErrors.serviceFeeMonth = "Service fee month is required";
        if (!formData.stampingFee) newErrors.stampingFee = "Stamping fee is required";
        if (!formData.rentalFormUrl) newErrors.rentalFormUrl = "Rental form is required";
        if (!formData.transactionSlip1Url) newErrors.transactionSlip1Url = "Transaction slip is required";
        if (!formData.tenancyAgreementUrl) newErrors.tenancyAgreementUrl = "Tenancy agreement is required";
        if (!formData.stampingCertUrl) newErrors.stampingCertUrl = "Stamping certificate is required";
        if (!formData.rentalDepositMonths) newErrors.rentalDepositMonths = "Rental deposit's month is required";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async () => {
        if (!validateForm()) {
            return;
        }

        const currentTime = new Date();

        try {
            
            if (editMode) {
                // Update existing sales order
                const rentalOrderRef = doc(db, "rentalOrders", editId);
                await updateDoc(rentalOrderRef, {
                    ...formData,
                    updatedAt: currentTime,
                    uid: currentUser.uid
                });
                toaster.push(<Message type='success'>Rental order updated successfully!</Message>);
            } else {
                // Add new sales order
                await addDoc(collection(db, "rentalOrders"), {
                    ...formData,
                    updatedAt: currentTime,
                    uid: currentUser.uid
                });
                toaster.push(<Message type='success'>New rental order added successfully!</Message>);
            }
            handleClose();
            reloadPage();
            setFormData(rentalFormData);
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };

    const handleChange = (key, value, index) => {
        if (key === 'salesAgent' || key === 'commissionShare') {
            const updatedArray = [...formData[key]];
            updatedArray[index] = value;
            setFormData(prevFormData => ({
                ...prevFormData,
                [key]: updatedArray
            }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [key]: value
            }));
        }
    };

    const handleNumberChange = (key, value, index) => {
        if (/^\d*\.?\d*$/.test(value)) {
            if ((key === 'loanMargin' || key === 'commissionShare') && Number(value) > 100) {
                toaster.push(<Message type='error'>Value cannot exceed 100</Message>);
            } else {
                handleChange(key, value, index);
            }
        }
    };

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
      setOpen(false);
      setEditMode(false);
      setEditId('');
      setFormData(rentalFormData);
      setErrors({});
      setUploadedFiles({});
    };

    const handleSearchChange = (value) => {
        setSearchQuery(value);
        if (value === "") {
            setFilteredData(data);
        } else {
            const lowercasedFilter = value.toLowerCase();
            const filtered = data.filter(item => {
                return (
                    item.lotNumber.toLowerCase().includes(lowercasedFilter) ||
                    item.landlordName.toLowerCase().includes(lowercasedFilter) ||
                    item.tenantName.toLowerCase().includes(lowercasedFilter) ||
                    item.project.toLowerCase().includes(lowercasedFilter) ||
                    item.status.toLowerCase().includes(lowercasedFilter)
                );
            });
            setFilteredData(filtered);
        }
    };

    const addAgent = () => {
        setFormData(prevFormData => ({
            ...prevFormData,
            salesAgent: [...prevFormData.salesAgent, ""],
            commissionShare: [...prevFormData.commissionShare, ""]
        }));
    };

    const removeAgent = (index) => {
        const updatedAgents = formData.salesAgent.filter((_, i) => i !== index);
        const updatedShares = formData.commissionShare.filter((_, i) => i !== index);
        setFormData(prevFormData => ({
            ...prevFormData,
            salesAgent: updatedAgents,
            commissionShare: updatedShares
        }));
    };

    const handleFileChange = (key, file) => {
        setUploadedFiles(prevFiles => ({
            ...prevFiles,
            [key]: file.name
        }));
        const storage = getStorage();
        const storageRef = ref(storage, `documents/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on('state_changed', 
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
            }, 
            (error) => {
                // Handle unsuccessful uploads
                console.error("Error uploading file: ", error);
            }, 
            () => {
                // Handle successful uploads on complete
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        [key]: downloadURL
                    }));
                });
            }
        );
    };

    const removeFile = (key) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [key]: ""
        }));
        setUploadedFiles(prevFiles => ({
            ...prevFiles,
            [key]: ""
        }));
    };

    const handleEdit = (event, rowData) => {
        event.stopPropagation();
        setFormData({
            ...rowData
        });
        setEditId(rowData.id);
        setEditMode(true);
        handleOpen();
    };

    const handleDelete = async () => {
        try {
            await deleteDoc(doc(db, "rentalOrders", deleteId));
            handleDeleteClose();
            toaster.push(<Message type='success'>Rental order deleted successfully!</Message>);
            reloadPage();
        } catch (e) {
            console.log(e);
        }
    };
    
    const handleDeleteOpen = (event, id) => {
        event.stopPropagation();
        setDeleteId(id);
        setDeleteOpen(true);
    };
    
    const handleDeleteClose = () => {
        setDeleteOpen(false);
        setDeleteId('');
    };

    const handleRowClick = (rowData) => {
        navigate(`/${VIEWRENTALSDETAILSURL}/${rowData.id}`);
    };

    return (
        <div style={{flex: 1}}>
            <Container style={{ marginLeft: expand ? '260px' : '56px' }}>
                <Container>
                    <NavBar/>
                    <CustomerHeader title={RENTALORDERS}/>
                    <div style={{display:"flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <div style={{textAlign: "left", margin: "20px"}}>
                            <Button onClick={handleOpen} appearance="primary">
                                New Rental
                            </Button>
                            <Modal backdrop={backdrop} keyboard={false} open={open} onClose={handleClose}>
                                <Modal.Header>
                                    <Modal.Title>{editMode ? 'Edit Rental' : 'Add New Rental'}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                <Form fluid>
                                    <div style={{marginBottom: "20px"}}>
                                        <h5>Property Details</h5>
                                    </div>
                                    <div style={{paddingRight: "40px", marginBottom: "20px"}}>
                                      <Form.Group controlId="landlordName">
                                          <Form.ControlLabel>Landlord Name *</Form.ControlLabel>
                                          <Form.Control name="landlordName" value={formData.landlordName} onChange={value => handleChange('landlordName', value)} />
                                          {errors.landlordName && <Form.HelpText style={{ color: 'red' }}>{errors.landlordName}</Form.HelpText>}
                                      </Form.Group>
                                      <Form.Group controlId="tenantName">
                                          <Form.ControlLabel>Tenant Name *</Form.ControlLabel>
                                          <Form.Control name="tenantName" value={formData.tenantName} onChange={value => handleChange('tenantName', value)} />
                                          {errors.tenantName && <Form.HelpText style={{ color: 'red' }}>{errors.tenantName}</Form.HelpText>}
                                      </Form.Group>
                                      <Form.Group controlId="project">
                                            <Form.ControlLabel>Project Name *</Form.ControlLabel>
                                            <Form.Control name="project" value={formData.project} onChange={value => handleChange('project', value)} />
                                            {errors.project && <Form.HelpText style={{ color: 'red' }}>{errors.project}</Form.HelpText>}
                                        </Form.Group>
                                    </div>
                                    <FlexboxGrid style={{marginBottom: "20px"}}>
                                        <FlexboxGrid.Item colspan={8}>
                                            <Form.Group controlId="lotNumber">
                                                <Form.ControlLabel>Lot Number *</Form.ControlLabel>
                                                <Form.Control name="lotNumber" value={formData.lotNumber} onChange={value => handleChange('lotNumber', value)} />
                                                {errors.lotNumber && <Form.HelpText style={{ color: 'red' }}>{errors.lotNumber}</Form.HelpText>}
                                            </Form.Group>
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={8}>
                                        <Form.Group controlId="rentalDepositMonths">
                                                <Form.ControlLabel>Tenure Years</Form.ControlLabel>
                                                <Dropdown title={formData.tenureYears} activeKey={formData.tenureYears} onSelect={value => handleChange('tenureYears', value)}>
                                                    {tenureYearsData.map(tenureYears => <Dropdown.Item key={tenureYears} eventKey={tenureYears}>{tenureYears}</Dropdown.Item>)}
                                                </Dropdown>
                                                {errors.rentalDepositMonths && <Form.HelpText style={{ color: 'red' }}>{errors.rentalDepositMonths}</Form.HelpText>}
                                            </Form.Group>
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                    <FlexboxGrid style={{marginBottom: "20px"}}>
                                        <FlexboxGrid.Item colspan={8}>
                                            <Form.Group controlId="totalRentalDeposit">
                                                <Form.ControlLabel>Total Rental Deposit (RM) *</Form.ControlLabel>
                                                <Form.Control name="totalRentalDeposit" value={formData.totalRentalDeposit} onChange={value => handleChange('totalRentalDeposit', value)} />
                                                {errors.totalRentalDeposit && <Form.HelpText style={{ color: 'red' }}>{errors.totalRentalDeposit}</Form.HelpText>}
                                            </Form.Group>
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={8}>
                                            <Form.Group controlId="rentalDepositMonths">
                                                <Form.ControlLabel>Security Deposit (Month)</Form.ControlLabel>
                                                <Dropdown title={formData.rentalDepositMonths} activeKey={formData.rentalDepositMonths} onSelect={value => handleChange('rentalDepositMonths', value)}>
                                                    {rentalDepositMonthData.map(rentalDepositMonths => <Dropdown.Item key={rentalDepositMonths} eventKey={rentalDepositMonths}>{rentalDepositMonths}</Dropdown.Item>)}
                                                </Dropdown>
                                                {errors.rentalDepositMonths && <Form.HelpText style={{ color: 'red' }}>{errors.rentalDepositMonths}</Form.HelpText>}
                                            </Form.Group>
                                        </FlexboxGrid.Item>
                                        
                                    </FlexboxGrid>
                                    <FlexboxGrid style={{marginBottom: "20px"}}>
                                        <FlexboxGrid.Item colspan={8}>
                                            <Form.Group controlId="accessCardDeposit">
                                                <Form.ControlLabel>Access Card Deposit (RM) *</Form.ControlLabel>
                                                <Form.Control name="accessCardDeposit" value={formData.accessCardDeposit} onChange={value => handleChange('accessCardDeposit', value)} />
                                                {errors.accessCardDeposit && <Form.HelpText style={{ color: 'red' }}>{errors.accessCardDeposit}</Form.HelpText>}
                                            </Form.Group>
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={8}>
                                            <Form.Group controlId="rentalMonthly">
                                                <Form.ControlLabel>Rental Per Month (RM) *</Form.ControlLabel>
                                                <Form.Control name="rentalMonthly" value={formData.rentalMonthly} onChange={value => handleChange('rentalMonthly', value)} />
                                                {errors.rentalMonthly && <Form.HelpText style={{ color: 'red' }}>{errors.rentalMonthly}</Form.HelpText>}
                                            </Form.Group>
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                    <FlexboxGrid style={{marginBottom: "20px"}}>
                                        <FlexboxGrid.Item colspan={8}>
                                            <Form.Group controlId="serviceFeeMonth">
                                                <Form.ControlLabel>Service Fee (Month)</Form.ControlLabel>
                                                <Form.Control name="serviceFeeMonth" value={formData.serviceFeeMonth} onChange={value => handleChange('serviceFeeMonth', value)} />
                                                {errors.serviceFeeMonth && <Form.HelpText style={{ color: 'red' }}>{errors.serviceFeeMonth}</Form.HelpText>}
                                            </Form.Group>
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={8}>
                                            <Form.Group controlId="stampingFee">
                                                <Form.ControlLabel>Stamping Fees (RM)</Form.ControlLabel>
                                                <Form.Control name="stampingFee" value={formData.stampingFee} onChange={value => handleChange('stampingFee', value)} />
                                                {errors.stampingFee && <Form.HelpText style={{ color: 'red' }}>{errors.stampingFee}</Form.HelpText>}
                                            </Form.Group>
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                    <FlexboxGrid style={{marginBottom: "40px"}}>
                                    <FlexboxGrid.Item colspan={8}>
                                            <Form.Group controlId="securityDeposit">
                                                <Form.ControlLabel>Security Deposit (RM)</Form.ControlLabel>
                                                <Form.Control name="securityDeposit" value={formData.securityDeposit} onChange={value => handleChange('securityDeposit', value)} />
                                                {errors.securityDeposit && <Form.HelpText style={{ color: 'red' }}>{errors.securityDeposit}</Form.HelpText>}
                                            </Form.Group>
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={8}>
                                            
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                   
                                    
                                    <div style={{marginBottom: "20px"}}>
                                        <h5>Document Details</h5>
                                    </div>
                                    <Form.Group controlId="rentalFormUrl">
                                        <Form.ControlLabel>Complete Rental Form</Form.ControlLabel>
                                        <input type="file" onChange={(event) => handleFileChange('rentalFormUrl', event.target.files[0])}/>
                                        {formData.rentalFormUrl && uploadedFiles.rentalFormUrl && (
                                            <div>
                                                <a href={formData.rentalFormUrl} target="_blank" rel="noopener noreferrer">{uploadedFiles.rentalFormUrl}</a>
                                                <Button onClick={() => removeFile('rentalFormUrl')} color="red" appearance="link">Remove</Button>
                                            </div>
                                        )}
                                        {!formData.rentalFormUrl && <div></div>}
                                        {errors.rentalFormUrl && <Form.HelpText style={{ color: 'red' }}>{errors.rentalFormUrl}</Form.HelpText>}
                                    </Form.Group>
                                    <Form.Group controlId="transactionSlip1Url">
                                        <Form.ControlLabel>Transaction Slip 1</Form.ControlLabel>
                                        <input type="file" onChange={(event) => handleFileChange('transactionSlip1Url', event.target.files[0])}/>
                                        {formData.transactionSlip1Url && uploadedFiles.transactionSlip1Url && (
                                            <div>
                                                <a href={formData.transactionSlip1Url} target="_blank" rel="noopener noreferrer">{uploadedFiles.transactionSlip1Url}</a>
                                                <Button onClick={() => removeFile('transactionSlip1Url')} color="red" appearance="link">Remove</Button>
                                            </div>
                                        )}
                                        {!formData.transactionSlip1Url && <div></div>}
                                        {errors.transactionSlip1Url && <Form.HelpText style={{ color: 'red' }}>{errors.transactionSlip1Url}</Form.HelpText>}
                                    </Form.Group>
                                    <Form.Group controlId="transactionSlip2Url">
                                        <Form.ControlLabel>Transaction Slip 2</Form.ControlLabel>
                                        <input type="file" onChange={(event) => handleFileChange('transactionSlip2Url', event.target.files[0])}/>
                                        {formData.transactionSlip2Url && uploadedFiles.transactionSlip2Url && (
                                            <div>
                                                <a href={formData.transactionSlip2Url} target="_blank" rel="noopener noreferrer">{uploadedFiles.transactionSlip2Url}</a>
                                                <Button onClick={() => removeFile('transactionSlip2Url')} color="red" appearance="link">Remove</Button>
                                            </div>
                                        )}
                                        {!formData.transactionSlip2Url && <div></div>}
                                    </Form.Group>
                                    <Form.Group controlId="tenancyAgreementUrl">
                                        <Form.ControlLabel>Tenancy Agreement</Form.ControlLabel>
                                        <input type="file" onChange={(event) => handleFileChange('tenancyAgreementUrl', event.target.files[0])}/>
                                        {formData.tenancyAgreementUrl && uploadedFiles.tenancyAgreementUrl && (
                                            <div>
                                                <a href={formData.tenancyAgreementUrl} target="_blank" rel="noopener noreferrer">{uploadedFiles.tenancyAgreementUrl}</a>
                                                <Button onClick={() => removeFile('tenancyAgreementUrl')} color="red" appearance="link">Remove</Button>
                                            </div>
                                        )}
                                        {!formData.tenancyAgreementUrl && <div></div>}
                                        {errors.tenancyAgreementUrl && <Form.HelpText style={{ color: 'red' }}>{errors.tenancyAgreementUrl}</Form.HelpText>}
                                    </Form.Group>
                                    <Form.Group controlId="stampingCertUrl">
                                        <Form.ControlLabel>Stamping Certificate</Form.ControlLabel>
                                        <input type="file" onChange={(event) => handleFileChange('stampingCertUrl', event.target.files[0])}/>
                                        {formData.stampingCertUrl && uploadedFiles.stampingCertUrl && (
                                            <div>
                                                <a href={formData.stampingCertUrl} target="_blank" rel="noopener noreferrer">{uploadedFiles.stampingCertUrl}</a>
                                                <Button onClick={() => removeFile('stampingCertUrl')} color="red" appearance="link">Remove</Button>
                                            </div>
                                        )}
                                        {!formData.stampingCertUrl && <div></div>}
                                        {errors.stampingCertUrl && <Form.HelpText style={{ color: 'red' }}>{errors.stampingCertUrl}</Form.HelpText>}
                                    </Form.Group>
                                    
                                    <div style={{paddingRight: "40px", marginBottom: "20px"}}>
                                        <Form.Group controlId="remark">
                                            <Form.ControlLabel>Remark</Form.ControlLabel>
                                            <Input as="textarea" rows={3} placeholder="Remarks..." name="remark" value={formData.remark} onChange={value => handleChange('remark', value)}/>
                                        </Form.Group>
                                    </div>
                                    <FlexboxGrid style={{marginBottom: "40px"}}>
                                        <FlexboxGrid.Item colspan={8}>
                                            <Form.Group controlId="status">
                                                <Form.ControlLabel>Status</Form.ControlLabel>
                                                <Dropdown title={formData.status} activeKey={formData.status} onSelect={value => handleChange('status', value)}>
                                                    {statusData.map(status => <Dropdown.Item key={status} eventKey={status}>{status}</Dropdown.Item>)}
                                                </Dropdown>
                                                {errors.status && <Form.HelpText style={{ color: 'red' }}>{errors.status}</Form.HelpText>}
                                            </Form.Group>
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                                       
                                    </FlexboxGrid>
         
                                    <FlexboxGrid style={{marginBottom: "20px"}}>
                                        <FlexboxGrid.Item colspan={8}>
                                            <h5>Agent Details</h5>
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={8}>
                                            <Button appearance="ghost" onClick={addAgent}>Add Agent</Button>
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                    {formData.salesAgent.map((agent, index) => (
                                        <FlexboxGrid key={index} style={{marginBottom: "20px"}}>
                                            <FlexboxGrid.Item colspan={8}>
                                                <Form.Group controlId={`salesAgent-${index}`}>
                                                    <Form.ControlLabel>Sales Agent</Form.ControlLabel>
                                                    <Dropdown title={agent || 'Select Agent'} activeKey={agent} onSelect={value => handleChange('salesAgent', value, index)}>
                                                        {users.map(user => <Dropdown.Item key={user.id} eventKey={user.name}>{user.name}</Dropdown.Item>)}
                                                    </Dropdown>
                                                </Form.Group>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={6}>
                                                <Form.Group controlId={`commissionShare-${index}`}>
                                                    <Form.ControlLabel>Commission Share</Form.ControlLabel>
                                                    <Form.Control name={`commissionShare-${index}`} value={formData.commissionShare[index]} onChange={value => handleNumberChange('commissionShare', value, index)} />
                                                    {errors.commissionShare && <Form.HelpText style={{ color: 'red' }}>{errors.commissionShare}</Form.HelpText>}
                                                </Form.Group>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={2}>
                                                <IconButton icon={<TrashIcon />} size="xs" appearance="ghost" color="red" onClick={() => removeAgent(index)} />
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    ))}
                                    
                                   
                                </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button appearance="primary" onClick={handleSubmit}>
                                        {editMode ? 'Update' : 'Add'}
                                    </Button>
                                    <Button onClick={handleClose} appearance="subtle">
                                        Cancel
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                        <div style={{height: "80px", justifyContent: "center", marginTop: "20px", marginRight: "20px", marginLeft: "20px"}}>
                            <Input 
                                placeholder="Search..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                                style={{ width: 300 }}
                            />
                        </div>
                    </div>
                    <Content style={{marginLeft: "20px"}}>
                        <Table
                            height={500}
                            data={filteredData.slice((activePage - 1) * 10, activePage * 10)}
                            onRowClick={handleRowClick}
                            style={{ marginRight: "40px", textAlign: "left"}}
                        >
                            <Column width={60} align="center">
                                <HeaderCell>No</HeaderCell>
                                <Cell dataKey="number" />
                            </Column>

                            <Column width={200}>
                                <HeaderCell>Landlord Name</HeaderCell>
                                <Cell dataKey="landlordName" />
                            </Column>

                            <Column width={200}>
                                <HeaderCell>Project Name</HeaderCell>
                                <Cell dataKey="project" />
                            </Column>
                            <Column width={100}>
                                <HeaderCell>Lot Number</HeaderCell>
                                <Cell dataKey="lotNumber" />
                            </Column>
                            <Column width={150}>
                                <HeaderCell>Rental Per Month</HeaderCell>
                                <Cell dataKey="rentalMonthly" />
                            </Column>
                            <Column width={200}>
                                <HeaderCell>Status</HeaderCell>
                                <Cell dataKey="status" />
                            </Column>
                            <Column width={200}>
                                <HeaderCell>Updated At</HeaderCell>
                                <Cell>{rowData => new Date(rowData.updatedAt.seconds * 1000).toLocaleDateString()}</Cell>
                            </Column>
                            <Column width={150} fixed="right">
                                <HeaderCell align='center'>Action</HeaderCell>
                                <Cell style={{ padding: '6px' }}>
                                    {rowData => (
                                        <div>
                                            {rowData.status !== 'Ready To Convert' && rowData.status !== 'Converted' && (
                                                <>
                                                    <Button appearance="link" style={{ marginLeft: '10px' }} onClick={(event) => handleEdit(event, rowData)}>
                                                        Edit
                                                    </Button>
                                                    <Button appearance="link" style={{ marginLeft: '10px' }} onClick={(event) => handleDeleteOpen(event, rowData.id)}>
                                                        Delete
                                                    </Button>
                                                </>
                                            )}
                                        </div>
                                    )}
                                </Cell>
                            </Column>
                        </Table>
                        <ViewModal viewModalOpen={viewModalOpen} handleCloseViewModal={handleCloseViewModal} selectedRowData={selectedRowData}/>
                        <DeleteModal message="Are you sure you want to delete this sales order?" handleDeleteClose={handleDeleteClose} deleteOpen={deleteOpen} handleDelete={handleDelete}/>
                        <Pagination total={filteredData.length} limit={10} activePage={activePage} onChangePage={setActivePage} style={{ marginLeft: "20px", marginTop: "20px", marginBottom: "40px"}}/>
                    </Content>
                </Container>
            </Container>
        </div>
    )
}

export default RentalsOrderComponents;
