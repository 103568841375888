import React, { useState, useContext, useEffect } from 'react';
import { Container, Content, Form, Button, Dropdown, useToaster, DatePicker, FlexboxGrid, Message, Table, Modal, Pagination, Input, InputGroup, IconButton } from 'rsuite';
import NavBar from '../../components/NavBar/NavBar';
import { addNumbersToData } from '../../Helpers/AddNumber';
import { AuthContext } from '../../context/authContext';
import { db } from '../../firebase';
import { doc, serverTimestamp, addDoc, collection, getDocs, updateDoc, deleteDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { reloadPage } from '../../Helpers/ReloadPage';
import ViewModal from './ViewModal';
import DeleteModal from './DeleteModal';
import TrashIcon from '@rsuite/icons/Trash';
import { useNavigate } from 'react-router-dom';
import CustomerHeader from '../../components/Header/Header';
import { PROJECTORDERS } from '../../constants/constants';

const { Column, HeaderCell, Cell } = Table;

const sourceData = [
    "Own",
    "Booth",
    "Company IProp 40%",
    "Company IProp 30%",
    "Company PropGuru 35%",
    "Company PropGuru 30%",
    "Walk-In",
    "Referral"
];

const statusData = [
    "Pending",
    "Cancel",
    "Ready To Convert",
    "Not Ready To Convert",
];

const SalesOrderComponent = ({ expand }) => {
    const { currentUser } = useContext(AuthContext);
    const [activePage, setActivePage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [open, setOpen] = useState(false);
    const [errors, setErrors] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [editId, setEditId] = useState('');
    const [backdrop, setBackdrop] = useState('static');
    const toaster = useToaster();
    const [data, setData] = useState([]);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [projectData, setProjectData] = useState([]);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();

    const handleCloseViewModal = () => {
        setSelectedRowData(null);
        setViewModalOpen(false);
    };

    const projectFormData = {
        lotNumber: "",
        project: projectData[0],
        bookDate: "",
        purchaserName: "",
        spaPrice: "",
        nettPrice: "",
        rebate: "",
        spaDate: "",
        status: statusData[0],
        source: sourceData[0],
        purchaserContact: "",
        referralName: "",
        loanMargin: "",
        agreementSignDate: "",
        remark: "",
        bookingFormUrl: "",
        spaCopyUrl: "",
        agreementSignedUrl: "",
        cancellationFormUrl: "",
        salesAgent: [currentUser.name],
        commissionShare: ["100"]
    }

    const [formData, setFormData] = useState(projectFormData);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "projects"));
                const projects = querySnapshot.docs.map(doc => doc.data().name);
                setProjectData(projects);

                // Assign the first project name to the formData.project
                if (projects.length > 0) {
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        project: projects[0]
                    }));
                }
            } catch (error) {
                console.error("Error fetching projects: ", error);
            }
        };

        
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "salesOrders"));
                const fetchedData = [];
                querySnapshot.forEach(doc => {
                    fetchedData.push({ id: doc.id, ...doc.data() });
                });
                const filteredData = fetchedData.filter(data => data.uid === currentUser.uid);
                const numberedData = addNumbersToData(filteredData);
                
                setData(numberedData);
                setFilteredData(numberedData);
                
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        const fetchUsers = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "users"));
                const usersData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setUsers(usersData);
            } catch (error) {
                console.error("Error fetching users: ", error);
            }
        };

        fetchProjects();
        fetchData();
        fetchUsers();
    }, [currentUser.uid]);

    const validateForm = () => {
        const newErrors = {};

        if (!formData.lotNumber) newErrors.lotNumber = "Lot Number is required";
        if (!formData.bookDate) newErrors.bookDate = "Booking Date is required";
        if (!formData.purchaserName) newErrors.purchaserName = "Purchaser Name is required";
        if (!formData.spaPrice) newErrors.spaPrice = "SPA Price is required";
        if (!formData.nettPrice) newErrors.nettPrice = "Nett Price is required";
        if (!formData.rebate) newErrors.rebate = "Rebate is required";
        if (!formData.source) newErrors.source = "Source is required";
        if (!formData.status) newErrors.status = "Status is required";
        if (!formData.purchaserContact) newErrors.purchaserContact = "Purchaser Contact is required";
        if (!formData.bookingFormUrl) newErrors.bookingFormUrl = "Booking Form URL is required";

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async () => {
        if (!validateForm()) {
            return;
        }

        try {
            if (editMode) {
                // Update existing sales order
                const salesOrderRef = doc(db, "salesOrders", editId);
                await updateDoc(salesOrderRef, {
                    ...formData,
                    updatedAt: serverTimestamp(),
                    uid: currentUser.uid
                });
                toaster.push(<Message type='success'>Sales order updated successfully!</Message>);
            } else {
                // Add new sales order
                await addDoc(collection(db, "salesOrders"), {
                    ...formData,
                    createdAt: serverTimestamp(),
                    uid: currentUser.uid
                });
                toaster.push(<Message type='success'>New sales order added successfully!</Message>);
            }
            handleClose();
            reloadPage();
            setFormData(projectFormData);
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };

    const handleChange = (key, value, index) => {
        let updatedFormData = { ...formData, [key]: value };

        if (key === 'spaPrice' || key === 'rebate') {
            const spaPrice = parseFloat(updatedFormData.spaPrice) || 0;
            const rebate = parseFloat(updatedFormData.rebate) || 0;
            updatedFormData.nettPrice = spaPrice - rebate;
        }

        if (key === 'salesAgent' || key === 'commissionShare') {
            const updatedArray = [...formData[key]];
            updatedArray[index] = value;
            updatedFormData[key] = updatedArray;
        }

        setFormData(updatedFormData);
    };

    const handleNumberChange = (key, value, index) => {
        if (/^\d*\.?\d*$/.test(value)) {
            if ((key === 'loanMargin' || key === 'commissionShare') && Number(value) > 100) {
                toaster.push(<Message type='error'>Value cannot exceed 100</Message>);
            } else {
                handleChange(key, value, index);
            }
        }
    };

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
      setOpen(false);
      setEditMode(false);
      setEditId('');
      setFormData(projectFormData);
      setErrors({});
    };

    const handleSearchChange = (value) => {
        setSearchQuery(value);
        if (value === "") {
            setFilteredData(data);
        } else {
            const lowercasedFilter = value.toLowerCase();
            const filtered = data.filter(item => {
                return (
                    item.lotNumber.toLowerCase().includes(lowercasedFilter) ||
                    item.purchaserName.toLowerCase().includes(lowercasedFilter) ||
                    item.project.toLowerCase().includes(lowercasedFilter) ||
                    item.status.toLowerCase().includes(lowercasedFilter)
                );
            });
            setFilteredData(filtered);
        }
    };

    const addAgent = () => {
        setFormData(prevFormData => ({
            ...prevFormData,
            salesAgent: [...prevFormData.salesAgent, ""],
            commissionShare: [...prevFormData.commissionShare, ""]
        }));
    };

    const removeAgent = (index) => {
        const updatedAgents = formData.salesAgent.filter((_, i) => i !== index);
        const updatedShares = formData.commissionShare.filter((_, i) => i !== index);
        setFormData(prevFormData => ({
            ...prevFormData,
            salesAgent: updatedAgents,
            commissionShare: updatedShares
        }));
    };

    const handleFileChange = (key, file) => {
        const storage = getStorage();
        const storageRef = ref(storage, `documents/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on('state_changed', 
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
            }, 
            (error) => {
                // Handle unsuccessful uploads
                console.error("Error uploading file: ", error);
            }, 
            () => {
                // Handle successful uploads on complete
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        [key]: downloadURL
                    }));
                });
            }
        );
    };

    const removeFile = (key) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [key]: ""
        }));
    };

    const handleEdit = (event, rowData) => {
        event.stopPropagation();
        setFormData({
            ...rowData,
            bookDate: new Date(rowData.bookDate.seconds * 1000),
            spaDate: rowData.spaDate ? new Date(rowData.spaDate.seconds * 1000) : "",
            agreementSignDate: rowData.agreementSignDate ? new Date(rowData.agreementSignDate.seconds * 1000) : ""
        });
        setEditId(rowData.id);
        setEditMode(true);
        handleOpen();
    };

    const handleDelete = async () => {
        try {
            await deleteDoc(doc(db, "salesOrders", deleteId));
            handleDeleteClose();
            toaster.push(<Message type='success'>Sales order deleted successfully!</Message>);
            reloadPage();
        } catch (e) {
            console.log(e);
        }
    };
    
    const handleDeleteOpen = (event, id) => {
        event.stopPropagation();
        setDeleteId(id);
        setDeleteOpen(true);
    };
    
    const handleDeleteClose = () => {
        setDeleteOpen(false);
        setDeleteId('');
    };

    const handleRowClick = (rowData) => {
        navigate(`/view-sales-order/${rowData.id}`);
    };

    return (
        <div className='salesOrder' style={{ flex: 1 }}>
                <Modal backdrop={backdrop} keyboard={false} open={open} onClose={handleClose}>
                    <Modal.Header>
                        <Modal.Title>{editMode ? 'Edit Sales' : 'Add New Sales'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form fluid>
                            <div style={{ marginBottom: "20px" }}>
                                <h5>Property Details</h5>
                            </div>
                            <FlexboxGrid style={{ marginBottom: "20px" }}>
                                <FlexboxGrid.Item colspan={8}>
                                    <Form.Group controlId="lotNumber">
                                        <Form.ControlLabel>Lot Number</Form.ControlLabel>
                                        <Form.Control name="lotNumber" value={formData.lotNumber} onChange={value => handleChange('lotNumber', value)} />
                                        {errors.lotNumber && <Form.HelpText style={{ color: 'red' }}>{errors.lotNumber}</Form.HelpText>}
                                    </Form.Group>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={6}>
                                    <Form.Group controlId="project">
                                        <Form.ControlLabel>Project</Form.ControlLabel>
                                        <Dropdown title={formData.project} activeKey={formData.project} onSelect={value => handleChange('project', value)}>
                                            {projectData.map(project => <Dropdown.Item key={project} eventKey={project}>{project}</Dropdown.Item>)}
                                        </Dropdown>
                                    </Form.Group>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>

                            <FlexboxGrid style={{ marginBottom: "20px" }}>
                                <FlexboxGrid.Item colspan={8}>
                                    <Form.Group controlId="spaPrice">
                                        <Form.ControlLabel>SPA Price</Form.ControlLabel>
                                        <Form.Control name="spaPrice" value={formData.spaPrice} onChange={value => handleNumberChange('spaPrice', value)} />
                                        {errors.spaPrice && <Form.HelpText style={{ color: 'red' }}>{errors.spaPrice}</Form.HelpText>}
                                    </Form.Group>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={8}>
                                    <Form.Group controlId="rebate">
                                        <Form.ControlLabel>Rebate</Form.ControlLabel>
                                        <Form.Control name="rebate" value={formData.rebate} onChange={value => handleNumberChange('rebate', value)} />
                                        {errors.rebate && <Form.HelpText style={{ color: 'red' }}>{errors.rebate}</Form.HelpText>}
                                    </Form.Group>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>

                            <FlexboxGrid style={{ marginBottom: "20px" }}>
                                <FlexboxGrid.Item colspan={8}>
                                    <Form.Group controlId="nettPrice">
                                        <Form.ControlLabel>Nett Price</Form.ControlLabel>
                                        <Form.Control name="nettPrice" value={formData.nettPrice} readOnly />
                                        {errors.nettPrice && <Form.HelpText style={{ color: 'red' }}>{errors.nettPrice}</Form.HelpText>}
                                    </Form.Group>
                                </FlexboxGrid.Item>

                                <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={8}>
                                    <Form.Group controlId="loanMargin">
                                        <Form.ControlLabel>Loan Margin (%)</Form.ControlLabel>
                                        <Form.Control name="loanMargin" value={formData.loanMargin} onChange={value => handleNumberChange('loanMargin', value)} />
                                        {errors.loanMargin && <Form.HelpText style={{ color: 'red' }}>{errors.loanMargin}</Form.HelpText>}
                                    </Form.Group>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>

                            <Form.Group controlId="bookingFormUrl" style={{ marginBottom: "40px" }}>
                                <Form.ControlLabel>Booking Form</Form.ControlLabel>
                                <input type="file" onChange={(event) => handleFileChange('bookingFormUrl', event.target.files[0])} />
                                {formData.bookingFormUrl && (
                                    <div>
                                        <a href={formData.bookingFormUrl} target="_blank" rel="noopener noreferrer">View File</a>
                                        <Button onClick={() => removeFile('bookingFormUrl')} color="red" appearance="link">Remove</Button>
                                    </div>
                                )}
                                {errors.bookingFormUrl && <Form.HelpText style={{ color: 'red' }}>{errors.bookingFormUrl}</Form.HelpText>}
                            </Form.Group>
                            <div style={{ marginBottom: "20px" }}>
                                <h5>Purchaser Details</h5>
                            </div>
                            <FlexboxGrid style={{ marginBottom: "20px" }}>
                                <FlexboxGrid.Item colspan={8}>
                                    <Form.Group controlId="purchaserName">
                                        <Form.ControlLabel>Purchaser Name</Form.ControlLabel>
                                        <Form.Control name="purchaserName" value={formData.purchaserName} onChange={value => handleChange('purchaserName', value)} />
                                        {errors.purchaserName && <Form.HelpText style={{ color: 'red' }}>{errors.purchaserName}</Form.HelpText>}
                                    </Form.Group>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={8}>
                                    <Form.Group controlId="purchaserContact">
                                        <Form.ControlLabel>Purchaser Contact</Form.ControlLabel>
                                        <Form.Control name="purchaserContact" value={formData.purchaserContact} onChange={value => handleChange('purchaserContact', value)} />
                                        {errors.purchaserContact && <Form.HelpText style={{ color: 'red' }}>{errors.purchaserContact}</Form.HelpText>}
                                    </Form.Group>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                            <FlexboxGrid style={{ marginBottom: "40px" }}>
                                <FlexboxGrid.Item colspan={8}>
                                    <Form.Group controlId="bookDate">
                                        <Form.ControlLabel>Booking Date</Form.ControlLabel>
                                        <DatePicker oneTap value={formData.bookDate} onChange={value => handleChange('bookDate', value)} style={{ width: 224 }} />
                                        {errors.bookDate && <Form.HelpText style={{ color: 'red' }}>{errors.bookDate}</Form.HelpText>}
                                    </Form.Group>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={8}>
                                    <Form.Group controlId="status">
                                        <Form.ControlLabel>Status</Form.ControlLabel>
                                        <Dropdown title={formData.status} activeKey={formData.status} onSelect={value => handleChange('status', value)}>
                                            {statusData.map(status => <Dropdown.Item key={status} eventKey={status}>{status}</Dropdown.Item>)}
                                        </Dropdown>
                                        {errors.status && <Form.HelpText style={{ color: 'red' }}>{errors.status}</Form.HelpText>}
                                    </Form.Group>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>

                            <div style={{ marginBottom: "20px" }}>
                                <h5>Document Details</h5>
                            </div>

                            <FlexboxGrid style={{ marginBottom: "20px" }}>
                                <FlexboxGrid.Item colspan={8}>
                                    <Form.Group controlId="source" >
                                        <Form.ControlLabel>Source</Form.ControlLabel>
                                        <Dropdown title={formData.source} activeKey={formData.source} onSelect={value => handleChange('source', value)}>
                                            {sourceData.map(source => <Dropdown.Item key={source} eventKey={source}>{source}</Dropdown.Item>)}
                                        </Dropdown>
                                        {errors.source && <Form.HelpText style={{ color: 'red' }}>{errors.source}</Form.HelpText>}
                                    </Form.Group>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={8}>
                                    <Form.Group controlId="referralName">
                                        <Form.ControlLabel>Referral Name</Form.ControlLabel>
                                        <Form.Control name="referralName" value={formData.referralName} onChange={value => handleChange('referralName', value)} />
                                    </Form.Group>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>

                            <div style={{ paddingRight: "40px" }}>
                                <Form.Group controlId="remark" style={{ marginBottom: "20px" }}>
                                    <Form.ControlLabel>Remark</Form.ControlLabel>
                                    <Input as="textarea" rows={3} placeholder="Remarks..." name="remark" value={formData.remark} onChange={value => handleChange('remark', value)} />
                                </Form.Group>
                            </div>

                            <FlexboxGrid style={{ marginBottom: "20px" }}>
                                <FlexboxGrid.Item colspan={8}>
                                    <Form.Group controlId="spaCopyUrl">
                                        <Form.ControlLabel>Completed SPA Copy</Form.ControlLabel>
                                        <input type="file" onChange={(event) => handleFileChange('spaCopyUrl', event.target.files[0])} />
                                        {formData.spaCopyUrl && (
                                            <div>
                                                <a href={formData.spaCopyUrl} target="_blank" rel="noopener noreferrer">View File</a>
                                                <Button onClick={() => removeFile('spaCopyUrl')} color="red" appearance="link">Remove</Button>
                                            </div>
                                        )}
                                    </Form.Group>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={8}>
                                    <Form.Group controlId="spaDate">
                                        <Form.ControlLabel>SPA Date</Form.ControlLabel>
                                        <DatePicker oneTap name="spaDate" value={formData.spaDate} onChange={value => handleChange('spaDate', value)} />
                                    </Form.Group>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>

                            <FlexboxGrid style={{ marginBottom: "20px" }}>
                                <FlexboxGrid.Item colspan={8}>
                                    <Form.Group controlId="agreementSignedUrl">
                                        <Form.ControlLabel>LO/LA Agreement Signed</Form.ControlLabel>
                                        <input type="file" onChange={(event) => handleFileChange('agreementSignedUrl', event.target.files[0])} />
                                        {formData.agreementSignedUrl && (
                                            <div>
                                                <a href={formData.agreementSignedUrl} target="_blank" rel="noopener noreferrer">View File</a>
                                                <Button onClick={() => removeFile('agreementSignedUrl')} color="red" appearance="link">Remove</Button>
                                            </div>
                                        )}
                                    </Form.Group>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={8}>
                                    <Form.Group controlId="agreementSignDate">
                                        <Form.ControlLabel>Agreement Sign</Form.ControlLabel>
                                        <DatePicker oneTap name="agreementSignDate" value={formData.agreementSignDate} onChange={value => handleChange('agreementSignDate', value)} />
                                    </Form.Group>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>

                            <Form.Group controlId="cancellationFormUrl" style={{ marginBottom: "40px" }}>
                                <Form.ControlLabel>Cancellation Form</Form.ControlLabel>
                                <input type="file" onChange={(event) => handleFileChange('cancellationFormUrl', event.target.files[0])} />
                                {formData.cancellationFormUrl && (
                                    <div>
                                        <a href={formData.cancellationFormUrl} target="_blank" rel="noopener noreferrer">View File</a>
                                        <Button onClick={() => removeFile('cancellationFormUrl')} color="red" appearance="link">Remove</Button>
                                    </div>
                                )}
                            </Form.Group>
                            <FlexboxGrid style={{ marginBottom: "20px" }}>
                                <FlexboxGrid.Item colspan={8}>
                                    <h5>Agent Details</h5>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={8}>
                                    <Button appearance="ghost" onClick={addAgent}>Add Agent</Button>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                            {formData.salesAgent.map((agent, index) => (
                                <FlexboxGrid key={index} style={{ marginBottom: "20px" }}>
                                    <FlexboxGrid.Item colspan={8}>
                                        <Form.Group controlId={`salesAgent-${index}`}>
                                            <Form.ControlLabel>Sales Agent</Form.ControlLabel>
                                            <Dropdown title={agent || "Select Agent"} activeKey={agent} onSelect={value => handleChange('salesAgent', value, index)}>
                                                {users.map(user => <Dropdown.Item key={user.id} eventKey={user.name}>{user.name}</Dropdown.Item>)}
                                            </Dropdown>
                                        </Form.Group>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={6}></FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={6}>
                                        <Form.Group controlId={`commissionShare-${index}`}>
                                            <Form.ControlLabel>Commission Share</Form.ControlLabel>
                                            <Form.Control name={`commissionShare-${index}`} value={formData.commissionShare[index]} onChange={value => handleNumberChange('commissionShare', value, index)} />
                                            {errors.commissionShare && <Form.HelpText style={{ color: 'red' }}>{errors.commissionShare}</Form.HelpText>}
                                        </Form.Group>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={2}>
                                        <IconButton icon={<TrashIcon />} size="xs" appearance="ghost" color="red" onClick={() => removeAgent(index)} />
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            ))}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button appearance="primary" onClick={handleSubmit}>
                            {editMode ? 'Edit' : 'Add'}
                        </Button>
                        <Button onClick={handleClose} appearance="subtle">
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Container style={{ marginLeft: expand ? '260px' : '56px' }}>
                    <NavBar/>
                    <CustomerHeader title={PROJECTORDERS}/>
                    <Content style={{marginLeft: "20px",}}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '20px' }}>
                            <div style={{ textAlign: 'left', marginTop: "20px"}}>
                                <Button onClick={handleOpen} appearance="primary">
                                    New Sales
                                </Button>
                            </div>
                            <div style={{ height: "80px", justifyContent: "center", marginTop: "20px", marginRight: "20px", marginLeft: "20px" }}>
                                <Input 
                                    placeholder="Search..."
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    style={{ width: 300 }}
                                />
                            </div>
                        </div>
                        <Table
                            height={400}
                            data={filteredData.slice((activePage - 1) * 10, activePage * 10)}
                            onRowClick={handleRowClick}
                            style={{ marginRight: "40px", textAlign: "left"}}
                        >
                            <Column width={60} align="center">
                                <HeaderCell>No</HeaderCell>
                                <Cell dataKey="number" />
                            </Column>

                            <Column width={150}>
                                <HeaderCell>Lot No.</HeaderCell>
                                <Cell dataKey="lotNumber" />
                            </Column>

                            <Column width={200}>
                                <HeaderCell>Purchaser Name</HeaderCell>
                                <Cell dataKey="purchaserName" />
                            </Column>
                            <Column width={200}>
                                <HeaderCell>Project</HeaderCell>
                                <Cell dataKey="project" />
                            </Column>
                            <Column width={200}>
                                <HeaderCell>Status</HeaderCell>
                                <Cell dataKey="status" />
                            </Column>
                            <Column width={100}>
                                <HeaderCell>Booking Date</HeaderCell>
                                <Cell>{rowData => new Date(rowData.bookDate.seconds * 1000).toLocaleDateString()}</Cell>
                            </Column>
                            <Column width={150} fixed="right">
                                <HeaderCell align='center'>Action</HeaderCell>
                                <Cell style={{ padding: '6px' }}>
                                    {rowData => (
                                        <div>
                                            {(rowData.status !== 'Ready To Convert' && rowData.status !== 'Converted' && rowData.status !== 'Claim Approved') && (
                                                <>
                                                    <Button appearance="link" style={{ marginLeft: '10px' }} onClick={(event) => handleEdit(event, rowData)}>
                                                        Edit
                                                    </Button>
                                                    <Button appearance="link" style={{ marginLeft: '10px' }} onClick={(event) => handleDeleteOpen(event, rowData.id)}>
                                                        Delete
                                                    </Button>
                                                </>
                                            )}
                                        </div>
                                    )}
                                </Cell>
                            </Column>
                        </Table>
                        <ViewModal viewModalOpen={viewModalOpen} handleCloseViewModal={handleCloseViewModal} selectedRowData={selectedRowData} />
                        <DeleteModal message="Are you sure you want to delete this sales order?" handleDeleteClose={handleDeleteClose} deleteOpen={deleteOpen} handleDelete={handleDelete} />
                        <Pagination total={filteredData.length} limit={10} activePage={activePage} onChangePage={setActivePage} style={{ marginLeft: "20px", marginTop: "20px", marginBottom: "40px"}}/>
                    </Content>
                </Container>
        </div>
    )
}

export default SalesOrderComponent;
