import React, { useState, useEffect, useContext } from 'react';
import { Container, Content, Form, Button, FlexboxGrid, Input, Message, useToaster, Modal, Steps } from 'rsuite';
import { useParams, useLocation } from 'react-router-dom';
import SideBar1 from '../../components/SideBar1/SideBar1';
import NavBar from '../../components/NavBar/NavBar';
import { db } from '../../firebase';
import { doc, getDoc, getDocs, collection, setDoc, query, where } from "firebase/firestore";
import ViewDataComponent from '../../components/ViewDataComponent/ViewDataComponent';
import CustomerHeader from '../../components/Header/Header';
import { AuthContext } from '../../context/authContext';
import { reloadPage } from '../../Helpers/ReloadPage';

const ViewSalesOrder = () => {
    const [expand, setExpand] = useState(true);

    const handleToggleExpand = () => {
        setExpand(!expand);
    };

    return (
        <div style={{ display: 'flex' }}>
            <SideBar1 expand={expand} onToggleExpand={handleToggleExpand} />
            <ViewSalesOrderData expand={expand} />
        </div>
    );
}

export default ViewSalesOrder;

const ViewSalesOrderData = ({ expand }) => {
    const { id } = useParams();
    const { currentUser } = useContext(AuthContext);
    const [saleData, setSaleData] = useState(null);
    const [usersData, setUsersData] = useState(null);
    const [submitBy, setSubmitBy] = useState("");
    const [formData, setFormData] = useState({});
    const [claimStatus, setClaimStatus] = useState(null);
    const [isClaimSubmitted, setIsClaimSubmitted] = useState(false);
    const toaster = useToaster();
    const colSpanTitle = 3;
    const location = useLocation();
    const title = location.state?.from === 'Claims' ? 'Sales Claim Details' : 'View Sales Order';
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const downloadFile = (url) => {
        window.open(url, '_blank');
    };

    useEffect(() => {
        const fetchSalesOrderData = async () => {
            try {
                const usersSnapshot = await getDocs(collection(db, "users"));
                const usersData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setUsersData(usersData);

                const saleDoc = await getDoc(doc(db, "salesOrders", id));
                if (saleDoc.exists()) {
                    const data = saleDoc.data();
                    setSaleData(data);
                    setFormData(data); // Initialize form data with fetched data
                    if (data.uid) {
                        const userDoc = await getDoc(doc(db, "users", data.uid));
                        if (userDoc.exists()) {
                            setSubmitBy(userDoc.data().name);
                        }
                    }
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error("Error fetching claim data: ", error);
            }
        };

        fetchSalesOrderData();
    }, [id, toaster]);

    useEffect(() => {
        const fetchClaimData = async () => {
            try {
                const q = query(collection(db, "claims"), where("docId", "==", id), where("uid", "==", currentUser.uid));
                const claimSnapshot = await getDocs(q);
                if (!claimSnapshot.empty) {
                    const claimData = claimSnapshot.docs[0].data();
                    setClaimStatus(claimData.status);
                    setIsClaimSubmitted(true); // Set claim submitted to true if there's already a claim
                }
            } catch (error) {
                console.error("Error fetching claim data: ", error);
            }
        };

        if (location.state?.from === 'Claims') {
            fetchClaimData();
        }
    }, [id, currentUser.uid, location.state?.from]);

    const handleChange = (key, value, index) => {
        if (key === 'salesAgent' || key === 'commissionShare') {
            const updatedArray = [...formData[key]];
            updatedArray[index] = value;
            setFormData(prevFormData => ({
                ...prevFormData,
                [key]: updatedArray
            }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [key]: value
            }));
        }
    };

    const calculateProjectCommission = (projectsOrder, projectsData, usersData, currentUserName) => {
        const { nettPrice, salesAgent, commissionShare, project } = projectsOrder;
        const projectData = projectsData.find(proj => proj.name === project);
        if (!projectData) return '0.00';

        const totalCommission = nettPrice * (projectData.commission / 100);

        const commissions = salesAgent.map((agent, index) => {
            const user = usersData.find(u => u.name === agent);
            const agentTier = user ? user.subsalesTier : 0;
            const agentShare = commissionShare[index] / 100;
            return {
                agent: agent,
                commission: totalCommission * (agentTier / 100) * agentShare
            };
        });

        const currentUserCommission = commissions.find(commission => commission.agent === currentUserName);
        return currentUserCommission ? currentUserCommission.commission.toFixed(2) : '0.00';
    };

    const handleClaimSubmit = async () => {
        try {
            const projectsSnapshot = await getDocs(collection(db, "projects"));
            const projectsData = projectsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            const calculatedCommission = calculateProjectCommission(formData, projectsData, usersData, currentUser.name);
            const claimData = {
                docId: id,
                uid: currentUser.uid,
                salesAgent: formData.salesAgent,
                commissionShare: formData.commissionShare,
                category: "Project",
                status: 1,
                calculatedCommission: calculatedCommission
            };
            await setDoc(doc(collection(db, "claims")), claimData);
            toaster.push(<Message type="success" closable>Claim submitted successfully!</Message>);
            setOpen(false);
            reloadPage();
            setIsClaimSubmitted(true); // Set claim submitted to true after submission
        } catch (error) {
            console.error("Error submitting claim: ", error);
            toaster.push(<Message type="error" closable>Error submitting claim!</Message>);
        }
    };

    const renderSteps = () => {
        if (!claimStatus) return null;

        let currentStep = 0;
        switch (claimStatus) {
            case 1:
                currentStep = 1;
                break;
            case 2:
                currentStep = 2;
                break;
            default:
                break;
        }

        return (
            <Steps current={currentStep} style={{ marginBottom: "40px", marginTop: "20px" }}>
                <Steps.Item title="Submitted" />
                <Steps.Item title="In Progress" />
                <Steps.Item title="Completed" />
            </Steps>
        );
    };

    if (!saleData) {
        return (
            <Container style={{ marginLeft: expand ? '260px' : '56px' }}>
                <NavBar />
                <Content style={{ padding: "20px", textAlign: "left" }}>
                    <Message type="info">Loading claim details...</Message>
                </Content>
            </Container>
        );
    }

    return (
        <Container style={{ marginLeft: expand ? '260px' : '56px' }}>
            <NavBar title={title} />
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <CustomerHeader title={title} />
                {location.state?.from === 'Claims' && !isClaimSubmitted && (
                    <Button appearance="primary" style={{ marginTop: "10px", marginRight: "20px" }} onClick={handleOpen}>
                        Claim
                    </Button>
                )}
            </div>
            <Content style={{ padding: "20px", textAlign: "left" }}>
                {renderSteps()}
                <Form fluid style={{ marginLeft: "20px" }}>
                    <h5 style={{ marginBottom: "30px" }}>Sale Order Information</h5>
                    <FlexboxGrid style={{ marginBottom: "10px" }} align="middle">
                        <FlexboxGrid.Item colspan={colSpanTitle}>
                            <Form.ControlLabel>Submit By</Form.ControlLabel>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={8}>
                            <Form.Control readOnly name="submitBy" value={submitBy} />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <ViewDataComponent title="Purchaser Name" data={formData.purchaserName} />
                    <ViewDataComponent title="Purchaser Contact" data={formData.purchaserContact} />
                    <ViewDataComponent title="Project Name" data={formData.project} />
                    <ViewDataComponent title="Lot No." data={formData.lotNumber} />
                    <ViewDataComponent title="Status" data={formData.status} />
                    <ViewDataComponent title="Loan Margin (%)" data={formData.loanMargin} />
                    <ViewDataComponent title="SPA Price (RM)" data={formData.spaPrice} />
                    <ViewDataComponent title="Nett Price (RM)" data={formData.nettPrice} />
                    <ViewDataComponent title="Source" data={formData.source} />
                    <ViewDataComponent title="Referral Name" data={formData.referralName === "" ? "N/A" : formData.referralName} />
                    <FlexboxGrid style={{ marginBottom: "20px" }} align="middle">
                        <FlexboxGrid.Item colspan={colSpanTitle}>
                            <Form.ControlLabel>Booking Form</Form.ControlLabel>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={8}>
                            {formData.bookingFormUrl ? (
                                <Button
                                    appearance="link"
                                    onClick={() => downloadFile(formData.bookingFormUrl)}
                                    style={{ marginLeft: '10px' }}
                                >
                                    Download
                                </Button>
                            ) : (
                                <div>N/A</div>
                            )}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>

                    <FlexboxGrid style={{ marginBottom: "20px" }} align="middle">
                        <FlexboxGrid.Item colspan={colSpanTitle}>
                            <Form.ControlLabel>SPA Copy</Form.ControlLabel>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={8}>
                            {formData.spaCopyUrl ? (
                                <Button
                                    appearance="link"
                                    onClick={() => downloadFile(formData.spaCopyUrl)}
                                    style={{ marginLeft: '10px' }}
                                >
                                    Download
                                </Button>
                            ) : (
                                <div>N/A</div>
                            )}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <ViewDataComponent title="SPA Date" data={formData.spaDate && new Date(formData.spaDate.seconds * 1000).toLocaleDateString()} />

                    <FlexboxGrid style={{ marginBottom: "20px" }} align="middle">
                        <FlexboxGrid.Item colspan={colSpanTitle}>
                            <Form.ControlLabel>LO/LA Agreement Signed</Form.ControlLabel>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={8}>
                            {formData.agreementSignedUrl ? (
                                <Button
                                    appearance="link"
                                    onClick={() => downloadFile(formData.agreementSignedUrl)}
                                    style={{ marginLeft: '10px' }}
                                >
                                    Download
                                </Button>
                            ) : (
                                <div>N/A</div>
                            )}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    <ViewDataComponent title="LO/LA Agreement Signed Date" data={formData.agreementSignDate && new Date(formData.agreementSignDate.seconds * 1000).toLocaleDateString()} />

                    <FlexboxGrid style={{ marginBottom: "20px" }} align="middle">
                        <FlexboxGrid.Item colspan={colSpanTitle}>
                            <Form.ControlLabel>Cancellation Form</Form.ControlLabel>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={8}>
                            {formData.cancellationFormUrl ? (
                                <Button
                                    appearance="link"
                                    onClick={() => downloadFile(formData.cancellationFormUrl)}
                                    style={{ marginLeft: '10px' }}
                                >
                                    Download
                                </Button>
                            ) : (
                                <div style={{ paddingLeft: "20px" }}>N/A</div>
                            )}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>

                    <FlexboxGrid style={{ marginBottom: "30px" }} align="middle">
                        <FlexboxGrid.Item colspan={colSpanTitle}>
                            <Form.ControlLabel>Remark</Form.ControlLabel>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={8}>
                            <Input as="textarea" readOnly rows={3} placeholder="Remarks..." name="remark" value={formData.remark} onChange={value => handleChange('remark', value)} />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>

                    <h5 style={{ marginBottom: "30px" }}>Agent Details</h5>

                    {formData.salesAgent.map((agent, index) => (
                        <FlexboxGrid key={index} style={{ marginBottom: "20px" }} align="middle">
                            <FlexboxGrid.Item colspan={3}>
                                <Form.Group controlId={`salesAgent-${index}`}>
                                    <Form.ControlLabel>Sales Agent</Form.ControlLabel>
                                    <Form.ControlLabel>{formData.salesAgent[index]}</Form.ControlLabel>
                                </Form.Group>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={8}>
                                <Form.ControlLabel>Shared Commission</Form.ControlLabel>
                                <Form.Group controlId={`commissionShare-${index}`}>
                                    <Form.Control readOnly name={`commissionShare-${index}`} value={formData.commissionShare[index]} />
                                </Form.Group>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    ))}
                </Form>
            </Content>

            <Modal open={open} onClose={handleClose}>
                <Modal.Header>
                    <Modal.Title>Confirm Claim</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to submit this claim?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClaimSubmit} appearance="primary">Confirm</Button>
                    <Button onClick={handleClose} appearance="ghost">Cancel</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};
