import { Button, Container, Form, useToaster, Message, Content} from 'rsuite';
import NavBar from '../../components/NavBar/NavBar';
import { AuthContext } from "../../context/authContext";
import { useContext, useState } from "react";
import { auth, db } from "../../firebase"; // Import your Firebase instance
import { updatePassword, EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore"; 
import { CHANGEPASSWORD } from '../../constants/constants';


const AccountComponent = ({expand}) => {
  const { currentUser } = useContext(AuthContext);
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const toaster = useToaster();

  const handlenewPassword1Change = (value) => {
    setNewPassword1(value);
  };

  const handlenewPassword2Change = (value) => {
    setNewPassword2(value);
  };

  const handleSave = async () => {
    // Validate inputs
    if (newPassword1 !== newPassword2) {
      toaster.push(<Message type="error">New Passwords do not match.</Message>);
      return;
    }

    if (newPassword1 === "" || newPassword2 === "") {
      toaster.push(<Message type="error">New Password cannot be empty.</Message>);
      return;
    }

    try {
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(currentUser.email, currentUser.password);

      // Reauthenticate user
      await reauthenticateWithCredential(user, credential);

      // Update password in Firebase Auth
      await updatePassword(user, newPassword1);

      // Update password in Firestore (if you are storing the password, which is not recommended for security reasons)
      const userDocRef = doc(db, "users", currentUser.uid);
      await updateDoc(userDocRef, {
        password: newPassword1,
      });

      toaster.push(<Message type="success" duration={3000}>Password updated successfully.</Message>);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toaster.push(<Message type="error" duration={3000}>Failed to update password: {error.message}</Message>);
    }
  };

  return (
    <div className='ClaimRequests' style={{ flex: 1 }}>
    <Container style={{ marginLeft: expand ? '260px' : '56px' }}>
        <Container>
            <NavBar title="Project Claim Requests" />
            <Content>
                <div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                      <h3 style={{textAlign: "left", marginTop: "10px", marginLeft: "20px"}}>{CHANGEPASSWORD}</h3>
                      {
                        newPassword1 && 
                        <Button 
                          appearance="primary" 
                          onClick={handleSave} 
                          style={{marginRight: "20px", width: "80px", height: "40px", marginTop: "10px"}}
                        >
                          Save
                        </Button>
                      }
                    </div>
                    <Form style={{ textAlign: 'left', marginLeft: "40px", marginTop: "40px"}}>
                    <Form.Group controlId="currPassword">
                        <Form.ControlLabel>Current Password</Form.ControlLabel>
                        <Form.Control 
                        name="currPassword" 
                        value={currentUser.password}
                        type="password" 
                        />
                    </Form.Group>
                    <Form.Group controlId="newPassword1">
                        <Form.ControlLabel>New Password</Form.ControlLabel>
                        <Form.Control 
                        name="newPassword1" 
                        type="password" 
                        onChange={handlenewPassword1Change} 
                        />
                    </Form.Group>
                    <Form.Group controlId="newPassword2">
                        <Form.ControlLabel>Confirm Password</Form.ControlLabel>
                        <Form.Control 
                        name="newPassword2" 
                        type="password" 
                        onChange={handlenewPassword2Change} 
                        />
                    </Form.Group>
                    </Form>
                </div>
            </Content>
        </Container>
    </Container>
</div>
  )
}

export default AccountComponent