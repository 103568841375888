import React from 'react';
import { Navigate } from 'react-router-dom';
import Login from "./pages/login/Login";
import Home from './pages/home/Home';
import Registration from './pages/registration/Registration';
import Projects from './pages/projects/Projects';
import Account from './pages/account/Account';
import Profile from './pages/profile/Profile';
import Documents from './pages/documents/Documents';
import MeetingRoomBooking from './pages/officeSlots/MeetingRoomBooking';
import SalesOrder from './pages/salesOrder/SalesOrder';
import SubsalesOrder from './pages/subsalesOrder/SubsalesOrder';
import RentalsOrder from './pages/rentalsOrder/RentalsOrder';
import DownlineOrder from './pages/downlineOrder/DownlineOrder';
import Subsales from './pages/subsales/Subsales';
import OrderRequest from './pages/OrderRequest/OrderRequest';
import ViewSubsalesOrder from './pages/subsalesOrder/viewSubsalesOrder';
import ViewSalesOrder from './pages/salesOrder/ViewSalesOrder';
import ViewRentalOrder from './pages/rentalsOrder/ViewRentalsOrder';
import Leaves from './pages/leaves/Leaves';
import ViewLeavesComponent from './pages/leaves/ViewLeavesComponent';
import Contacts from './pages/contacts/Contacts';
import ViewContactComponent from './pages/contacts/ViewContactComponent';
import UserDetailsPage from './pages/registration/UserDetailsPage';
import Claims from './pages/claims/Claims';
import { CLAIMSREQUEST, CLAIMSREQUESTURL, VIEWPROJECTSDETAILSURL, VIEWRENTALSDETAILSURL, VIEWSUBSALESDETAILSURL } from './constants/constants';
import ClaimsRequest from './pages/claimsRequest/ClaimsRequest';

const RequireAuth = ({ children, currentUser }) => {
    return currentUser ? children : <Navigate to="/login" />;
};

const routes = (currentUser) => [
    { path: "login", element: <Login /> },
    { path: "/", element: <RequireAuth currentUser={currentUser}><Home /></RequireAuth>, index: true },
    { path: "users-registration", element: <RequireAuth currentUser={currentUser}><Registration /></RequireAuth> },
    { path: "project-list", element: <RequireAuth currentUser={currentUser}><Projects /></RequireAuth> },
    { path: "subsales-list", element: <RequireAuth currentUser={currentUser}><Subsales /></RequireAuth> },
    { path: "order-request", element: <RequireAuth currentUser={currentUser}><OrderRequest /></RequireAuth> },
    { path: `${CLAIMSREQUESTURL}`, element: <RequireAuth currentUser={currentUser}><ClaimsRequest /></RequireAuth> },
    { path: "account-setting", element: <RequireAuth currentUser={currentUser}><Account /></RequireAuth> },
    { path: "claims", element: <RequireAuth currentUser={currentUser}><Claims /></RequireAuth> },
    { path: "profile", element: <RequireAuth currentUser={currentUser}><Profile /></RequireAuth> },
    { path: "documents", element: <RequireAuth currentUser={currentUser}><Documents /></RequireAuth> },
    { path: "leaves", element: <RequireAuth currentUser={currentUser}><Leaves /></RequireAuth> },
    { path: "contact", element: <RequireAuth currentUser={currentUser}><Contacts /></RequireAuth> },
    { path: "booking-office-room", element: <RequireAuth currentUser={currentUser}><MeetingRoomBooking /></RequireAuth> },
    { path: "sales-order", element: <RequireAuth currentUser={currentUser}><SalesOrder /></RequireAuth> },
    { path: "subsales-order", element: <RequireAuth currentUser={currentUser}><SubsalesOrder /></RequireAuth> },
    { path: "rentals-order", element: <RequireAuth currentUser={currentUser}><RentalsOrder /></RequireAuth> },
    { path: "downline-order", element: <RequireAuth currentUser={currentUser}><DownlineOrder /></RequireAuth> },
    { path: `${VIEWPROJECTSDETAILSURL}/:id`, element: <RequireAuth currentUser={currentUser}><ViewSalesOrder /></RequireAuth> },
    { path: `${VIEWSUBSALESDETAILSURL}/:id`, element: <RequireAuth currentUser={currentUser}><ViewSubsalesOrder /></RequireAuth> },
    { path: `${VIEWRENTALSDETAILSURL}/:id`, element: <RequireAuth currentUser={currentUser}><ViewRentalOrder /></RequireAuth> },
    { path: "view-leaves/:id", element: <RequireAuth currentUser={currentUser}><ViewLeavesComponent /></RequireAuth> },
    { path: "view-contact/:id", element: <RequireAuth currentUser={currentUser}><ViewContactComponent /></RequireAuth> },
    { path: "view-user/:id", element: <RequireAuth currentUser={currentUser}><UserDetailsPage /></RequireAuth> },
    { path: "*", element: <Navigate to="/" /> },
];

export default routes;
